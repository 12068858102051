const enGB = {
  '14Days': '14 days',
  '30Days': '30 days',
  '30DaysNotice': 'Please note you are required to give 30 days notice of any cancellation.',
  404: '404',
  '7Days': '7 days',
  AllCommodities: 'All commodities',
  Amount: 'amount',
  AreYouLoadingMore: 'Are you loading more here?',
  AssignCustomer: 'Assign a customer',
  Cleaning: 'Cleaning',
  ContactValid:
    'Please make sure the contact below is valid so they can receive important information via email and Whatsapp (or SMS).',
  'Hygeine Record': 'Hygeine Record',
  IBAN: 'IBAN',
  IUnderstand: 'I understand',
  ListingNowLive: 'Your listing is now live',
  MIRLuxCapitalSarl: 'MIR Lux Capital S.a.r.l',
  MIRLuxWillPaySettlementDetailsNotStoredAskSupport:
    'MIR Lux will securely pay the settlement to the seller directly for this trade. Your details will not be stored. Any questions or issues please reach out to our support team.',
  MIRLuxWillSecurelyPayTheSettlementToTheSellerForTrade:
    'MIR Lux will securely pay the settlement to the seller directly for this trade. Your details will not be stored. ',
  ManageMultipleUserPermissions: 'Manage permissions for multiple users',
  MaxNumberDecks: 'Maximum number of decks',
  NoInviteNewConnection: 'No, add as new connection',
  NoInviteNewUser: 'No, add as new user',
  Observations: 'Observations',
  POD: 'POD',
  PODUploadRequest: 'POD Upload request',
  PODUploadSuccessOrganiserCanView:
    'Upload was successful. Team members of {organiserName} can now view and download this POD.',
  PODXSuccessfullyDeleted: 'POD - {POD} successfully deleted',
  PleaseAllocateEntireLoadBefore:
    'Please ensure you have allocated the entire load across available vehicles and drivers before',
  PleaseCompleteLoadFormBelow: 'Please complete load planning form below',
  PleaseReviewVehicleLoadErrors: 'Please review your vehicle loads for errors',
  Process: 'Process',
  SQCNumber: 'SQC Number',
  SQCno: 'SQC no',
  SameAsCompanyAddress: 'Same as company address',
  Sms: 'SMS',
  StartingLocation: 'Starting location',
  UTC: 'UTC',
  UpdateGroupGrade: 'Upgrade group/grade?',
  VehicleSaved: 'Vehicle saved',
  XDays: '{days} days',
  aBusinessUnitRequired: 'A business unit is required',
  aCollectionReceiptWillBeRequired: 'A collection receipt will be required to complete this trade',
  aFarmAddressIsRequiredToPostListing: 'A farm address is required to post a listing',
  aListingRequiresADecision: 'A listing requires a decision. | {count} listings require a decision.',
  aStorageContractIsRequired: 'A storage contract is required',
  aboutTheBuyer: 'About the buyer',
  aboutTheSeller: 'About the seller',
  aboutThisBuyer: 'About this buyer',
  accept: 'Accept',
  acceptAllAndContinue: 'Accept all and continue',
  acceptAndBuyCrop: 'Accept and buy crop',
  acceptAndGetPaidNow: 'Accept and get paid now',
  acceptInvitationAndSignUpToFarmTo: 'Accept the invitation and sign up to FarmTo now to view shared resources',
  acceptPrice: 'Accept price',
  acceptQuote: 'Accept quote',
  acceptRequest: 'Accept request',
  acceptTheBestPrice: 'Accept the best price',
  acceptTrade: 'Accept trade',
  acceptTradeWith: 'Accept trade with',
  accepted: 'Accepted',
  acceptedBidDetails: 'Accepted Bid Details',
  acceptedPrices: 'Accepted prices',
  acceptedQuotes: 'Accepted quotes',
  acceptedWith: 'Accepted with',
  accessCurrentMarketValueForInventory:
    "Access the current market value for your inventory with alerts to help make better trading decisions. Click 'Unlock insights now' button below and one of our team will get in touch very soon.",
  accessPermissions: 'Access permissions',
  accommodation: 'Accommodation',
  account: 'Account',
  accountHolderName: 'Account holder name',
  accountNickname: 'Account nickname',
  accountNumber: 'Account number',
  accountNumberFormat: 'Format: 12345678',
  actionRequired: 'Action required',
  actions: 'Actions',
  activeContracts: 'Active contracts',
  activePricesReceived: 'Active prices received',
  activity: 'activity',
  actualCurrencyPerUnit: 'Actual {currency}/{unit}',
  actualWeighedYield: 'Actual weighed yield',
  actualYield: 'Actual yield',
  add: 'Add',
  addABusinessConnection: 'Add a business connection',
  addABusinessConnectionInformation:
    'Please fill out the information below and we will email the business so they can join you. You can add as many contacts to the business as you wish.',
  addANewBusiness: 'Add a new business',
  addANewBusinessToFarmToToConnect: 'Add a new business to FarmTo to connect with you.',
  addANewConnection: 'Add a new connection',
  addANewPremiumContract: 'Add a new premium contract and fix them to base contracts',
  addARow: 'Add a row',
  addASinglePriceOrPricePerMovementMonth: 'Add a single price or add a price per movement month',
  addAVehicle: 'Add a vehicle',
  addAddressLine: 'Add address line',
  addAddressManually: 'Add address manually',
  addAllFarmLocationsGrowSellCrop: 'Add all farm locations where you grow and sell crop',
  addAnother: 'Add another',
  addAnotherCombination: 'Add another combination',
  addAnotherContact: 'Add another contact',
  addAnotherContract: 'Add another contract',
  addAnotherCrop: 'Add another crop',
  addAnotherFarm: 'Add another farm',
  addAnotherInventory: 'Add another planting/intake',
  addAnotherLoad: 'Add another load',
  addAnotherOption: 'Add another option',
  addAnotherPOD: 'Add another POD',
  addAnotherRecord: 'Add another record',
  addAnotherStop: 'Add another stop',
  addAsExistingInventory: 'Add as existing inventory',
  addAsMuchDetailsAsYouCan: 'Add as much detail as you can',
  addAssetsToLoad: 'Please add assets to load details',
  addAssuranceEachFarmForBuyerConfidence: 'Add farm assurance to each farm for extra buyer confidence',
  addBonus: 'Add bonus',
  addBusiness: 'Add business',
  addCarrier: 'Add carrier',
  addCompanyLogo: 'Add your company logo',
  addContact: 'Add contact',
  addContactToBusinessNetwork: 'Please add this contact in your business network page.',
  addContract: 'Add contract',
  addContractInfo: 'Add contract info',
  addCropDetails: 'Add crop details',
  addCropsYouCurrentlyHaveInStore:
    'Add the crops that you currently have in store to see your complete marketed position to help make your trading decisions easier.',
  addCustomDaysOrPresetOrDayOfMonth:
    'Add custom payment days or a preset. Alternatively click the ‘Day of month’ button to be more specific about the payment terms',
  addDate: 'Add date',
  addDates: 'Add dates',
  addDeductions: 'Add deductions',
  addDelivery: 'Add delivery',
  addDeliveryVehicleEntrance: 'Add delivery vehicle entrance',
  addDetails: 'Add details',
  addDriver: 'Add driver',
  addExistingBusinessUnit: 'Add existing business unit',
  addExistingInventory: 'Add existing inventory',
  addExistingInventoryToLocation: 'Add existing inventory into here',
  addExistingInventoryToThisLocation: 'Add existing inventory in this location',
  addExistingInventoryToYourStore: 'Add existing inventory to your store',
  addExistingLocationGroups: 'Add existing location groups',
  addExtraStoreInfo: 'Would you like to add extra store information',
  addInformation: 'Add information',
  addInventory: 'Add inventory',
  addLoad: 'Add a load',
  addLoadDetail: 'Add load detail',
  addLoadToHarvest: 'Add load to harvest',
  addLoadVehicle: 'Add load vehicle',
  addLoads: 'Add loads',
  addLoadsToHarvestRecord: 'Add loads to harvest record',
  addLoadsToRoute: 'Please add some loads to your route',
  addLoadsToYourHarvest: 'Add loads to your harvest',
  addLocation: 'Add location',
  addLocations: 'Add locations',
  addMoreDetailsForEstimate: 'Add more details for an estimate',
  addMorePhotos: 'Add more photos',
  addMorePlantings: 'Add more plantings',
  addMoreStorageLocation: 'Add more storage location',
  addMoreStoreInfo: 'Add more store info',
  addMultiplePlantingRecords: 'Add multiple planting records',
  addNew: 'Add new',
  addNewBreed: 'Do you want to add a new breed?',
  addNewBusiness: 'Add new business',
  addNewBusinessTo: 'Add a new business to FarmTo',
  addNewBusinessUnit: 'Add new business unit',
  addNewBusinessUnitSuccessDescription:
    'You can now add stores and fields into your business unit by clicking "+ Stores/fields" button.',
  addNewBusinessUnitSuccessTitle: 'Your new business unit has been created',
  addNewCrop: 'Do you want to add a new crop?',
  addNewDriver: 'Add new driver',
  addNewFarm: 'Add new farm',
  addNewField: 'Add new field',
  addNewFieldSuccess: 'Your new field, {name} has been created',
  addNewFile: 'Upload new file',
  addNewGroup: 'Add new group',
  addNewIntakeRecord: 'Add new intake record',
  addNewInventory: 'Add new inventory',
  addNewInventoryRecord: 'Add new inventory record',
  addNewJob: 'Add new job',
  addNewLoads: 'Add new loads',
  addNewLocationGroup: 'Add new location group',
  addNewLocationGroupSuccessDescription:
    'You can now add stores and fields into your location group by clicking "+ Stores/fields" button.',
  addNewLocationGroupSuccessTitle: 'Your new location group has been created',
  addNewOrganisation: 'Add new organisation',
  addNewPODs: 'Add new PODs',
  addNewPlantingOrIntakeRecord: 'Add a new planting or intake record',
  addNewPlantings: 'Add new plantings',
  addNewProfile: 'Add new profile',
  addNewRow: 'Add new row',
  addNewSpecies: 'Do you want to add a new species?',
  addNewStore: 'Add new store',
  addNewStoreSuccess: 'Your new store, {name} has been created',
  addNewTeamMember: 'Add a new team member',
  addNewTrailer: 'Add new trailer',
  addNewVariety: 'Do you want to add a new variety?',
  addNewVehicle: 'Add new vehicle',
  addNote: 'Add a note',
  addNoteForDriverCarrier: 'Add note for driver and carrier',
  addOfflineQuote: 'Add offline quote',
  addOfflineSales: 'Add offline sales',
  addOrUploadListOfLoadsToGetStarted: 'Add or upload a list of loads to get started.',
  addOtherFarmLocations: 'Add other farm locations',
  addPermissions: 'Add permissions',
  addPermissionsForUser: 'Add permissions for {name}',
  addPermissionsForUserDescription: 'Add {name} to your team and configure access permissions',
  addPhoto: 'Add photo',
  addPlantedInventoryToYouField: 'Add planted inventory to your field',
  addPreferredDestination: 'Add preferred destination',
  addPricePerMovementMonth: 'Add price per movement month',
  addProfilePicture: 'Add a profile picture',
  addQuote: 'Add quote',
  addRecord: 'Add additional record',
  addRerouteStop: 'Add re-route stop',
  addRow: 'Add row',
  addSample: 'Add sample',
  addSampleRecord: 'Add sample record',
  addSpecification: 'Add specification',
  addStopOrWeighbridge: 'Add stop or weighbridge',
  'addStores/FieldsTo': 'Add stores/fields to {name}',
  addTheLoadInformationBelowAndRecordToYourHarvest: 'Add the load information below and record to your harvest.',
  addTimeframe: 'Add timeframe',
  addToBusinessUnit: 'Add to business unit ({count})',
  addToExistingBusinessUnitsOrLocationGroups: 'Add to existing business unit(s) or location groups',
  addToLocationGroup: 'Add to location group ({count})',
  addToPreferredLocations: 'Add to preferred destinations',
  addTrailer: 'Add trailer',
  addTwoStops: 'Add at least two stops',
  addUnload: 'Add unload',
  addUser: 'Add user',
  addVat: 'Add VAT',
  addVehicle: 'Add vehicle',
  addViewStoreInfo: 'Add/view store info',
  addWeighbridgeTicket: 'Add weighbridge ticket',
  addYield: 'Add yield',
  addYourAssurance: 'Add your assurance',
  addYourAssuranceDetails: '🚜 Add your assurance details',
  addYourNewPlantingInBulk: 'Add your new plantings records in bulk',
  addYourPlantingsForYear: 'Add your planting records for {year}',
  addYourRedTractorSqcDetails:
    'Add your Red Tractor/SQC details against your farm so buyers can see that your grain is assured.',
  addedBy: 'Added by {name}',
  addedByOwnerAt: 'Added by {owner} on {date}',
  addedOn: 'Added on {date}',
  addedToViewStoreInfo: 'This has been added to your store info below.',
  addingHoldingNumberStorage: 'Adding a holding number to see long/short term storage options',
  addingTheseWillSaveTimeCreatingJobs:
    'Adding these will help save time when creating new jobs so you don’t have to add people everytime during job creation',
  additionalDetails: 'Additional details',
  additionalInformation: 'Additional information',
  additionalNotes: 'Additional notes',
  address: 'Address',
  addressLine: 'Address Line {number}',
  addressLineOne: 'Address Line 1',
  addressLineTwo: 'Address Line 2',
  addyourInventory: 'Start adding your inventory by clicking the button below',
  adjust: 'Adjust',
  adjustAssets: 'Adjust ({count}) assets',
  adjustByPercentage: 'Adjust by percentage',
  adjustByTonnesPerHectare: 'Adjust by tonnes per hectare',
  adjustCount: 'Adjust ({count})',
  adjustOutstandingInventory: 'Adjust outstanding inventory',
  adjustPreciseLocation: 'Adjust precise location',
  adjustRemainingHectaresIfNotCorrect: 'Adjust remaining hectares if not correct',
  adjustSelectedInventoryBy: 'Adjust selected inventory by',
  adjustYield: 'Adjust yield',
  adjusted: 'Adjusted',
  adjustedBy: 'Adjusted by',
  adjustedTo: 'Adjusted to',
  adjustment: 'Adjustment',
  adjustmentCreated: 'Adjustment created',
  adjustmentExpectedOutputOrLoss: 'Adjust expected output or in-store loss',
  adjustmentReason: 'Adjustment reason',
  adjustmentSummary: 'Adjustment summary',
  adjustments: 'Adjustments',
  admin: 'Admin',
  adminDashboard: 'Admin dashboard',
  adminPortal: 'Admin portal',
  adminProfile: 'Admin profile',
  'admixture/ScreeningCombined': 'Admixture/Screening combined',
  admixturePercentage: 'Admixture/Screening combined (%)',
  advanceBrokenIntoOneOrTwoPayments:
    'Depending on when you’re moving your crop, the advance will be broken in to one or two payments. The schedule will be clearly shown against your price offers.',
  advanceFees: 'Advance fees',
  advancePay: 'Advance pay',
  advancePayAcceptedTradeNoLongerValid: 'Advance Pay for this accepted trade is no longer valid',
  advancePayCostAndTerms: 'Advance Pay cost and terms',
  advancePayCostPerTonnePerDay: 'Advance Pay cost per tonne per day',
  advancePayNoLongerAvailable: 'Advance Pay no longer available',
  advancePayPending: 'Advance Pay pending',
  advancePayment: 'Advance payment',
  advancePaymentToBeMade: 'Advance payment to be made',
  advanceSummary: 'Advance summary',
  advancedPayment: 'Advanced payment',
  advancedPaymentAfterFees: 'Advanced payment, after fees',
  afterMovement: 'After movement',
  age: 'Age',
  ageMonths: 'Just born | {count} month | {count} months',
  ago: 'ago',
  agreeToHectaresCommunications: 'Agree to Hectare’s communications',
  agreeToHectaresTermsAndConditions: 'Agree to Hectare’s terms and policies',
  agreedPostDryingMoistureLevel: 'Agreed post drying moisture level',
  agreedQuantity: 'Agreed quantity',
  agreedStorageQuantity: 'Agreed storage quantity',
  agreedTrade: 'Agreed trade',
  agreedTrades: 'Agreed trades',
  agreedWithFinance: 'Agreed with finance',
  agreement: 'Agreement',
  agreements: 'Agreements',
  alertAllRelevantBuyersInTheMarket: 'Alert all relevant buyers in the market (including my buyers)',
  alertChosenFromMyBuyersList: 'Alert chosen contacts from my buyers list',
  alertOnlyThoseChosenFromMyBuyersList: 'Alert only those chosen from my buyers list',
  alertPreferences: 'Alert preferences',
  alertReceivedOn: 'Alert received on',
  alertYourTeam: 'Alert your team',
  alerts: 'Alerts',
  all: 'All',
  allAcceptedTrades: 'All accepted trades',
  allAmountsAreShownBasedOnTargetPriceMovementMonth:
    '*All amounts shown are based on the target price and movement month selected.',
  allAnswersFollowingWillBeLost: 'All answers following this question will be removed based on this change.',
  allBankDetailsAreEncrypted:
    'All bank details are encrypted, stored securely and protected by stringent security protocols.',
  allBusinessAreas: 'All business areas',
  allBuyersAndSellersUsingHectareTradingAreFullyVetted:
    'All buyers and sellers using Hectare Trading are fully vetted',
  allBuyersVerifiedCreditCheckedUseHectareTrade: 'All buyers are verified and credit checked to use Hectare Trade',
  allCarriers: 'All carriers',
  allCommodities: 'All commodities',
  allContracts: 'All contracts',
  allCrops: 'All crops',
  allCropsSpotPrices: 'All crops - spot prices',
  allCustomers: 'All customers',
  allDeliveriesRecorded: 'All deliveries recorded',
  allDrivers: 'All drivers',
  allFarmAssuredCropVerfied: 'All farm assured crop auto-verified for you',
  allFilters: 'All filters',
  allGroups: 'All groups',
  allHectareTradeBuyersAreVerified:
    'All Hectare trade buyers are verified and credit checked. This will go to everyone in the trusted network including your contacts.',
  allHectareTradeBuyersVerifiedCreditChecked:
    'Trade with confidence knowing Hectare carefully verifies and credit checks ALL buyers regularly.',
  allInventoryData: 'All inventory data',
  allInventoryInformation: 'All inventory information',
  allJobs: 'All jobs',
  allListings: 'All listings',
  allLocations: 'All locations',
  allOfYourSetupTasksAreComplete:
    'All of your setup tasks are complete. Keep selling your grain or view your marketing position.',
  allOrganisations: 'All organisations',
  allOrganisers: 'All organisers',
  allOtherCarriers: 'All other carriers',
  allPODDataX: 'All POD data ({count} records)',
  allPaymentsHaveBeenMade: 'All payments have been made and the contract has been settled.',
  allPriceOffersHaveExpiredRequestToReopen:
    'All price offers have expired. You can still request to re-open a price offer.',
  allPricesAreReceivedFromVerifiedAndCreditCheckedBuyers:
    'All prices are received from verified and credit checked buyers, for your peace of mind.',
  allPricesReceivedFromVerifiedBuyer: 'All prices are received from verified and credit checked buyers.',
  allQuotes: 'All quotes',
  allRegions: 'All regions',
  allTrades: 'All trades',
  allTypes: 'All types',
  allYourPriceOffersAreShownBelow: 'All your price offers are shown below with the best price shown at the top.',
  allocationSummary: 'Allocation summary',
  allowLocationServices: 'Allow location services',
  allowPushNotifications: 'Allow push notifications',
  allowToUsePhone: 'Allow you to use your phone for other tasks while this app is in use.',
  alreadyHasAccount: 'It looks like you already have an account',
  alreadyHasBusinessAccount: 'Your company already has a business account',
  alreadyHaveAnAccount: 'Already have an account?',
  alreadyHaveAnAccountSignIn: 'You already have an account, please sign in',
  alwaysUseFlexibleTonnage: 'Always use flex tonnage if you can',
  amendContract: 'Amend contract',
  amendPremiumContract: 'Amend premium contract',
  amendSpecification: 'Amend specification',
  amended: 'Amended',
  amendedDefaultCropSpecification: 'Amended from default crop specification',
  amendedDefaultSpecification: 'This specification has been amended from the original default specification.',
  amendmentsOpenFor:
    'Amendments to the driver and price remain open for 72 hours after the re-route has been created.',
  amount: 'Amount',
  amountCommitted: 'Amount committed',
  amountOwed: 'Amount owed',
  anErrorHasOccurred: 'An error has occurred',
  anErrorOccurred: 'An error occurred',
  anUploadRequestHasBeenSent: 'An upload request has been sent',
  and: 'and',
  andDidntFinishNotToWorryWeSavedYourProgress:
    "and didn't finish. Not to worry, we've saved your progress so you can continue",
  andPriceSentByUserOnDate: ' and {user} on {date} ({status})',
  andrewCoFounderAndArableFarmer: 'Andrew, co-founder and arable farmer',
  andrewHaleFarm: 'Andrew, Hale Farm, West Sussex',
  animalDamage: 'Animal damage',
  anotherBuyerInYourOrganisation: 'Another buyer in your organisation has sent a price for this listing',
  anotherBuyersInYourOrganisation: 'Other buyers in your organisation have sent a price for this listing',
  anotherInventory: 'Another inventory',
  anotherLoad: 'Another load',
  anotherPOD: 'Another POD',
  anyAttributesToAdd: 'Are there any attributes to add?',
  anyCropAnyTypeAnyLocation: 'Any crop, any type & group, any location',
  anyLossWillAutomaticallyBeAdjustedToZero:
    'Any loss will automatically be adjusted to 0{unit} in your field records.',
  anyStatus: 'Any status',
  anyTime: 'Anytime',
  anythingElseYouWantTellBuyers: 'Anything else you want to tell the buyers?',
  anytimeBetween: 'Between {timeFrom} and {timeBy}',
  apiErrors: {
    NotAuthorizedException: 'Incorrect username or password',
    UnsupportedUserStateException:
      'As you have previously set a password, you will need to use our forgotten password service to set a new password',
    UserNotFoundException: 'We cannot find your user in our database, please check your spelling',
    UserUnverifiedCannotSendRequest:
      'You have not activated your account. Please check your email or phone, or contact our support',
  },
  appFailedToLoad: 'The app has failed to load.',
  appImage: 'App image',
  appSetup: 'App setup',
  appStoreBadge: 'App store badge',
  applyAdjustment: 'Apply adjustment',
  applyAdjustments: 'Apply adjustments',
  applyAutoDryingLossWarning:
    'We will apply an auto drying loss after you have provided us the weighbridge and sample records.',
  applyFilters: 'Apply filters',
  applyPermissions: 'Apply permissions to {count} team members',
  approxFinalSettlement: 'Approx. final settlement',
  approxSettlementAfterFees: 'Approx. settlement, after fees',
  approxValueQuotesMayVary: 'Approximate value, quotes may vary',
  archiveListing: 'Archive listing',
  archiveThisListing: 'Archive this listing',
  archived: 'Archived',
  areAllTheDeliveriesRecorded: 'Are all the deliveries recorded?',
  arePredictedForLoad: 'are predicted for this load',
  areThereAnyAttributesToAdd: 'Are there any attributes to add?',
  areYouLookingForFurtherAssistance: 'Are you looking for further assistance?',
  areYouSure: 'Are you sure?',
  areYouSureThisWillDeleteFarm: 'Are you sure? This will permanently delete this farm location',
  areYouSureYouWantToDeleteLoads:
    'Are you sure you want to delete the selected loads? Please note that the deleted loads cannot be retrieved.',
  areYouSureYouWantToDeleteThisPremiumContract:
    'Are you sure you want to delete this premium contract? All fixed contracts associated to this premium contract will be unfixed.',
  areYouSureYouWantToEditTheDate: 'Are you sure you want to edit the date?',
  areYouSureYouWantToRemoveCombination: 'Are you sure you want to remove this combination? ',
  areYouSureYouWantToWithdraw: 'Are you sure that you want to withdraw this offer?',
  area: 'Area',
  areaCovered: 'Area covered',
  areaUnitShort: 'ha',
  areaUnits: 'hectares | hectare | hectares',
  areaYieldUnitsShort: 't/ha',
  arentAnyListingToViewRightNow:
    'There aren’t any listings to view right now. Make sure you’ve setup your alert preferences to receive all or specific crop combinations.',
  arriveAnytime: 'Arrive anytime',
  arriveBetween: 'Arrive between',
  arriveBy: 'Arrive by',
  arriveByTime: 'Arrive by {timeBy}',
  arriveFirstLocation: 'Arrive first location',
  arrived: 'Arrived',
  articleNotFound: 'Article not found. Please try again',
  asAvailable: 'As available',
  asInventoryIsStoredForThirdParty:
    " As inventory is stored for a 3rd party, you need to connect this movement to a storage contract.\n\n We haven't been able to find any existing storage contracts based on your selected crop. Please edit movement details or add a new storage contract.",
  asSale: 'as sale',
  asSoonAsYouCanEmailACopyOfTheBuyersContract: `As soon as you can, email a copy of the buyer’s contract to`,
  asYouHaveAcceptedAPriceWouldYouLikeToCloseThisMarketListing:
    'As you have accepted a price, you may keep this listing open to sell more tonnage or may choose to end this listing',
  askBuyerToAcceptTrade: 'Ask buyer to accept trade',
  askBuyerToReopenOffer: 'Ask to re-open the offer',
  assetCategories: 'Asset categories',
  assetCreated: 'Load created',
  assetInformation: 'Asset information',
  assetLoad: 'Load',
  assetType: 'Asset type',
  assetUnload: 'Unload',
  assetUpdated: 'Load updated',
  assets: 'Assets',
  assignABusinessArea: 'Assign a business area',
  assignCarriers: 'Assign carriers',
  assignNow: 'Assign now',
  assignVehiclesDrivers: 'Assign Vehicles/Drivers to accepted Jobs',
  associatedOrg: 'Associated Org',
  assured: 'Assured',
  at: 'at',
  atPointBuyerAcceptsYouHaveSuccessfullyCompleted:
    'At the point the buyer accepts, you will have successfully completed the trade',
  attachAContract: 'Attach a contract',
  attachContract: 'Attach a contract',
  attachContractDoc: 'Attach a contract doc',
  attachments: 'Attachments',
  attractMoreBuyersAndTakeAdvantageOfCarry:
    'Attract more buyers and take advantage of the carry by being open to different movement months. Select a single month, a range or a specific date from the calendar view. And remember, for forward sales, you can get paid now using Advance Pay when you trade with us!',
  attributes: 'Attributes',
  attributesSpecifiedCrop: 'Attributes can still be specified for this crop.',
  automaticCleaning: 'Automatic adjustment due to cleaning',
  automaticDryingShrink: 'Automatic adjustment due to drying',
  availability: 'Availability',
  available: 'Available',
  availableCapacity: '{remaining} of {capacity} available',
  availableCapacityForDifferentCrop: 'Capacity: {capacity}',
  availableCapacityStorage: 'Available capacity',
  availableDates: 'Available dates',
  availableForSale: 'Available for sale',
  availableMarketListings: 'Crop available to buy',
  availableRemainingCapacityPercentage: '{remainingCapacity}% Available',
  availableServices: 'Available services',
  availableUsers: 'Available users',
  avatarImage: 'Avatar image',
  averageCurrencyPerUnit: 'Average {currency}/{unit}',
  averagePerUnit: 'Average {currency} p/{unitShort}',
  averagePrice: 'Average price',
  averagePricePerTonne: 'Average price per tonne',
  averageSoldPrice: 'Average sold price',
  averageWeight: 'Average weight per head',
  avg: 'Avg',
  'avg/head': 'Avg/head',
  avgMoistureContent: 'Average moisture content',
  avgPrice: 'AVG. Price',
  awaitingBid: 'Awaiting bid',
  awaitingBids: 'Awaiting bids',
  awaitingCarrierAssignmentAppearHere: 'Loads awaiting carrier assignment will appear here.',
  awaitingChosenCarriersToOrganiseTheirLoads: 'Awaiting chosen carriers to organise their loads',
  awaitingJobsQuotesAppearHere: 'Jobs awaiting quotes will appear here.',
  awaitingJobsVehiclesAppearHere: 'Jobs awaiting vehicles/drivers will appear here.',
  awaitingPayments: 'Awaiting payments',
  awaitingQuote: 'Awaiting quote',
  awaitingQuoteResponses: 'Jobs awaiting quote responses',
  awaitingQuotes: 'Awaiting quotes',
  awaitingQuotesPricingAppearHere: 'Quotes awaiting pricing will appear here.',
  awaitingReply: 'Awaiting reply',
  awaitingReplyFromBuyer: 'Awaiting reply from buyer',
  back: 'Back',
  backToBids: 'Back to bids',
  backToEditDetails: 'Back to edit details',
  backToInventory: 'Back to inventory',
  backToInventoryTable: 'Back to inventory table',
  backToJobsAndPODs: 'Back to jobs and PODs',
  backToListings: 'Back to listings',
  backToLoads: 'Back to loads',
  backToMarketListings: 'Back to market listings',
  backToMyListings: 'Back to my listings',
  backToPODRecords: 'Back to POD records',
  backToReview: 'Back to review',
  backgroundPrimaryColor: 'Background primary color',
  backgroundSecondaryColor: 'Background secondary color',
  bankAccountName: 'Bank account name',
  bankDetails: 'Bank account details',
  bankDetailsProvided: 'Bank details provided',
  bankDetailsUpdated: 'Bank details updated',
  bankName: 'Bank name',
  base: 'Base',
  baseContractCreated: 'Base contract created',
  basePlusPremOnly: 'Base plus prem only',
  basePlusPremium: 'Base plus premium',
  basePrice: 'Base price',
  based: 'Based',
  basedIn: 'Based in',
  basedOnTypicalPriceOnPlatformNationwide: 'Based on a typical price on platform across a nationwide audience',
  beNotifiedWhenABuyerWantsYourCrop:
    'Be notified when a buyer wants your crop if it’s been added to your inventory,',
  beTheFirstInTheKnowWhenDemandIsHigh: 'Be the first to know when demand for your crops is high in your area',
  beddingRequired: 'Straw bedding',
  beforeDeductionsVat: 'before deductions/VAT',
  beforeTheReview: 'before the review',
  beforeWeStart: 'Before we get started',
  beforeYouRelistCanYouTellUsWhy: `Before you relist, can you tell us why?`,
  beforeYouSignInWeHaveUpdatedOurTerms: `Before you sign in, we've updated our terms`,
  benchmarkEmailSent: 'Benchmark email sent',
  benchmarkPriceAgainstMarket: 'Benchmark your price offers against the market',
  benchmarkPriceOffers: 'Benchmark price offers with free market price insights',
  bestLivePrice: 'Best live price',
  bestPrice: 'Best price',
  between: 'Between',
  bicSwift: 'BIC Swift',
  bid: 'Bid',
  bidAccepted: 'Price offer accepted',
  bidAlertsNotificationExplainer:
    'Who else in your team should be alerted when price offers arrive? By default you will be notified when a price offer comes in.',
  bidAmount: 'Bid amount',
  bidArchive: 'Bid Archive',
  bidDateTime: 'Bid date/Time',
  bidExpiry: 'Bid expiry',
  bidFormQuantityDescription:
    'Enter a min and max amount you are prepared to buy. The seller will then choose a final quantity within this range.',
  bidHistory: 'Bid history',
  bidNotes: 'Bid notes',
  bidOptionNumFromBuyer: 'Option {number} from {name}',
  bidRequestedBySeller: 'Bid requested by seller',
  bidReview: 'Bid review',
  bidSpread: 'Bid spread',
  bidSummary: 'Bid summary',
  bidValidForDesciption: 'Choose how long the seller has to respond to your offer.',
  bidWasValidOn: 'Bid was valid on',
  bidWithdrawn: 'Bid withdrawn',
  bidding: 'Bidding',
  bids: 'Bids',
  bidsAccepted: 'Bids accepted',
  bidsReceived: 'Bids received',
  bidsToDate: 'Bids to date',
  bidsWon: 'Bids won',
  bigNewsGraindexIsNowHectare: 'Big news, Graindex is now Hectare Trading',
  billing: 'Billing',
  billingAddress: 'Billing address',
  billingContactName: 'Billing contact name',
  billingContactNumber: 'Billing contact number',
  bonus: 'Bonus',
  booked: 'Booked',
  bookedJobs: 'Booked jobs',
  branchCode: 'Branch code',
  branding: 'Branding',
  breakdown: 'Breakdown',
  breakevenPrice: 'Breakeven price',
  breakevenPriceIsCalculatedByDividingYieldByCost:
    'Breakeven price is calculated by dividing yield by cost. This helps you understand the minimum price you should be accepting for crop. Target price is calculated as cost plus margin and is the ideal price to accept at to keep you in profit.',
  breed: 'Breed',
  buildingName: 'Building name',
  buildingNumber: 'Building number',
  bushel: 'bushel',
  bushels: 'bushels',
  bushelsShort: 'bu',
  businesId: 'Business',
  business: 'Business',
  businessAccounts: 'Business accounts',
  businessAccountsAbv: 'Biz accounts',
  businessAddress: 'Business address',
  'businessAddress/Postcode': 'Business address / postcode',
  businessAddressPostcode: 'Business address/postcode',
  businessAndLocations: 'Business and locations',
  businessArea: 'Business area',
  businessConnectionNewContactAdded: 'You have successfully added a new contact.',
  businessConnections: 'Business connections',
  businessContactAdded: 'Business and contacts added',
  businessContactDeleted: '{contact} successfully deleted',
  businessDetails: 'Business details',
  businessHasBeenConnected: '{businessName} has been connected',
  businessHasBeenCreated: 'You have successfully added contacts to {businessName}',
  businessHasBeenDeleted: '{businessName} has been deleted',
  businessHasBeenUpdated: '{businessName} has been updated',
  businessInformation: 'Business information',
  businessLocation: 'Business location',
  businessManagement: 'Business management',
  businessName: 'Business name',
  businessNetwork: 'Business network',
  businessProfile: 'Business profile',
  businessSetup: 'Business setup',
  businessUnit: 'Business unit',
  businessUnitCannotBeRecovered:
    'This business unit cannot be recovered after deletion. Are you sure you want to delete it?',
  businessUnitContactEmailAddress: 'Contact email address',
  businessUnitContactName: 'Contact name',
  businessUnitContactPhoneNumber: 'Contact phone number',
  businessUnitFarm: 'Business unit/farm',
  businessUnitIds: 'Business units',
  businessUnitName: 'Farm/business unit name',
  businessUnitType: 'Business type',
  businessUnits: 'Business units',
  businessUnitsAndLocationGroups: 'Business units and location groups',
  businessUnitsPermissionsDisclaimer:
    'A user who is not assigned to any business units will have access to all business units across your organisation by default.',
  businessWebsite: 'Business website address',
  businesses: 'Businesses',
  buyer: 'Buyer',
  buyerChosenToHideName: 'This buyer has chosen to hide their name',
  buyerDeclinedYourRequest: 'Buyer declined your request',
  buyerGuidePriceIsNotAnOffer: '*The buyer’s guide price is not an offer',
  buyerInformation: 'Buyer information',
  buyerListingSpecificationConfirmPrompt: 'Please click below to confirm you have read the specification',
  buyerLogo: 'Buyer logo',
  buyerNameDetails: '{buyerName} details',
  buyerNotes: 'Notes from buyer',
  buyerRepaymentTerms: 'Buyer repayment terms',
  buyerSpecificationOverviewChanges: 'Changed from standard crop specification',
  buyerSpecificationOverviewStandard: 'Standard crop specification',
  buyerWhoSentTradeRequestNotified:
    'The buyer who sent the trade request has been notified of this listing, look out for incoming bids on the market listings page.',
  buyers: 'Buyers',
  buyersAreCreditCheckedAndFrequentlyMonitored: 'Buyers are credit checked and frequently monitored',
  buyersGuidePrice: 'Buyer’s guide price*',
  buyersGuidePricePosted:
    '*Buyers guide price was posted {date} and are not obliged to adhere to the price offer, it is just what they could be will to spend at that point in time',
  buyersGuidePriceWasPostedOn:
    '*The guide price is an indication of what the buyer would be willing to spend as of {date}; however, they are under no obligation to adhere to this value',
  buyersHaveBeenNotified: 'Buyers have been notified about your listing. Here’s what to expect whilst you wait...',
  buyersNotified: 'buyers notifed | buyer notified | buyers notified',
  buyersPriceExpiredOrWithdrawnPleaseClose:
    "It appears the buyer's offer has either expired or been withdrawn. Please close this message to view the listing or click My listings to see everything.",
  buyersPriceNoLongerActive: 'Buyers price offer no longer active',
  buyersTypciallyReplyWithinHour: 'Buyers typically reply within 1 hour',
  buyersVerfied: 'Buyers verified and credit checked for confident decision making',
  buying: 'Buying',
  by: 'By',
  byClicking: 'By clicking',
  byClosingThisListing:
    'By closing this listing, it will no longer be open for bids. Buyers will be notified their bid has been unsuccessful.',
  byDateRange: 'By date range',
  byDeletingAssetTrade: 'By deleting this trade it will be permanently removed from this inventory record.',
  byDeletingAssetTradeContract:
    'By deleting this contract it will be permanently removed from this record. Are you sure?',
  byDeletingBusinessConnection:
    'By deleting this you will no longer see it in your business connection lists. Are you sure?',
  byDeletingPlannedMovement:
    'By deleting this movement it will be permanently removed and will affect other movements you have planned.  Are you sure you want to delete?',
  byDeletingSample: 'This sample will be permanently removed. Are you sure?',
  byDeletingSampleImage: 'The sample image will be permanently removed. Are you sure?',
  byDeletingStopYouWillLoseLoads:
    'By deleting a stop, you will lose the routes and loads you have added to this stop.',
  byDeletingStorageContract:
    'By deleting this storage contract it will be permanently removed. Are you sure you want to delete?',
  byDeletingThisContractItWillBeRemoved: 'By deleting this contract it will be permantently removed.',
  byDeletingThisMovement:
    'By deleting this movement it will be permanently removed. Are you sure you want to delete?',
  byDeletingThisSampleImage: 'By deleting this sample image it will be permanently removed. Are you sure?',
  byDeletingWeighBridgeTicket:
    'By deleting this weighbridge ticket it will be permanently removed from this record. Are you sure?',
  byDeletingthisUser:
    'By deleting this user they will be removed permanently from  your FarmTo account and be unable to access or view inventory. Are you sure?',
  byFarmsStores: 'By farms/stores',
  byLocations: 'By locations',
  byMonth: 'By month(s)',
  calculateMyPosition: 'Calculate my position',
  calculationsExplained: 'Calculations explained',
  calculator: 'calculator',
  call: 'Call',
  callDriver: 'Call driver',
  callHandlerName: 'Call handler {name}',
  callOnNumber: 'Call us on the number below.',
  callUs: 'Call',
  canAdjustItemsAfterSelectPercentage: 'You can still edit each record individually after bulk adjustments.',
  canBeDecidedCloserJobDate: 'This can be decided closer to the job date',
  canYouBeFlexibleOnMovementDate: 'Can you be flexible on movement month?',
  cancel: 'Cancel',
  cancelButtonLabel: 'Cancel',
  cancelContract: 'Cancel contract',
  cancelEditing: 'Cancel Editing',
  cancelThisContract: 'Cancel this contract',
  cancelTransportRequestConfirm: 'This will cancel the job and notify the carrier. This action cannot be undone.',
  cancelled: 'Cancelled',
  cancelledByCarrier: 'Cancelled by carrier',
  cancelledByOrganiser: 'Cancelled by organiser',
  cancellingInstantInvoice: 'Cancelling InstantInvoice',
  cannotEditBusinessWithUserPermissions: 'You cannot edit business with current user permissions.',
  cannotMixGroups: 'Cannot mix groups',
  capacity: 'Capacity',
  capacityFor: 'Capacity for',
  capacityTypes: 'Capacity types',
  carbonOutput: 'Carbon Output',
  carbonOutputForLoad: 'Carbon output for load',
  carrier: 'Carrier',
  carrierDeclined: 'Carrier declined',
  carrierMode: 'Carrier mode',
  carrierPlural: 'Carrier | Carriers',
  carrierSignUp: 'Carrier sign up',
  carrierTermsAndConditions: 'Carrier Terms and Conditions',
  carrierTermsAndConditionsAgree: 'I agree to the Terms and Conditions',
  carrierTermsAndConditionsEmail: 'The Carrier Terms and Conditions will be sent to you via email once accepted.',
  carrierTermsAndConditionsError: 'Please read and agree to the terms and conditions before accepting this quote.',
  carriers: 'Carriers',
  carriersWillBeNotified: 'Carriers will be notified',
  category: 'Category',
  cc: 'cc',
  certificateExpiredOnDate: 'Certificate expired on {date}',
  certificationFound: 'Certification found',
  change: 'Change',
  changeDate: 'Change date',
  changeDeliveryVehicleEntrance: 'Change delivery vehicle entrance',
  changeFarm: 'Change farm',
  changeLocation: 'Change location',
  changePassword: 'Change password',
  changeProfileDetails: 'Change profile details',
  changePushNotifications: 'Do you want to update push notifications?',
  changeReroute: 'Change re-route',
  changeSettings: 'Change settings',
  changedTo: 'Changed to',
  changesMadeToInventoryRecordUpdateTimeline:
    'Changes made to an inventory record will be recorded as an inventory update in your timeline.',
  characterMinimum: '8 character minimum',
  characters: 'characters',
  chart: {
    allocated: 'Allocated',
    complete: 'Complete',
    unallocated: 'Unallocated',
  },
  checkAndEditYourCropSpecification: 'Check and edit your crop specification',
  checkBackLaterToSeeNewListings: 'Check back later to see if a seller has sent a new listing your way.',
  checkDetails: 'Check details',
  checkEmailResetLink: 'Please check your emails for your reset password link',
  checkTradeDetailsAreCorrect: 'Please check the details of this trade are correct',
  checkYour: 'Check your',
  checkYourEmailAddress: 'Check your email address',
  checkYourEmailAndClickTheLink:
    'Check your WhatsApp/ email and click the link to automatically log in and and continue.',
  checkYourEmailToFinishSigningUp: 'Check your email to finish signing up',
  chooseAContractToFix: 'Choose a contract to fix',
  chooseAFrequentlyUsedRoute: 'Choose a frequently used route',
  chooseARangeIfFlexible: 'Choose a range if you’re flexible',
  chooseARangeIfFlexibleAmountToSell:
    "Choose a range if you have flexible amount you'd like to sell to attract more offers.",
  chooseARangeIfFlexibleOnMovementMonths:
    'Choose a range if you’re flexible. If you want to add a different price per month, click “Add another option” below.',
  chooseAnExistingBaseContractOrCreateANewBaseContract:
    'Choose an existing base contract or create a new base contract to fix your premium against.',
  chooseAnyNumberToMatchYourRecords: 'Choose any number to match your records.',
  chooseBuyers: 'Choose buyers',
  chooseBuyersFromYourNetwork: 'Choose buyers from your network',
  chooseBuyersToMarketTo: 'Choose buyers to market to',
  chooseColleaguesToNotify: 'Choose colleagues to notify',
  chooseCrops: 'Choose crops',
  chooseDestination: 'Choose your destination',
  chooseDestinationsToCompare: 'Choose one or multiple destinations to compare',
  chooseExistingContract: 'Choose existing contract',
  chooseFarmLocation: 'Choose farm location',
  chooseFile: 'Choose a file',
  chooseFromYourBuyers: 'Choose from your buyers',
  chooseInventory: 'Choose inventory to move',
  chooseLocationTypeFirst: 'Choose a location type first',
  chooseLocationsASellerCanBuyCropFrom:
    'Choose which locations a seller can sell crop from, by default a user who is not assigned any locations will have access to all farm locations within your organisations',
  chooseManagementType: 'Choose management type',
  chooseManually: 'choose manually',
  chooseMostUsedOrMostRecentRoutes:
    'Choose one of your most used or most recent routes to pre-fill the stops below',
  chooseMovementMonthsRange: 'Choose a range if you’re flexible on movement months',
  chooseNewPassword: 'Choose a new password for your account.',
  chooseOnePriceOption: 'Choose 1 price option',
  chooseOption: 'Choose option',
  choosePhoto: 'Choose photo',
  choosePickupDateForThisJob: 'Choose a pickup date for this job.',
  chooseRoute: 'Choose route',
  chooseRouteAddLoads: 'Choose route and add loads',
  chooseRouteAndLoads: 'Choose route and add loads',
  chooseSpecType: 'Choose spec type',
  chooseStore: 'Choose store',
  chooseVehicle: 'Choose vehicle',
  chooseWhichBusinessUnitsCanAccess:
    'Choose which business unit(s) this person is able to access. Their permissions below will be applied to each location group added.',
  chooseWhichTeamMemberYouWouldLikeToSignInAs: 'Choose which team member you would like to sign in as.',
  chooseYourFarmAssuranceScheme: 'Choose your farm assurance scheme',
  chooseYourOfferExpiry: 'Choose your offer expiry',
  chooseYourOrganisation: 'Choose your organisation',
  chooseYourPickupLocation: 'Choose your pickup location',
  chosenCarrier: 'Chosen carrier',
  chosenDate: 'Chosen Date',
  chosenMakeOwner:
    'You have chosen to make {name} an owner of your organisation. They will have permission to access and edit all areas from now on.',
  city: 'City',
  cleaning: 'Cleaning',
  cleaningCanBeManuallyAppliedAfterService:
    'Cleaning loss can be manually applied after the service through the “Storage service” action page.',
  cleaningFacilities: 'Cleaning facilities',
  cleaningLoss: 'Cleaning loss',
  cleaningService: 'Cleaning service',
  clear: 'clear',
  clearCropInfo: 'Clear crop info',
  clearFilters: 'Clear filters',
  clearSearch: 'Clear search',
  clickBoxDragDropFileUpdateYourImage:
    'Click the box below or drag and drop your chosen file to update your user image.',
  clickButtonBelowToTryAgain: 'Click the button below to try again.',
  clickMapToChangeLocation: 'Click a point on map to change',
  clickMe: 'Click me',
  clickRowToDownloadReport: 'Click row to download report',
  clickToSkip: 'Click to skip',
  clickUpdateDragDrop: 'Click update then drag and drop an image from your files to update your profile picture.',
  close: 'Close',
  closeAndViewTradeSummary: 'Close and view trade summary',
  closeListing: 'Close listing',
  closeThisSetup: 'Close this setup',
  closed: 'Closed',
  closedListing: 'Closed listing',
  collapse: 'Collapse',
  collection: 'Collection',
  collectionAddress: 'Collection address',
  collectionBy: 'Collection by',
  collectionByDateOrDateRange: 'Collection by (date or date range)',
  collectionCenter: 'Collection center',
  collectionDate: 'Collection date',
  collectionLocation: 'Collection location',
  collectionOfThisTrade: 'collection of this trade.',
  collectionReceiptRequired: 'Collection receipt required',
  collectionReceiptUploaded: 'Collection receipt uploaded',
  collectionTime: 'Collection Time',
  combinationSaved: 'Combination saved',
  combinationX: 'Combination {value}',
  combinationXRemoved: 'Combination {num} removed',
  combinationXSaved: 'Combination {num} saved',
  comingSoon: 'Coming soon',
  commercial: 'Commercial',
  commodity: 'Commodity',
  commodityDetails: 'Commodity details',
  commodityGrade: 'Group/grade',
  commoditySummary: 'Commodity summary',
  commodityTags: 'Crop tags',
  communications: 'Communications',
  companyAddress: 'Company address',
  companyHasBeenNotifiedOfEditedPrice:
    'We have removed your old price and replaced it with the updated price offer. {companyName} has been notified and will be in touch.',
  companyHasBeenNotifiedThatYouWouldLikeToAcceptThisTrade:
    '{companyName} has been notified that you would like to accept this trade.',
  companyName: 'Company name',
  companyNoLongerAbleToViewBid: '{companyName} is no longer able to view or accept this bid.',
  companyNumber: 'Company number',
  companyNumberMatchValidation: 'The company number is not valid',
  complete: 'complete',
  completeHarvest: 'Complete harvest',
  completeLoadPlanningFormAssignVehicles:
    'Complete the load planning form now to assign a vehicle and driver to this job.',
  completeNewIntake: 'Complete new intake',
  completeNewPlanting: 'Complete new planting',
  completeTheTasksBelow: 'Complete the tasks below',
  completed: 'Completed',
  completedContracts: 'Completed contracts',
  completedJobs: 'Completed jobs',
  completedLoads: 'Completed loads',
  completelyFreeCarrierDrivers: 'Completely free to use for Carriers and Drivers',
  completionOfMovement: 'Completion of movement',
  confirm: 'Confirm',
  confirmAndContinue: 'Confirm and continue',
  confirmBid: 'Confirm bid',
  confirmButtonLabel: 'Confirm',
  confirmCarrierSelection: 'Confirm carrier selection',
  confirmCollection: 'Confirm collection',
  confirmCropSpecification: 'Confirm crop specification',
  confirmDeleteBusinessContact: 'Please confirm you would like to delete {contact}',
  confirmDeletion: 'Confirm deletion',
  confirmDescription: 'This action cannot be undone.',
  confirmDriverChange: 'Confirm driver change',
  confirmEmailOrPhoneNumberToRecover:
    "No problem, confirm the email or phone number that you used when you set up your account and we'll send you a link to reset your password.",
  confirmExistingInventory:
    "Ensure existing inventory has been confirmed by clicking the 'Add as existing inventory' button",
  confirmGradeAndPriceToAddDeliveries: 'Confirm the grade and price of this contract to add your deliveries.',
  confirmHarvestProgress: 'Confirm harvest progress',
  confirmMovementDate: 'Confirm movement date',
  confirmOverwriteCarrierTermsAndConiditons:
    'You are only able to store one version of your Terms and Conditions. By confirming this action you will overwrite the existing file and it will be replaced by the file you are uploading.',
  confirmPermissions: 'Confirm permissions',
  confirmReRouteDriverWillBeNotified: 'Please confirm re-route, driver will be issued new route immediately.',
  confirmRouteSetup: 'Confirm route setup',
  confirmSelection: 'Confirm selection',
  confirmTheGradeAndPriceOfThisContract: 'Confirm the grade and price of this contract to add your deliveries.',
  confirmTitle: 'Are you sure?',
  confirmTrade: 'Confirm trade',
  confirmWhenCropIsMovedAllowsRemainingPaymentsToBeMade:
    'Confirming when the crop has moved allows remaining payments to be completed promptly. Any intermediate advanced payments can only be processed with collection proof.',
  confirmYouWantToRemoveDelivery:
    'Confirm that you want to remove delivery from this contract. If this delivery is linked to an existing movement, the movement will not be deleted.',
  confirmYouveBeenPaidCantEditInvoice:
    'Confirm that you have been paid for this delivery. Note you will not be able to edit invoice once it is settled.',
  confirmationCodeExpired: 'Password Reset Request Expired',
  confirmed: 'Confirmed',
  confirmedBySellerGrainAssured: 'Confirmed by seller that grain is assured',
  confirmedMovementDate: 'Confirmed movement date',
  conflictCommodity: 'Different crop',
  conflictShortfall: 'Inv. shortfall',
  conflictSurplus: 'Over capacity',
  conflictsListedBelow:
    'You have conflicts listed in the red rows below. Please resolve these conflicts by editing the movement (click the three dots) then you will be able to delete the planned movement as you wish.',
  conflictsOutstanding:
    'You have {count} outstanding conflicts. Please resolve this conflict by clicking the three dots and clicking ‘Manage conflict’',
  congratsNewInventoryAddedIntoAccount: 'Congratulations! New inventory added to your account',
  congratsNewInventoryAddedIntoStore: 'Congratulations! Your new inventory has been added into store.',
  congratsYourNewPlantingsAreAddedToList: 'Congratulations! Your new plantings have been added to your list.',
  congratulations: 'Congratulations',
  congratulationsRequestToQuote: 'Congratulations, you have a request to quote for a new job.',
  'congratulationsStores/FieldsAddedTo': 'Congratulations, all your selected stores/fields are added to {name}.',
  congratulationsYouAcceptedTheTrade: 'Congratulations, you’ve accepted the trade!',
  congratulationsYourAccountHasBeenCreated: 'Congratulations! Your account has been created',
  congratulationsYoureOnTheOpenMarket: 'Congratulations! You’re on the open market',
  congratulationsYouveAcceptedTheTrade: "Congratulations, you've accepted the trade",
  connectAppearsOffline: 'Connection appears to be offline',
  connectBusinessUnitsAndLocations: 'Connect business units and location groups',
  connectIntakeToAStorageContract: 'Connect intake to a storage contract',
  connectMovementToContract: 'Connect movement to a contract',
  connectMovementToSalesContract: 'Connect movement to sales contract',
  connectThisMovementToAStorageContract: 'Connect this movement to a storage contract',
  connectToExistingMovement: 'Connect to existing movement',
  connectToSalesContract: 'Connect to a sales contract',
  contact: 'Contact',
  contactAtRegionLocationType: 'Contact at {locationType} - {region}',
  contactBuyer: 'Contact buyer',
  contactEmail: 'Contact email address',
  contactHelpToUpdateYourSettings: 'Contact help@wearehectare.com to update your notification settings',
  contactMobileNumber: 'Contact mobile number',
  contactName: 'Contact Name',
  contactNumber: 'Contact number',
  contactOurCustomerSuccessTeam: 'Contact our customer success team',
  contactRequestSent: 'Contact request sent',
  contactSupport: 'contact support',
  contactUs: 'Contact us',
  contactUsToGetStarted: 'Contact us to get started',
  contacts: 'Contacts',
  continue: 'Continue',
  continueDashboard: 'Continue to dashboard',
  continueSettingUp: 'Continue setting up',
  continueTo: 'Continue to',
  continueToSignIn: 'Continue to sign in',
  continueToTradeSummary: 'Continue to trade summary',
  continueWithListing: 'Continue with listing',
  continueWithNextJob: 'Continue with next job',
  continueWithoutAdvancePay: 'Continue without Advance Pay',
  continueWithoutSaving: 'Continue without saving',
  continuingYouAgreeToTerms: 'By continuing you agree to accept all applicable Terms & Conditions.',
  contract: 'Contract',
  contractAdded: 'Contract added',
  contractAddedToTrade: 'Contract added to trade',
  contractCreated: 'Contract created',
  contractDate: 'Contract date',
  contractDocuments: 'Contract documents',
  contractEndDate: 'Contract end date',
  contractFixed: 'Contracts fixed',
  contractFixedToABaseContract: 'Contract fixed to a base contract - reduced editing functions',
  contractId: 'Contract ID',
  contractIdEndsOnDate: '{contractId}, ends on {date}',
  contractIdOrCompanyName: 'Contract ID or company name',
  contractIdX: 'Contract ID {contractId}',
  contractInfo: 'Contract info',
  contractInformation: 'Contract information',
  contractNo: 'Contract No.',
  'contractNo.': 'Contract No.',
  contractNumber: 'Contract number',
  contractPaymentDays: 'Contract specifies invoice to be paid within {days} days',
  contractRef: 'Contract ref.',
  contractRolledOver: 'Contract rolled over',
  contractStartDate: 'Contract start date',
  contractTerms: 'Contract terms',
  contractType: 'Contract type',
  contractUploaded: 'Contract uploaded',
  contractValue: 'Contract value',
  contractingOrganisation: 'Contracting organisation',
  contracts: 'Contracts',
  contractsFixed: 'Contracts fixed',
  cookiePolicy: 'Cookie Policy',
  cookiePolicyDescription: 'Information on the cookies we use to enhance your experience.',
  cookiePolicyDisclaimer: 'We use cookies on this website to enhance your experience.',
  cookiePolicyLinkLabel: 'Read our cookie policy.',
  coordinates: 'Coordinates',
  coordinatesUpdateTip: 'Updating this information will automatically update the address',
  copiedToClipboard: 'Copied to clipboard',
  copies: 'Copies',
  copy: 'Copy',
  copyCoordinates: 'Copy coordinates',
  copyFullAddress: 'Copy full address',
  copyWhat3words: 'Copy What3words',
  costPerHectare: 'Cost per hectare',
  couldntFindContactsForJob: "We couldn't find any contact details for this job",
  countBuyersSelected: 'One buyer selected | {count} buyers selected',
  countResultSelected: '1 row selected | {count} rows selected',
  countSelected: '{count} selected',
  country: 'Country',
  countryLandlineNumber: '{country} landline number',
  countryMobileNumber: '{country} mobile number',
  create: 'Create',
  createABusinessAccount: 'Create a business account',
  createAListing: 'Create a listing',
  createANew: 'Create a new',
  createAReRoute: 'Create a re-route',
  createAndEdit: 'Create & Edit',
  createAnotherJob: 'Create another job',
  createAsset: 'Save load',
  createBusinessAccountNow: 'Create a business account now',
  createBusinessOwnerAccount: 'Create business owner account',
  createExistingJob: 'Create existing job',
  createFarm: 'Create farm',
  createJob: 'Create job',
  createJobOrganiseAndTrackProgress:
    'Creating a new job allows you to organise and track the progress of drivers when picking up and dropping off loads',
  createJobRequestQuotes: 'Create job and request quotes',
  createListing: 'Create listing',
  createMarketListing: 'Post a listing',
  createMovement: 'Create a movement',
  createMyBusinessAccount: 'Create my business account',
  createNew: 'Create New',
  createNewJob: 'Create new job',
  createNewPasswordValidation:
    'Your password needs to be at least 8 characters long and contain at least one uppercase character and one digit.',
  createNewPasswordValidationDeprecated:
    'Your password needs to be at least 8 characters long and contain at least one special character, one uppercase character, one lowercase character and one digit.',
  createNewPlantings: 'Create new plantings',
  createNewSalesContract: 'Create new sales contract',
  createReRoute: 'Create a re-route',
  createRequest: 'Post wanted listing',
  createStorageLocation: 'Create storage location',
  createYourFirstMarketListing: 'Create your first market listing and start receiving prices from buyers.',
  created: 'Created',
  creating: 'Creating',
  creatingLoginLink: 'Creating login link...',
  crop: 'Crop',
  cropDefaultSpecResetWarning:
    'Please note that editing the crop will undo any changes made to the default specification.',
  cropDetails: 'Crop detail',
  cropFilters: 'Crop filters',
  cropHasBeenAdded: '{name} crop has been added',
  cropInformation: 'Crop information',
  cropNeedsEndTradeRequestDescription: 'Ending this request will remove its visibility to sellers. Are you sure?',
  cropPrice: 'Crop price per {unit}',
  cropSpecification: 'Crop specification',
  cropType: 'Crop type',
  cropValue: 'Crop value',
  crops: 'Crops',
  cropsYouWantToHearAbout: 'Crops you want to hear about',
  currency: 'Currency',
  currencyCode: 'Currency code',
  current: 'Current',
  currentDriver: 'Current driver',
  currentJob: 'Current job',
  currentOutput: 'Current output',
  currentPosition: 'Current position',
  currentProjectedQuantity: 'Current projected quantity',
  currentProjectedYield: 'Current projected yield',
  currentSelectedFiltersX: 'Current selected filters ({count} records)',
  currentTonnage: 'Current tonnage',
  currentYield: 'Current yield',
  currentlySold: 'Currently sold',
  custom: 'Custom',
  customDomains: 'Custom domains',
  customSpec: 'Custom',
  customer: 'Customer',
  customerPlural: 'Customer | Customers',
  customerSuccessWillBeInTouch: 'Customers success will be in touch soon to get you setup with your account.',
  customers: 'Customers',
  customersAccount: 'Customers: Accounts',
  customiseTargetPricesForEachRegion: 'Customise target prices for each region that you trade in',
  dailySpotPrices: 'Daily spot prices',
  dashboard: 'Dashboard',
  dataSeeding: 'Data seeding',
  dataType: 'Data type',
  dataUpload: 'Data Uploads',
  date: 'Date',
  dateListed: 'Date listed',
  dateRange: 'Date range',
  dateTime: 'Date/Time',
  dates: 'Dates',
  datesAndRoute: 'Dates and route',
  datesAndSpecialRequirements: 'Dates and special requirements',
  day: 'day',
  dayAgo: 'day ago | days ago',
  dayOfMonth: 'Day of month',
  days: 'days',
  daysShorthand: 'd',
  dealTicket: 'Trade confirmation',
  declaration: 'Declaration',
  decline: 'Decline',
  declinedStillAddQuote: 'Once declined, you will still be able to add a quote to this job',
  decrease: 'Decrease',
  deduction: 'Deduction',
  deductions: 'Deductions',
  defaultInvoiceToHasBeenRemoved: 'Default invoice to has been removed',
  defaultInvoiceToHasBeenSet: 'Default invoice to has been set',
  defaultSpec: 'Default',
  defaultSpecification: 'Default specification',
  delete: 'Delete',
  deleteAssetTradeContract: 'Delete contract',
  deleteAssets: 'Delete ({count}) assets',
  deleteBusiness: 'Delete business',
  deleteBusinessContact: 'Delete {contact}',
  deleteBusinessUnit: 'Delete this business unit',
  deleteContact: 'Delete contact',
  deleteContract: 'Delete contract',
  deleteExistingFilesFirst: 'You must delete existing files first',
  deleteFarm: 'Delete farm',
  deleteFarmLocation: 'Delete farm location',
  deleteInventory: 'Delete inventory',
  deleteLocation: 'Delete location',
  deleteLocationGroup: 'Delete location group',
  deleteMovement: 'Delete movement',
  deletePlannedMovement: 'Delete planned movement',
  deletePremiumContract: 'Delete premium contract',
  deleteSampleImage: 'Delete sample image',
  deleteStorage: 'Delete storage',
  deleteStorageContract: 'Delete storage contract',
  deleteTeamMember: 'Delete this team member',
  deleteThisLocation: 'Delete this location',
  deleteTrade: 'Delete trade',
  deleteUser: 'Delete user',
  deleteValue: 'Delete {value}',
  deleteWeighBridgeTicket: 'Delete weighbridge ticket',
  deletedFail: 'There was an error deleting that item. Please reload and try again.',
  deletedSuccess: 'Deleted successfully',
  deletedX: '{name} deleted',
  deletingAStop: 'Deleting a stop',
  deletingThisLoadWillRemoveIt:
    'Deleting this load will remove it from the harvest, including any uploaded samples',
  deletingThisLocation: 'Deleting this location will remove it from your storage locations permanently',
  delivered: 'Delivered',
  deliveries: 'Deliveries',
  deliveriesAndSettlements: 'Deliveries and settlements',
  delivery: 'delivery',
  deliveryCreated: 'New delivery created',
  deliveryDate: 'Delivery date',
  deliveryDetails: 'Delivery details',
  deliveryInProgress: 'Delivery in progress',
  deliveryMarkedAsPaid: 'Delivery marked as paid',
  deliveryPostCode: 'Delivery post code',
  deliveryTerms: 'Delivery terms',
  deliveryUpdated: 'Settlement and delivery updated',
  departed: 'Departed',
  depot: 'Depot',
  describeBusiness: 'Describe your business',
  description: 'Description',
  deselect: 'Deselect',
  deselectAllPreferredCarriers: 'Deselect all preferred carriers',
  desiredPosition: 'Desired position',
  destination: 'Destination',
  destinationLocations: 'Destination location(s)',
  details: 'Details',
  'details.endDateUTC': 'End date',
  'details.files': 'Files',
  'details.haulageType': 'Haulage type',
  'details.movementPeriod': 'Movement period',
  'details.notes': 'Notes',
  'details.paymentDays': 'Payment days',
  'details.premiumPrice': 'Premium price',
  'details.premiumWeightKG': 'Premium Weight',
  'details.pricePerUnit': 'Price per unit',
  'details.priceType': 'Price type',
  'details.reference': 'Reference',
  'details.startDateUTC': 'Start date',
  'details.terms': 'Payment terms',
  'details.totalPrice': 'Total price',
  'details.totalWeight': 'Total weight',
  'details.totalWeightKG': 'Total weight',
  detailsAssociatedWithUserProfileCannotBeEdited:
    "These details are now associated with another user's profile and cannot be edited.",
  detailsDisplayedAtSubLevel: 'Details displayed at sub-storage level',
  detailsDisplayedSubstorage: 'Details displayed at sub-storage level',
  detailsToContactCustomerSupport: 'Details to contact customer support ',
  developer: 'Developer',
  difference: 'Difference',
  differentCrop: 'Different crop',
  digitiseGrainContracts: 'Digitise grain contracts',
  diseaseBaterial: 'Disease - Bacterial',
  diseaseFungal: 'Disease - Fungal',
  diseaseViral: 'Disease - Viral',
  dismiss: 'Dismiss',
  doNotAlertBuyersOnTheOpenMarket: 'Do not alert buyers in the open market',
  doThisLater: 'Do this later',
  doYouKnowMovementDate: 'Do you know the exact movement date?',
  doYouKnowPlantingDate: 'Do you know the exact planting date?',
  doYouRequireTheCropToBeFarmAssured: 'Do you require the crop to be farm assured?',
  doYouStoreCropFromDifferentFarms:
    'Do you store and sell crop from different farms? You can add these to your farm locations with the farms assurance scheme for simpler trading.',
  doYouStoreInventoryFor3rdParties: 'Do you store inventory for 3rd parties?',
  doYouWantToReceive: 'Do you want to receive trading news and insights?',
  doYouWantToSaveYourChanges: 'Do you want to save your changes?',
  documents: 'Documents',
  documentsAdded: 'Document added | Documents added',
  documentsNotIncludedInCsv: 'Please note that the documents uploaded are not included in this CSV.',
  documentsPerPage: 'Rows per page',
  done: 'Done',
  doneEditing: 'Done editing',
  dontForgetToFillLoadDetails: "Don't forget to add your loads to each pre-filled location.",
  dontHaveAnAccount: "Don't have an account?",
  dontHesitateToContactUs: 'Don’t hesitate to contact us.',
  dontKnowYourPassword: "Don't know your password?",
  dontWorry: "Don't worry, we can get this issue sorted for you.",
  doubleCheckPODSubmission: 'Please double check before submission as this POD cannot be modified later.',
  down: 'Down',
  download: 'Download',
  downloadAll: 'Download all',
  downloadFarmToApp: 'Download FarmTo app',
  'downloadInvoice[PDF]': 'Download invoice [PDF]',
  downloadNow: 'Download now',
  downloadOnAppStore: 'Download on the app store',
  downloadOurApp: 'Download our new app now',
  downloadTemplateCSV: 'Download template (.csv)',
  downloadTradeSummary: 'Download trade summary',
  dragAndDropFilesHere: 'Drag and drop your files here, or',
  dragDropHere: 'Drag and Drop files here',
  drive: 'Drive',
  driveVehicleRegistration: 'Driver and vehicle registration',
  driver: 'Driver',
  driverAndVehicle: 'Driver and vehicle',
  driverChangeWillApplyImmediately: 'Driver will immediately receive new route on their devices.',
  driverInCharge: 'Driver in charge',
  driverIncharge: 'Driver in charge',
  driverMustUploadSampleWhenUnloadGrain: 'Driver must upload a sample when unloading grain',
  driverName: 'Driver name',
  driverNotes: 'Notes for driver',
  driverNumberError: "Please check this is a valid number that isn't already registered to your organisation.",
  driverPlural: 'Driver | Drivers',
  driverRecordWeightAtLocationWithImage:
    'Require the driver record the weight in the app at this location, ideally with an image',
  driverReg: 'Driver Reg',
  drivers: 'Drivers',
  driversAssigned: 'Drivers assigned',
  drivingFor: 'Driving for',
  dropFilesHereOrClickBrowse: 'Drop your files here, or {uploadLink}',
  dropOffLocation: 'Drop off location',
  dryerDamage: 'Dryer damage',
  dryerShrink: 'Dryer shrink',
  drying: 'Drying',
  dryingService: 'Drying service',
  dryingServiceLossMatrixTable: 'Drying service - loss matrix table',
  due: 'Due',
  dueDate: 'Due date',
  duplicate: 'Duplicate',
  duplicateThisMovementForm: 'Duplicate this movement form',
  eachStepRepresentsStop: 'Each numbered step is a leg in the job',
  edit: 'Edit',
  editAsset: 'Edit load',
  editAssetType: 'Edit {assetType} load',
  editBankDetails: 'Edit bank details',
  editBid: 'Edit bid',
  editBusiness: 'Edit your contact in {businessName}',
  editBusinessUnit: 'Edit details - {name}',
  editBusinessUnitSuccessTitle: 'Your farm location has been updated',
  editContract: 'Edit contract',
  editContractDetails: 'Edit contract details',
  editContractInfo: 'Edit contract info',
  editCrop: 'Edit crop',
  editCropTypes: 'Edit crop types',
  editCropTypesInformation:
    'If you want to move inventory here, update the types of crops that can be stored here now.',
  editCustomer: 'Edit customer',
  editDetails: 'Edit details',
  editExistingInventory: 'Edit existing inventory in your store',
  editField: 'Edit field',
  editGroup: 'Edit group',
  editImage: 'Edit image',
  editInventory: 'Edit inventory',
  editInventoryRecord: 'Edit inventory record',
  editJob: 'Edit job',
  editLoads: 'Edit loads',
  editLocation: 'Edit location',
  editLocationGroup: 'Edit location group',
  editLocationGroupSuccessTitle: 'Your location group has been updated',
  editMovement: 'Edit movement',
  editName: 'Edit name',
  editOrganisation: 'Edit organisation',
  editPlannedMovementDetails: 'Edit planned movement details',
  editPlantedInventoryInYourField: 'Edit planted inventory in your field',
  editPrice: 'Edit price',
  editPriceOffer: 'Edit price offer',
  editProfile: 'Edit Profile',
  editQuote: 'Edit quote',
  editSample: 'Edit sample',
  editSampleRecord: 'Edit sample record',
  editSampleResults: 'Edit sample results',
  editSettlementAndDelivery: 'Edit settlement and delivery',
  editSpecShowBuyersCropDetails:
    'You can edit this specification to show buyers how this has changed from the crop default.',
  editSpecification: 'Edit specification',
  editStore: 'Edit store',
  editTargetPrice: 'Edit target price',
  editTrailer: 'Edit trailer',
  editUserX: 'Edit: {user}',
  editVehicle: 'Edit vehicle',
  editWeight: 'Edit weight',
  editedSpecBasedOnSampleInfo: 'Edited specification based on the sample information provided',
  editingABid: 'Editing a bid',
  editingAPrice: 'Editing a price',
  editingYourBidWillReplaceYourInitialBidDoYouWantToContinue:
    'Editing your bid will replace your initial bid. Do you want to continue?',
  editingYourPriceWillReplaceYourInitialBidDoYouWantToContinue:
    'Editing your price will replace your initial bid. Do you want to continue?',
  egX: 'e.g. {example}',
  eligibleOffersForCropInStoreMovementXMonths: `Eligible for this year’s harvest in-store, moving within {months} months to approved buyers`,
  email: 'Email',
  emailAddress: 'Email address',
  emailDomain: 'Email domain',
  emailOrMobileNumber: 'Email or mobile number',
  emailUs: 'Email us',
  emailWithDetailsOfTheIssue: 'Email us with details of the issue',
  emailsNewsAndUpdates: 'Email - News and updates',
  emptyBusinessUnits: 'No business units have been created yet',
  emptyInventory: 'You have no locations to view your inventory',
  emptyLocationGroups: 'No location groups have been created yet',
  enable: 'Enable',
  enableLocationServices: 'Enable location services',
  enablePushNotificationGetInstantAlerts:
    'Enable push notifications to get instant alerts on new jobs, route updates and other important job messages.',
  enablePushNotifications: 'Enable push notifications',
  enableWelcomeTutorials: 'Enable welcome tutorials',
  enabledCommodities: 'Enabled commodities',
  enablingBackgroundLocationServicesWillAllow:
    'Enabling background location services will allow you to use your phone for other tasks while maintaining         the functionality',
  end: 'end',
  endDate: 'End date',
  endImpersonation: 'End impersonation',
  endListing: 'End listing',
  endListingNow: 'End listing now',
  endOfJob: 'End of job',
  endRequest: 'End request',
  endTheListing: 'End the listing',
  endThisListing: 'Sell more tonnage or end this listing?',
  endThisListingCTA: 'End this listing',
  endThisListingTitle: 'End this listing?',
  endThisTradeRequest: 'End this request',
  endToAllVerifiedBuyers: 'Send to all verified buyers',
  endTradeRequest: 'End request',
  ended: 'Ended',
  endedListings: 'Ended listings',
  ending: 'Ending',
  endingIn: 'Ending in',
  endingTheListingWillNotifyBuyers:
    'Ending the listing will notify buyers with price offers that you no longer wish to sell the crop.',
  endingToday: 'Ending today',
  endingTomorrow: 'Ending tomorrow',
  engineSize: 'Engine size',
  enhanceYourProfile: 'Enhance your profile',
  enhanceYourProfileWithLogo: '💼 Enhance your profile',
  ensureLoadTotalsCorrect: 'Ensure all load/unload fields match total load',
  ensurePODDetailsAreCorrect:
    'Please check your POD details are correct, they can’t be modified after submission.',
  enterAValidNumberToVerfiyAssurance:
    'Enter valid scheme/membership number so we can show the buyer that you’re verified. If no number is entered, we will show that on your listing.',
  enterAddressManually: 'Enter address manually',
  enterCodeAndPassword: 'Enter your reset code and choose a new password for your account.',
  enterCustomTimeForSellerToRespond: 'Enter a custom time for how long the seller has to respond to your offer',
  enterEmailAddressOrMobileWellSendSecureLink:
    'Enter the email address or mobile number for your account and we’ll send you a secure sign in link. ',
  enterMessageProvideDetail: 'Enter your message here, please provide as much detail as possible.',
  enterMinMaxPreparedBuySellerDecides:
    'Enter a min and max amount you are prepared to buy. The seller with then decide how much to sell.',
  enterRealisticPrice: 'Please enter a realistic price',
  enterRecordWeight: 'Enter the recorded weight',
  enterRecordedWeight: 'Enter the recorded weight.',
  enterSQCMembershipNumber: 'Enter SQC membership number',
  enterTotalPriceChargeJob: 'Enter the total price you will charge for the job',
  enterYourBankAccountAndConfirmAllYourDetails:
    'Enter your bank account and confirm all your details are correct to get paid',
  ergot: 'Ergot',
  error: 'Error',
  errorAddressLookup: 'Error looking up address',
  errorCreatingStorageNote: 'Error creating extra store information',
  errorDeletingFile: 'Error deleting file',
  errorFetchingSalesContracts: 'Error fetching sales contracts',
  errorFetchingStorageContracts: 'Error fetching storage contracts',
  errorLinkingDelivery:
    'There was an error linking this delivery to the storage contract, please add manually via storage contracts page.',
  errorLoading: 'Error loading',
  errorLoadingReport: 'Error loading report',
  errorLoadingStats: 'Error loading stats',
  errorLoadingTR: 'Error loading Transport Request.',
  errorLoadingTransportRequest: 'Error loading Transport Request.',
  errorLoggingInWithToken: 'There was an error logging in with that link, it may have expired',
  errorNotFound: 'Error: {noun} could not be found',
  errorRequestingUpgrade:
    "There was an error requesting an upgrade please try to contact directly or via the 'need help' option in sidebar",
  errorSigningOut: 'There was an error signing out, please reload and try again',
  errorUploadingContract: 'Error uploading contract',
  errorUploadingFile: 'Error uploading file',
  errorUploadingLoads: 'Error uploading loads',
  errorVerifyingMagicLink: 'Magic links are one-time-use and can expire',
  est: 'Est.',
  estPlantingDate: 'Est. planting date',
  estimateWillAppearHere: 'Estimate will appear here',
  estimatedAmountLeftForHarvest: 'Estimated amount left for harvest',
  estimatedContractValue: 'Estimated contract value',
  estimatedOutput: 'Estimated Output',
  estimatedPickup: 'Estimated pickup',
  estimatedQuote: 'Estimated quote',
  estimatedYield: 'Estimated yield',
  eta: 'ETA',
  eventLogs: 'Event logs',
  events: 'Events',
  everyone: 'Everyone',
  'ex-farm': 'Ex Farm',
  exFarm: 'Ex Farm',
  exFarmEstimator: 'Ex-Farm Estimator',
  exFarmFeed: 'Ex-farm Feed',
  exampleOffer: 'Example offer',
  exclusiveLoad: 'Exclusive load',
  'exclusiveMarketInsights:UnlockForPricing':
    'Exclusive marketing insights: unlock now for UK wide pricing insight',
  exclusivePricingInsights: 'Exclusive pricing insights',
  existingInventory: 'Existing inventory',
  existingQuotes: 'Existing quotes',
  existingUploadTermsAndConditions:
    'You have uploaded your terms and conditions, please note that we can only store one version. Any updates will need to replace the existing version on file. To do this click the ‘Edit’ button.',
  exit: 'Exit',
  exitAndGoToStorageContracts: 'Exit and go to storage contracts',
  expand: 'Expand',
  expandDetails: 'Expand details',
  expectedPrice: 'Expected price',
  expectedYield: 'Expected yield',
  experienceFarmToApp: 'Experience the benefits for yourself with the free FarmTo app.',
  expired: 'Expired',
  expiredOffered: 'Expired offer',
  expiredPriceOfferFromX: 'Expired price offer from {buyer}',
  expiredPriceOffers: 'Expired price offers',
  expiredPrices: 'Expired prices',
  expires: 'Expires',
  expiresIn: 'Expires in',
  expiringIn: 'Expiring in',
  expiry: 'Expiry',
  expiryDate: 'Expiry date',
  expiryLength: 'Expiry length',
  expiryUnit: 'Expiry unit',
  exploreBuyerDemandAndReviewWanted: 'Explore buyer demand and review recently posted Wanted crops',
  export: 'Export',
  exportAsCsv: 'Export as csv',
  exportCsv: 'Export {name} (CSV)',
  exportDataCSVModal: 'Export data (CSV)',
  exportDataDateRangeModal: 'Download based on current selected date range',
  exportDataDownloadAllModal: 'Download all load data',
  exportDataNoteModal: '(Note: Large downloads can take a few minutes, please keep your browser open)',
  exportDataSubTitleModal: 'Choose how you would like to download the data',
  exportIntakeRecordsCsv: 'Export intake records (CSV)',
  exportInventoryData: 'Export inventory data (CSV)',
  exportOutboundMovementRecordsCsv: 'Export external movement records (CSV)',
  exportPODRecordsCSV: 'Export POD records (CSV)',
  exportStorageList: 'Export storage list',
  external: 'External',
  externalField: 'External field',
  externalLocations: 'External locations',
  externalMovementCreated: 'External movement created',
  externalMovements: 'External movements',
  externalProfilePreview: 'External profile preview',
  facilities: 'Facilities',
  failed: 'Failed',
  failedToLoad: 'Failed to load',
  faqs: 'FAQs',
  farm: 'Farm',
  farmAdded: 'Farm added',
  farmAssurance: 'Farm assurance',
  farmAssuranceScheme: 'Farm assurance scheme',
  farmAssured: 'Farm assured',
  farmFinishSettingUpToPostAListing: 'Farm details are incomplete. Finish setting up to post a listing.',
  farmHoldingNumber: 'Farm holding number',
  farmLocation: 'Farm location',
  farmLocationName: 'Farm location name',
  farmLocationUpdated: 'Farm location updated',
  farmLocations: 'Farm locations',
  farmRemovedFromYourAccount: 'Farm removed from your account',
  farmTo: 'FarmTo',
  farmToApp: 'FarmTo app',
  farmToQuoteInviteSpiel:
    'FarmTo is free to use and dedicated to helping you earn more, keep more of your hard-earned pay while removing many of the day-to-day hassles of the job.',
  farmerTypicallyList4Loads: 'Farmers typically list 4 loads to attract the most interest from buyers',
  farmersAreSeeingMarketPricesBetween: 'Farmers are seeing market prices between',
  farmersMostCommonlyListToAttractTheBestOffers:
    'Farmers most commonly list at least 116t on Hectare to attract the best offers',
  farmtoLogo: 'FarmTo Logo',
  feedWheat: 'Feed Wheat',
  feedWheatSpotPrices: 'Feed Wheat spot prices',
  feedback: 'Feedback',
  feedlot: 'Feedlot',
  feelFreeToSetupAnIcon:
    'Feel free to set your face/crop as an icon and introduce your business in the bio. This will help leave a lasting impression on potential buyers',
  feesAreOnlyTakenOnMovement: 'Fees are only taken on movement',
  feesTakenOnFirstAdvance: 'Fees only taken on first advance',
  female: 'Female',
  fertiliser: 'Fertiliser',
  field: 'Field',
  fieldSize: 'Field size',
  fieldTips: {
    fieldBulkMovementCommodityMix: 'Please do not mix crop/group if you want to create a bulk movement to stores.',
    listingDetails: 'You can choose a range of your preferred movement months to receive bids on.',
    listingDetailsTradeRequest:
      'Some of the information has been pre-filled for your convenience based on the information from the buyer’s trade request.',
    locationTypeOfPlanting:
      'You can only add inventory to a single location for planting. If you grow in different fields, add as a separate inventory.',
    marketBuyers:
      "You have two choices when posting your market listing.  To gain maximum exposure, make sure you alert all buyers and your own buyers (first button). Buyers targeted will be relevant to the crop that you're sellling. If you would like to keep the trade closed, you are able to select specific buyers from your buyers list (second button). Happy trading!",
    plannedMovementDate:
      'We will calculate available storage capacity based on your planned movement date. You can change the movement day at anytime when you have confirmed your logistics arrangements.',
    recordAttributes: 'This is temp copy for the attributes field tip body',
    recordVariety:
      'You can search for an existing variety or type and create a new one. New varieties created will be saved for future use.',
    updateYourPassword: 'A secure password should include',
  },
  fields: 'Fields',
  fieldsX: 'No fields | {count} field | {count} fields',
  fileDeletedSuccess: 'File deleted successfully',
  fileName: 'File name',
  fileUpload: 'File upload',
  fileUploaded: 'File uploaded',
  fileUploadedSuccess: 'File uploaded successfully',
  filesAccepted10mb: 'files accepted up to 10MB each',
  filesUpTo10MBEach: 'Files up to 10MB each',
  fillFormBelow:
    'Please fill in the form below for assistance, someone from the team will get back to you as soon as possible.',
  fillInAsManySections: 'Fill in as many sections as you can. All will need adding before the record is complete',
  fillInSectionsForRecordToComplete:
    'Fill in as many sections as you can. All will need adding before the record is complete',
  fillOutSpecManually: 'Please fill out specification manually',
  filter: 'Filter',
  filterBy: 'Filter by',
  filterByCarrier: 'Filter by carrier',
  filterByCrop: 'Filter by crop',
  filterByDrivers: 'Filter by Drivers',
  filterByKeyword: 'Filter by keyword',
  filterByLocation: 'Filter by location',
  filterByOrganiser: 'Filter by organiser',
  filterByToSeeWhatsSold:
    'You can filter by crop, type, movement month and business unit so you can see what’s sold at any time',
  filterTranslations: {
    activityType: 'Record type',
    assetType: 'Inventory type',
    attributes: 'Attributes',
    businessArea: 'Business area',
    businessUnits: 'Business units',
    carrierId: 'Carrier',
    category: 'Category',
    crop: 'Crop',
    groupGrade: 'Group',
    harvestYear: 'Harvest year',
    inventoryOwner: 'Inventory owner',
    inventoryType: 'Inventory type',
    location: 'Location',
    managementType: 'Management type',
    movingFrom: 'Moving from',
    movingTo: 'Moving to',
    organiserId: 'Organiser',
    owned: 'Owned',
    owner: 'Inventory owner',
    ownerStatus: 'Ownership',
    region: 'Region',
    salesStatus: 'Sales status',
    seller: 'Seller',
    sortByMovement: 'Sort by',
    status: 'Status',
    storageOwner: 'Storage owner',
    storageType: 'Storage type',
    thirdPartyOwned: '3rd Party Owned',
    thirdPartyOwner: 'Third party owner',
    typeClass: 'Type',
    variety: 'Variety',
  },
  filters: 'filters',
  final: 'final',
  finalCollectionReceiptUploaded: 'Final collection receipt uploaded',
  finalSettlementWithTheUsualBuyerTerms: 'Final settlement with the usual buyer terms',
  finalTradeSettlement: 'The final trade settlement',
  finalising: 'Finalising',
  finance: 'Finance',
  financeContactName: 'Contact name',
  financeContactNumber: 'Contact Number',
  financeSummary: 'Finance summary',
  financedTrades: 'Financed trades',
  findAddress: 'Find address',
  findBillingAddress: 'Find billing address',
  findLocation: 'Find location',
  findOutHowMuchYouNeedToSellInEachArea:
    'Find out how much you need to sell in each area to achieve your desired position.',
  findOutMoreHere: 'find out more here',
  finish: 'Finish',
  finishSettingUpYourBusiness: 'Finish setting up your business',
  finishSetup: 'Finish setup',
  fire: 'Fire',
  firstEmailTheBuyersContractToEmail: `First, email the buyer’s contract to <a style="color: #3B47E3;" href="mailto:help@wearehectare.com">help@wearehectare.com</a>`,
  firstName: 'First name',
  firstPickupLocation: 'First pick-up location',
  fixAContract: 'Fix a contract',
  fixAgainstABaseContract: 'Fix against a base contract',
  fixAnotherContract: 'Fix another contract',
  fixContract: 'Fix contract',
  fixContractPrice: 'Fix contract price',
  fixPremiumTo: 'Fix premium to',
  fixYourContractPrice: 'Fix your contract price',
  fixed: 'Fixed',
  fixedContracts: 'Fixed contracts',
  fixedSet: 'Fixed set',
  fixedToPremiumContract: 'Fixed to Premium contract',
  fixedValue: 'Fixed value',
  flagIndicatesConflict: 'A red flag indicates over capacity or that there is an inventory shortfall to resolve.',
  flatNumber: 'Flat number',
  flexTonnage: 'Flex the tonnage you choose to sell when the price is right',
  flexiblePricingWithMovementMonths: 'Flexible pricing for different movement months',
  floorPriceX: 'Floor price ({currency}/{unit})',
  followingThisYourAdvancePaymentWillBeInYourBankWithinXDays:
    'Following this, your advance payment will be in your bank within {days} working days.',
  for: 'for',
  forHowWeLookAfterYourData: 'for how we look after your data.',
  forSale: 'For sale',
  forYourGrain: 'for your grain',
  forYourReferenceHereAre: 'For your reference here are the',
  forYourSecureSignInLinkToArrive: 'for your secure sign in link to arrive',
  forecastedInventory: 'Forecasted inventory',
  forgotYourPassword: 'Forgot your password',
  forgottenPassword: 'Forgot your password?',
  formFields: {
    KgHl: 'Kg/Hl',
    SQCNumber: 'SQC number',
    accountHolderName: 'Account holder name',
    accountNickname: 'Account nickname',
    accountNumber: 'Account number',
    addCustomTags: 'Add custom tags',
    additionalInformation: 'Additional information',
    address: 'Address',
    addressCity: 'Town or city',
    addressLineOne: 'Address line 1',
    addressLineTwo: 'Address line 2',
    addressPostcode: 'Postcode',
    addressRegion: 'Region',
    adjustSelectedInventoryBy: 'Adjust selected inventory by',
    adjustYield: 'The type of yield adjustment',
    adjustmentReason: 'Adjustment reason',
    agreedStorageQuantity: 'Agreed storage quantity',
    amount: 'Amount',
    areaRemainingToCut: 'Area remaining to cut',
    assetType: 'Asset type',
    assetValue: 'Asset value',
    attributes: 'Attributes',
    bankAccountName: 'Bank account name',
    bankName: 'Name of bank',
    basePrice: 'Base price',
    bicSwift: 'BIC Swift',
    bidAmount: 'Bid amount',
    billingAddress: 'Billing address',
    billingContactName: 'Billing contact name',
    billingContactNumber: 'Billing contact number',
    breed: 'Breed',
    buisnessUnit: 'Business unit',
    businessName: 'Business name',
    businessRelationship: 'Business relationship',
    businessUnit: 'Business unit',
    businessUnits: 'Business units',
    buyerNotes: 'Buyer notes',
    capacity: 'Capacity',
    carrier: 'Carrier',
    category: 'Category',
    chooseStore: 'Choose Store',
    chooseTimeframe: 'Choose timeframe',
    class: 'Class',
    co2Emissions: 'Co2 emissions',
    collectionInformationBy: 'Collection by',
    collectionTime: 'Collection time',
    colour: 'Colour',
    commodityDetails: 'Commodity details',
    companyNumber: 'Company number',
    confirmNewPassword: 'Re-enter new password',
    contactEmailAdress: 'Contact email address',
    contactNumber: 'Contact number',
    contract: 'Contract',
    contractDate: 'Contract date',
    contractId: 'Contract ID',
    contractNumber: 'Contract number',
    contractStartDate: 'Contract start date',
    coordinates: 'Latitude and longitude',
    cost: 'Cost',
    country: 'Country',
    crop: 'Crop',
    cropDetails: 'Crop details',
    cropPriceAmount: 'Crop price',
    cropType: 'Crop type',
    crops: 'Crops',
    currentPassword: 'Current password',
    customer: 'Customer',
    date: 'Date',
    daysOfMonthAfterMovement: 'Days of month after movement',
    deduction: 'Deduction',
    deductionReason: 'Deduction reason',
    deliveryDate: 'Delivery date',
    documents: 'Documents',
    driver: 'Driver',
    driverInCharge: 'Driver in charge',
    dropOffLocation: 'Drop off location',
    email: 'Email',
    emailAddress: 'Email address',
    endMonth: 'End month',
    engineCapacity: 'Engine capacity',
    estimatedAmount: 'Estimated amount',
    estimatedOriginArrival: 'Estimated arrival at first pick up point',
    example: 'Example',
    exisitingInventoryCapacity: 'Existing inventory capacity',
    exisitingInventoryFill: 'Existing inventory',
    existingInventoryOwner: 'Existing inventory owner',
    expectedTotalYield: 'Expected total yield',
    expectedYield: 'Expected yield',
    farmAssured: 'Farm assured',
    field: 'Field',
    files: 'Files',
    firstName: 'First name',
    fixedPrice: 'Fixed price',
    fuelType: 'Fuel type',
    generalNotes: 'General notes',
    grade: 'Grade',
    group: 'Group',
    groupGrade: 'Group/Grade',
    guidePriceMax: 'Max guide price',
    guidePriceMin: 'Min guide price',
    hagberg: 'Hagberg',
    handlerMobile: 'Handler mobile',
    handlerName: 'Handler name',
    harvestYear: 'Harvest year',
    haulage: 'Haulage',
    haulageTerms: 'Haulage terms',
    height: 'Height',
    holdingNumber: 'Holding number',
    hours: 'Hours',
    howDidYouHearAboutUs: 'How did you hear about us?',
    iban: 'IBAN',
    identifier: 'Email or mobile phone number',
    image: 'Image',
    intake: 'intake',
    inventoryType: 'Inventory type',
    invoiceDate: 'Invoice date',
    invoiceDueDate: 'Invoice due date',
    invoiceRef: 'Invoice ref',
    jobType: 'Job type',
    lastName: 'Last name',
    lastV5ciIssueDate: 'Last V5C issued',
    latitude: 'Latitude',
    legalNumber: 'Holding Number',
    lngLatHidden: 'Address',
    load: 'Load',
    loadDetail: 'Load detail',
    loadInsuranceDocument: 'Load insurance document',
    loadInsuranceExpiryDate: 'Load insurance expiry date',
    loadReference: 'Load reference',
    loadWeight: 'Load weight',
    location: 'Location',
    longitude: 'Longitude',
    make: 'Make',
    max: 'Max',
    maxAge: 'Max age',
    maxDecks: 'Number of decks',
    maxPrice: 'Max price',
    maxQuantity: 'Max quantity',
    measure: 'Measure',
    message: 'Message',
    min: 'Min',
    minAge: 'Min age',
    minPrice: 'Min price',
    minQuantity: 'Min quantity',
    minutes: 'Minutes',
    mobile: 'Mobile',
    mobileNumber: 'Mobile number',
    model: 'Model',
    moisture: 'Moisture',
    moistureMaxPercentage: 'Max',
    moistureMinPercentage: 'Min',
    moreAboutYou: 'More about you',
    motStatus: 'MOT status',
    movementEndDate: 'Movement end date',
    movementMonth: 'Movement month',
    movementPeriod: 'Movement period',
    movementRef: 'Movement ref',
    movementReference: 'Movement reference',
    movementStartDate: 'Movement start date',
    name: 'Name',
    nameOfAuthorisedIndividual: 'Name of authorised individual',
    netWeight: 'Net weight',
    newPassword: 'New password',
    note: 'Note',
    notes: 'Notes',
    numberDecks: 'Number of decks',
    organiser: 'Organiser',
    ownership: 'Ownership',
    password: 'Password',
    paymentReceivedDate: 'Payment received date',
    paymentTerms: 'Payment terms',
    paymentTermsFromDeliveryDate: 'Payment terms from delivery date',
    pedigree: 'Pedigree',
    perTonne: 'Per tonne',
    pickUpLocation: 'Pick up location',
    pickupDate: 'Pickup date',
    pickupDateTime: 'Pickup date & time',
    plannedQuantity: 'Planned quantity',
    plantedInventoryAmount: 'Planted inventory amount',
    plantedInventoryOwner: 'Planted inventory owner',
    plantingAtUTC: 'Planting date',
    plantingExpectedYield: 'Predicted yield',
    plantingMonth: 'Planting month',
    plantingType: 'Field type',
    pleaseSpecify: 'Please specify',
    position: 'Position',
    postCode: 'Postcode',
    postcode: 'Postcode',
    preAgreedMoistureLevel: 'Pre-agreed moisture level',
    preciseLocation: 'Precise location',
    preferredMovementDate: 'Preferred movement date',
    premium: 'Premium',
    premiumPrice: 'Premium price',
    price: 'Price',
    priceAgreedWithCarrier: 'Price agreed with carrier',
    priceOffer: 'Price offer',
    pricePerHead: 'Value per head',
    pricePerHeadAmount: 'Price per head',
    pricePerUnit: 'Price per unit',
    pricePerUnitSold: 'Price per unit sold',
    pricePerUnitSoldPrice: 'Price per unit sold',
    priceVatAmount: 'Price VAT amount',
    pricingBasis: 'Pricing basis',
    pricingTerms: 'Pricing terms',
    primaryContact: 'Primary contact',
    proposedDate: 'Proposed date',
    protein: 'Protein',
    purpose: 'Purpose',
    quantity: 'Quantity',
    quantityToRoll: 'Quantity to roll',
    reason: 'Reason',
    recordType: 'Record type',
    reference: 'Job reference',
    region: 'Region',
    registration: 'Registration',
    relationshipType: 'Relationship type',
    requestQuotesDate: 'Request quotes date',
    requireGrainSample: 'Driver must upload sample',
    salesTaxLabel: 'Sales tax label',
    salesTaxPercentage: 'Sales tax percentage',
    schemeMembershipNumber: 'Scheme/Membership number',
    searchStore: 'Search store',
    secret: 'Password',
    selectMovementPeriod: 'Select movement period',
    selectedCarriers: 'Select carriers',
    selectedDestinations: 'Number of selected destinations',
    selectedMovement: 'Selected movement',
    sellerNotes: 'Seller notes',
    services: 'Services',
    sex: 'Sex',
    signUpPurpose: 'Sign up purpose',
    soldOffline: 'Sold offline',
    soldOnHectareTrading: 'Sold on Hectare Trading',
    sortCode: 'Sort code',
    sortcode: 'Sort code',
    species: 'Species',
    specificationDeviateDefault: 'Does your specification deviate from this default?',
    startMonth: 'Start month',
    stopType: 'Stop type',
    storage: 'Storage',
    subStoreSelection: 'This sub-storage selection',
    substoreCapacity: 'Your sub-store capacity',
    targetPrice: 'Target price',
    taxNumber: 'Tax number',
    taxStatus: 'Tax status',
    temperature: 'Temperature',
    term: 'Term',
    termsAndConditions: 'Terms and Conditions',
    ticketRef: 'Ticket ref',
    time: 'Time',
    tippedQuantity: 'Tipped quantity',
    title: 'Title',
    tonnesToSell: 'Tonnes to sell',
    totalCommittedTonnage: 'Total committed tonnage',
    totalPrice: 'Total price',
    totalSoldOffline: 'Total sold offline',
    totalWeight: 'Total weight',
    town: 'Town/City',
    trailerName: 'Trailer name',
    transportAssets: 'Number of loads',
    type: 'Type',
    typeGroup: 'Type & group',
    typeOther: 'Type',
    unload: 'Unload',
    unloadDetail: 'Unload detail',
    uploadWeighbridgeTicket: 'Upload weighbridge ticket',
    username: 'Email or mobile number',
    variety: 'Variety',
    vatTaxNumber: 'Vat / Tax number',
    vehicle: 'Vehicle',
    vehicleImage: 'Vehicle image',
    vehicleInsuranceDocument: 'Vehicle insurance document',
    vehicleInsuranceExpiryDate: 'Vehicle insurance expiry date',
    vehicleReg: 'Vehicle Reg',
    vehicleRegistration: 'Vehicle registration',
    vehicleType: 'Vehicle type',
    vrn: 'Vehicle registration',
    weight: 'Weight',
    weightAdjusted: 'Adjusted weight',
    weightEmpty: 'Weight empty',
    weightFull: 'Weight full',
    weightLossPercentage: 'Weight loss percentage',
    what3words: 'What3words',
    width: 'Width',
    year: 'Year',
    yield: 'Yield',
    yourBusinessUnitContactEmailAddress: 'Your contact email address',
    yourBusinessUnitContactName: 'Your contact name',
    yourBusinessUnitContactPhoneNumber: 'Your contact phone number',
    yourBusinessUnitName: 'Your business unit name',
    yourBusinessUnitType: 'Your business unit type',
    yourFarmLocationName: 'Your farm location name',
    yourFieldArea: 'Your field area',
    yourFieldName: 'Your field name',
    yourLocationGroupName: 'Your location group name',
    yourPhoneNumber: 'Your contact phone number',
    yourStoreCapacity: 'Your potential capacity',
    yourStoreCrop: 'Your potential crop',
    yourStoreName: 'Your store name',
    yourSubStoreName: 'Your sub-store name',
  },
  formSent: 'Form sent successfully.',
  formValidation: {
    areNotValid: '{_field_} are not valid',
    contractId: 'Contract ID',
    digits: '{_field_} must be {length} digits long',
    email: '{_field_} is invalid',
    isNotValid: '{_field_} is not valid',
    isSameNumber: '{_field_} must be {other}',
    loadReference: 'Load reference',
    max: '{_field_} must be less than {length} characters',
    maxLength: '{_field_} has a max length of {max} characters',
    maxLoad: 'Value is more than all available loads',
    maxValue: '{_field_} must be {max} or less',
    min: '{_field_} must be at least {length} characters',
    minValue: '{_field_} must be {min} or more',
    mustHaveAMinPricePerUnitValue: '{fieldName} cannot be below £0.01',
    mustHaveASelectedOption: '{fieldName} option must be selected',
    numeric: '{_field_} must contain only numbers',
    passwordsDoNotMatch: 'Passwords do not match',
    regex: '{_field_} must be valid',
    required: '{_field_} is required',
    someValueHasPropertyValue: 'At least one item must have a {keyName} of {propValue}',
    someValueHasPropertyValues: 'An item must have a {keyName} of any of: {propValues}',
  },
  foundExistingAccountWithName:
    'We found an existing account with your business name {companyName}.  Is this your business?',
  foundStorageLocationForCompany:
    'We found a storage contract with {companyName}. Please confirm the information below.',
  foundVehicleInformationCompleteEmptyFields:
    'We have found the following information about your vehicle from the DVLA. Please complete any empty fields.',
  frequentlyAskedQuestions: 'Frequently asked questions',
  from: 'From',
  fromTeamHectare: 'From Team Hectare',
  frontMonth: 'Front Month',
  fulfilled: 'Booked',
  fulfillmentsAndSettlements: 'Fulfilments and settlements',
  fulfilmentOfContract: 'Fulfilment of contract',
  fullCropDetails: 'Full crop details',
  fullNameOfPayee: 'Full name of payee',
  futureIntake: 'Future intake',
  futureMovements: 'Future Movements',
  futurePlanting: 'Future planting',
  futuresPrices: 'Futures prices',
  general: 'General',
  generateTradeSummary: 'Generate trade summary',
  getAlertsOnCropTrades: 'Get alerts on crop trades to help you make smart marketing decisions.',
  getAnAccount: 'Get an account',
  getHelp: 'Get help',
  getInTouch: 'Get in touch',
  getInstantNotificationsWhenABuyerWantsYourCrop:
    'Get instant notifications when a buyer posts a wanted listing for crop you have in your inventory.',
  getItOnGooglePlay: 'Get it on Google Play',
  getMoreBids: 'Get more bids',
  getNotificationsWhenAddingInventory: 'Get notifications when adding inventory',
  getNotificationsWhenTheMarketGoesBelowYourFloorPrice:
    'Get notifications when the market goes below your floor price or above your target price',
  getPaidForYourGrainNow: 'Get paid for your grain, now',
  getPaidNowWith: 'Get paid now with',
  getPaidNowWithAdvancedPay: 'Get paid now with Advance Pay',
  getPaidWithinXWorkingDays: 'Get paid within {days} working days',
  getQuickQuote: 'Get quick-quote',
  getQuotesFromCarriers: 'Get quotes from carriers',
  getStarted: 'Get started',
  getStartedAndFixAContractToThisPremium: 'Get started and fix a contract to this premium.',
  getStartedNow: 'Get started now',
  getStartedWithRecommendedActionsOrExplore:
    'Get started with the actions we recommend on the Actions page, or feel free to explore the platform on your own.',
  getThe: 'Get the',
  getYourAdvancePaymentInYourBankWithinXWorkingDays:
    'Get your advance payment in your bank within {days} working days',
  gettingStarted: 'Getting started',
  goBack: 'Go back',
  goBackToMyListings: 'Go back to my listings',
  goBackToSignIn: 'Go back to sign in',
  goHome: 'Go home',
  goToBusinessNetwork: 'Go to business network',
  goToInventory: 'Go to inventory',
  goToLogin: 'Go back to login',
  goToOffers: 'Go to offers',
  goodToKnow: 'Good to know',
  googlePlayBadge: 'Google play badge',
  gotIt: 'Got it',
  grade: 'Grade',
  grain: 'Grain',
  graindexPasswordResetConfirmationExplainer: 'You’ve been returned to the sign in page.',
  graindexPasswordResetConfirmationTitle: 'Password changed on your account',
  graindexSecurityUpdatePasswordNotification:
    'For security purposes, please update your password to access Hectare Trading.',
  graindexSetNewPassword: 'Security update - Create a new password',
  graphTranslations: {
    inField: 'In field',
    inFieldAdjustments: 'In field adjustments',
    inStore: 'In store',
    inStoreAdjustments: 'In store adjustments',
    movedAsSale: 'Moved - as sale',
    movedStoreExternally: 'Moved - store externally',
  },
  greatJobYourListingIsLive: 'Great job, your listing is live!',
  greatJobYouveFinishedHarvestingFieldX: 'Great job, you’ve finished harvesting {field}',
  greatNewsMorePotentialBuyers:
    'Great news! Lots more potential buyers will receive your listing which will bring in more bids. Keep any eye out.',
  greatWorkYouveAddedNewFarm: "Great work, you've added a new farm",
  greatWorkYouveNearlyCompletedYourHarvest:
    "Great work, you've nearly completed your harvest. Here's a breakdown of your projected vs predicted yield.",
  gross: 'Gross',
  grossBeforeDeductions: 'Gross - before deductions/VAT',
  grossWeight: 'Gross weight',
  group: 'Group',
  groupGrade: 'Group/Grade',
  groupMembers: 'Members',
  groupName: 'Group name',
  groupPermissions: 'Permissions',
  groupYourLocations: 'Group your locations',
  groups: 'Groups',
  guidePrice: 'Guide Price',
  guidePriceIsIndicativeNotFinalOffer: 'Your guide price is only indicative for sellers and not your final offer',
  guidePriceIsNotFinalOffer: 'Your guide price is not your final offer',
  guidePriceToGetSellersInterested:
    'Your optional price offer is a way to get sellers interested in your wanted request.',
  hagberg: 'Hagberg',
  hagbergMax: 'Hagberg Max',
  hagbergMin: 'Hagberg Min',
  hagbergSeconds: 'Hagberg (Seconds)',
  handler: 'Handler',
  handlerAtRegionLocationType: 'Handler at {locationType} - {region}',
  handlerPlural: 'Handler | Handlers',
  handlers: 'Handlers',
  harvest: 'Harvest',
  harvestComplete: 'Harvest complete',
  harvestDate: 'Harvest date',
  harvestPlan: 'Harvest Plan',
  harvestPlanComplete: 'Harvest plan complete',
  harvestPlans: 'Harvest Plans',
  harvestProgress: 'Harvest progress',
  harvestProgressConfirmed: 'Harvest progress confirmed',
  harvestRecords: 'Harvest records',
  harvestYear: 'Harvest year',
  harvested: 'Harvested',
  harvestedInventory: 'Harvested inventory',
  hasBeenDeclined: 'has been declined',
  hasBeenFullyVerifiedBuyerByHectare: 'Has been fully verified by Hectare as a legitimate buyer',
  hasBeenNotifiedAndWillBeInTouch:
    'will receive an alert that you’ve placed an offer, along with any expiry time you may have set on the price. All you have to do is sit back and wait for their response.',
  hasBeenSubmitted: 'has been submitted',
  hasInvitedYouToFarmTo: ' has invited you to join Farmto',
  hasThisCropBeenSold: 'Has this crop been sold?',
  haulage: 'Haulage',
  haulageTerms: 'Haulage terms',
  haveALookAtListingReceived:
    'Have a look at your listings received to see if there’s anything you would like to make an offer on.',
  haveAcceptedBidNowCloseMarketListing:
    'As you have accepted a bid, would you like to now close this market listing? Buyers will be notified their bid has been unsuccessful.',
  haveAlookAtLiveListing: 'Have a look at your live listings to see any prices you might have received',
  haveAnAccount: 'Have an account?',
  haveYouFinishedCuttingThisField: 'Have you finished cutting this field?',
  haveYouSampledTheCrop: 'Have you sampled the crop?',
  head: 'Head',
  headOnInSeeIndividualListing:
    'Head on in and see this individual listing and send your price to the seller with this one off access.',
  headerBackground: 'Header background',
  hectareLogo: 'Hectare logo',
  hectarePricePrediction: "Hectare's price prediction",
  hectareTrading: 'Hectare trading',
  hectareTradingActivity: 'Hectare Trading activity',
  hectareTradingDailySpotPrices: 'Hectare Trading - Daily spot prices',
  hectareTradingFeedWheatSpotPrices: 'Hectare Trading Feed Wheat spot prices',
  hectareTradingPrices: 'Hectare trading prices',
  hectareVerified: 'Hectare verified',
  hectaresHarvested: 'Hectares harvested',
  hectaresPriceInsights: 'Hectare’s price insights',
  height: 'Height',
  hello: 'Hello',
  help: 'Help',
  helpCentre: 'Help Centre',
  helpTopics: 'Help topics',
  helpsQaAssured: 'This helps us find out if it’s QA assured',
  herbicideInjury: 'Herbicide injury',
  here: 'here',
  hereAreYourUpcomingJobs: 'Here are your upcoming jobs',
  heresARepresentativeExample: 'Here’s a representative example',
  heresSomeOfTheFeaturesYoullSee: "Here's just some of the new features you'll see...",
  hgvEntrance: 'HGV entrance',
  hi: 'Hi',
  hide: 'Hide',
  hideAll: 'Hide all',
  hideDetails: 'Hide details',
  hideFullDetails: 'Hide full details',
  hideList: 'Hide list',
  hideLoadInfo: 'Hide load info',
  holdingMembershipNumber: 'Holding no. / Red Tractor membership no.',
  holdingNo: 'Holding no',
  holdingNumber: 'Holding number',
  home: 'Home',
  hour: 'hour',
  hourAgo: 'hour ago | hours ago',
  hours: 'hours',
  hoursPlural: 'no hours | one hour | {count} hours',
  hoursShorthand: 'h',
  howDidYouHearAboutUs: 'How did you hear about us?',
  howDoYouWantToHearAboutThem: 'How do you want to hear about them?',
  howDoYouWantToReceive: 'How do you want to receive your trading notifications?',
  howDoYouWantToReceiveYourTradingNotifications: 'How do you want to receive your Trading notifications?',
  howItWorks: 'How it works',
  howManyHectaresIsYourField: 'How many hectares is your field?',
  howMuchAreYouMovingHere: 'How much are you moving here?',
  howMuchCapacityWhenFull: 'How much capacity can be stored when full?',
  howMuchDoYouNeed: 'How much do you need?',
  howMuchDoYouNeedToMoveFromOriginLocation: 'How much do you need to move from the origin location?',
  howMuchDoYouWantToMove: 'How much do you want to move?',
  howMuchHaveYouSold: 'How much have you sold?',
  howMuchIsBeingStoredHere: 'How much is being stored here?',
  howMuchLeftToCut: 'How much is left to cut?',
  howMuchMoved: 'How much is being moved?',
  howMuchStored: 'How much is being stored?',
  howMuchStoredHere: 'How much is being stored here?',
  howMuchWouldYouLikeToSell: 'How much would you like to sell?',
  howTheTradeIsPaid: 'How the trade is paid',
  howWouldYouLikeToAdjustYield: 'How would you like to adjust yield?',
  howWouldYouLikeToMarketYourListing: 'How would you like to market your listing?',
  hrs: 'hrs',
  iAgree: 'I agree',
  iCanConfirmIAmSupplyingAssuredGrain: 'I confirm that I am supplying assured grain',
  iConfirmMyPaymentDetailsAreCorrect:
    'I confirm my payment details are correct and I want to accept the advance payment',
  iDidntGetOffersFromBuyers: `I didn't get offers from buyers I'd like to trade with`,
  iDidntReceiveAnyPriceOffersThatWereHighEnough: `I didn't receive any price offers that were high enough`,
  iHaveCropsToSell: 'I have crops to sell',
  iHaveMoreCropTonnageToSell: 'I have more crop tonnage to sell',
  iOwnInventory: 'I own the inventory',
  iStillHaventReceivedMyLink: "I still haven't received my link",
  iUnderstand: 'I understand',
  iWantToBuy: 'I want to buy',
  iWantToCheckTheMarket: 'I want to check the market',
  iWantToCheckTodaysMarket: `I want to check todays market`,
  iWantToSell: 'I want to sell',
  iceLiffe: 'ICE | LIFFE',
  icon: 'Icon',
  ifAny: 'if any',
  ifBuyerAcceptsTradeWillBeConfirmed: 'If the buyer accepts, the trade will be confirmed.',
  ifInFieldAfterAdjustments:
    'If in field, this is the expected yield after any adjustments, if in store, this is the current quantity available to sell after any adjustments',
  ifInFieldWhenCreatedRecord:
    'If in field, this is the expected yield at time of creating the record, if in store, this is what was first put into store',
  ifLocationDiffersFromAssurance:
    'If your farm location differs from the assurance, please add the location details which will be made available when posting a listing',
  ifNoQAAssuredLication:
    'If your QA assured location isn’t showing please try a different post code or adjusting your holding number.',
  ifNoRegionIsChosenDefaultAllRegions: 'If no region is chosen this is defaulted to all regions.',
  ifQuoteAcceptedDownloadTheApp:
    'If your quote is accepted, you will receive a text message requesting you to download the {farmTo} app to see full details of the booking.',
  ifThereAreNewActionsDisplayedHere: 'If there are any updates, new actions will be displayed here',
  ifYouCantFindRightMovemementAddManually:
    'If you can’t find the right movement in the above list, add delivery details manually.',
  ifYouNeedAssistanceCallBelow: 'If you need assistance please call us',
  ifYouNeedFurtherAssistancePlease: 'If you need further assistance please',
  ifYouWantToChangeTheDateRange: 'If you want to change the date range, please reselect another range.',
  imAnAdvisor: "I'm an advisor",
  imBuying: "I'm buying",
  imSelling: "I'm selling",
  impersonate: 'Impersonate',
  impersonateAccountModalDescription:
    'By impersonating this business you will see only data related to their organisation until you end impersonation.',
  impersonateAccountX: 'Impersonate account: {businessName}',
  impersonateName: 'Impersonate {name}',
  impersonationEnded: 'Impersonation ended',
  importantInformation: 'Important information',
  improveYourCredibility: 'Improve your credibility with potential buyers by including a company logo and bio.',
  in: 'in',
  inField: 'In field',
  inFieldInventorySummary: 'In field inventory summary',
  inLineWithBuyerPaymentTerms: 'In line with buyer payment terms',
  inMetres: 'in metres',
  inStorage: 'In store',
  inStore: 'In store',
  inStoreInventorySummary: 'In store inventory summary',
  inTotal: 'in total',
  inTransit: 'In-transit',
  inTransitJobs: 'In-Transit jobs',
  inboundInventory: 'Inbound inventory',
  increase: 'Increase',
  individualInventoryRecord: 'Anything to do with an individual inventory record',
  informationAboutTheAdvance: 'Information about the advance',
  informationAboutTheAdvancePayment: 'Information about the advance payment',
  initialAdvance: 'Initial advance',
  initialAdvanceLoanTerm: 'Initial advance loan term',
  initialYield: 'Initial yield',
  insectDamage: 'Insect damage',
  insightLivestockSearch: {
    forAges: 'for {minAge}-{maxAge} months old',
    forAnyAge: 'for any age',
    forMaxAge: 'for up to {maxAge} months old',
    forMinAge: 'for at least {minAge} months old',
  },
  insightsToShapeGrainStrategy: 'Insights to shape your grain marketing strategy with free access to the ODA blog',
  intake: 'Intake',
  intakeDate: 'Intake Date',
  intakeReport: 'Intake report',
  intakeSampleMoistureLevel: 'Intake sample moisture level',
  intakeWeight: 'Intake weight',
  intakeWeightUpdatedFromWeighbridge: 'Intake weight has been auto-updated using weighbridge values',
  intakes: 'Intakes',
  interestEquals: 'Interest equals',
  interestFees: 'Interest fees',
  interestRate: 'Interest rate',
  interestedBuyersHaveBeenNotifiedAboutTheCrop: 'Interested buyers have been notified about the crop for sale',
  intermediateAdvance: 'Intermediate advance',
  intermediateAdvanceAfterFees: 'Intermediate advance, after fees',
  intermediateAdvanceLoanTerm: 'Intermediate advance loan term',
  internal: 'Internal',
  internalField: 'Internal field',
  internalMovementCreated: 'Internal movement created',
  internalMovements: 'Internal movements',
  internalPlural: 'Internal | Internals',
  internetConnectivityIssues: 'Internet connectivity issues?',
  invalid: 'Invalid',
  invalidMovements: 'Invalid movements',
  inventOwnedBy: 'Inventory owned by',
  inventory: 'Inventory',
  'inventory.commodity.grade': 'Crop grade',
  inventoryAdded: 'Inventory added',
  inventoryBreakdown: 'Inventory breakdown',
  inventoryContainsStoredInventory:
    'This location currently contains stored inventory. You will need to setup movements or trades to empty this storage location before deleting.',
  inventoryDeleteWarning:
    'Would you like to delete this inventory? Please note that the inventory cannot be retrieved after delete.',
  inventoryDetail: 'Inventory Detail',
  inventoryFunctionNotavailableOnMobile:
    'Sorry, the add new inventory function is not available on mobile. Please use desktop to upload inventory.',
  inventoryITEM: 'Inventory',
  inventoryInFields: 'Inventory in fields',
  inventoryInStore: 'Inventory in store',
  inventoryInformation: 'Inventory Information',
  inventoryIsRequiredInStore: 'Inventory is required in store, please check or add new inventory',
  inventoryLocation: 'Inventory location',
  inventoryOwned: 'Inventory owned',
  inventoryOwnedBy: 'Inventory owned by',
  inventoryOwner: 'Inventory owner',
  inventoryRecord: 'Inventory record',
  inventoryRecords: 'Inventory records',
  inventorySelectedCount:
    'No inventory records selected | 1 inventory record selected | {count} inventory records selected',
  inventorySentBy: 'Inventory sent by',
  inventoryShortfall: 'Inventory shortfall',
  inventorySold: 'Inventory sold',
  inventorySoldTo: 'Inventory sold to',
  inventorySummary: 'Inventory summary',
  inventoryType: 'Inventory type',
  inventoryUpdated: 'Inventory record updated.',
  invitePending: 'Invite pending',
  inviteResend: 'Invite re-sent',
  inviteResendError: 'Invite unable to be re-sent',
  inviteSent: 'Invite sent to {name}',
  inviteTeamMembers: 'Invite team members',
  inviteUsersViewAdjust: 'Invite new users, view and adjust permissions',
  invoice: 'Invoice',
  invoiceDate: 'Invoice date',
  invoiceDueDate: 'Invoice due date',
  invoiceId: 'Invoice {id}',
  invoiceNo: 'Invoice no.',
  invoicePrefix: 'Invoice prefix',
  invoiceRef: 'Invoice ref',
  invoiceSettings: 'Invoice settings',
  invoiceTo: 'Invoice to',
  invoiced: 'Invoiced',
  invoices: 'Invoices',
  invoicesSavesTimeAdminCosts: 'Automated invoices saving time and admin costs',
  isATrustedHectareBuyer: 'Is a trusted member of the Hectare buyer network',
  isBeddingRequired: 'Is bedding required?',
  isHectarePricePredictionForMonthRange: 'is Hectare’s prediction for {monthRange}',
  isInternalOrExternal: 'Is it an internal or external movement?',
  isNow: 'is now',
  isThisConnectionABuyer: 'Is this connection a buyer?',
  isThisTheBusiness: 'Is this the business you want to add?',
  isThisYourBusiness: 'Is this your business',
  isWeighbridgeRequired: 'Is a weighbridge required?',
  issueWithAdvancePayment: 'Issue with advance payment',
  issueWithServersTryAgainSoon: 'There could be an issue with our servers, please try again in a few minutes.',
  itCanBeCustomisedForEachCommodity: 'It can be customised for each commodity that you trade',
  itCanTakeUpTo: 'It can take up to',
  itHasExpiredCanAskBuyerReopen: 'It has expired but you can still ask the buyer to reopen the offer.',
  itLooksLikeThisUserAlreadyExists: 'It looks like this user already exists',
  itLooksLikeYouDontHaveAnyInventory:
    "It looks like you don't have any inventory setup right now, please add some to enable powerful insights around your trading position.",
  itTakesUsToCreateNewRecords:
    'It usually takes us about 10-15 minutes to create new records from your upload. Check back later.',
  itWontTakeLong:
    'It won’t take long. Our team is on their way to assist you with setting up your account. Any listing posted will be in pending status until the verification process is complete.',
  itsEitherBeenMovedOrDoesNotExist: "It's either been moved or does not exist.",
  job: 'Job',
  jobAssignedToYouSpecifyLoadSplit:
    'This job has been assigned to you, please specify how the load will be split.',
  jobCancelled: 'This job has been cancelled',
  jobCancelledCannotView: 'Cancelled job details are unavailable.',
  jobComplete: 'Job complete!',
  jobDeclined: 'Job has been declined',
  jobDetails: 'Job details',
  jobFinished: 'Job finished!',
  jobHasBeenCompleted: 'This job has been completed',
  jobID: 'Job ID',
  jobId: 'Job ID',
  jobNoLongerActive: 'Job no longer active',
  jobRefNo: 'Job ref no.',
  jobReferenceNo: 'Job reference no.',
  jobStarted: 'Job started!',
  jobSummary: 'Job summary',
  jobType: 'Job type',
  jobs: 'Jobs',
  jobsAwaitingQuotes: 'Jobs awaiting quotes',
  joined: 'Joined',
  journey: 'Journey',
  journeyTime: 'Journey time',
  justNow: 'just now',
  justTenMins: 'Just 10 mins',
  keepAdding: 'Keep adding',
  keepAnEyeOutCheckBackSoon: 'Keep any eye out and check back soon.',
  keepBid: 'Keep bid',
  keepYourDetails: 'Keep your details up to date.',
  keyAnEyeOutCheckBackSoon: 'Keep any eye out and check back soon.',
  kg: 'kg',
  kgHl: 'Kg/Hl',
  kgHlMax: 'Kg/Hl max',
  kgHlMin: 'Kg/Hl min',
  kgHlPercentage: 'Kg/Hl (%)',
  kilometers: 'km',
  landLine: 'Landline',
  languageSwitchedTo: 'Language switched to British English',
  lastName: 'Last name',
  lastTraded: 'Last traded',
  lastUpdated: 'Last updated',
  lastUpdatedDate: 'Last updated: {dateTime}',
  lastWeek: 'Last week',
  latestMarketNews: 'Latest market news',
  latestPrice: 'Latest price',
  latestUpdates: 'Latest Updates',
  latitude: 'Latitude',
  layby: 'Layby',
  lb: 'lb',
  learnMore: 'Learn more',
  learnMoreAboutHectaresAdvancePayService: 'Learn more about Hectare’s independent Advance Pay service',
  leaveAnyNotesThatWillBeUsefulForTheBuyer: 'Leave any notes that will be useful for the buyer',
  leaveAnyNotesThatWillBeUsefulForTheSeller: 'Leave any notes that will be useful for the seller',
  leftInFields: 'Left in field(s)',
  legStatus: 'Leg Status',
  legal: 'Legal',
  lessThanXMins: 'Less than {duration}',
  letBuyerKnowSpecificationDetails: 'Let the buyer know the specification details of your crop. ',
  letBuyerKnowWithinQuantity:
    'Let the buyer know how much you would like to sell. This must be within the quantity listed above.',
  letBuyersKnowMinMaxOfSellAndBuy:
    'Let the buyer(s) know the min to max of what you can sell. The buyer will let you know a min/max of what they can buy You can then choose a final quantity.',
  letPotentialBuyersKnowWhatYouWant: 'Let the potential buyers know what you would like for this listing',
  letTheSellerKnowWhatYouWouldOffer: 'Let the potential seller know what you would be willing to offer',
  letsGetYouSetupFirstOfAll: `Let’s get you setup, first of all...`,
  letsGetYouSetupToTrade: 'Let’s get you setup to trade',
  line1: 'Line 1',
  line2: 'Line 2',
  lineOne: 'Line 1',
  lineTwo: 'Line 2',
  linkAlreadyUsed: 'This link has already been used. If you still need to set your password click',
  linkedMovements: 'Linked movements',
  listAgain: 'List again',
  listCropFromCorrectLocationEverySell: 'List crop from the correct location, every time you sell',
  listMoreCrop: 'List more crop',
  listYourCrops: 'List your crops',
  listing: 'Listing',
  listingBasedTradeRequest: 'Listing based on request',
  listingClosed: 'Listing closed',
  listingDetails: 'Listing details',
  listingEnded: 'Listing ended',
  listingEndsIn: 'Listing ends in',
  listingInformation: 'Listing information',
  listingNotes: 'Listing Notes',
  listingReceived: 'Listing received',
  listingReceivedOn: 'Listing received',
  listingReference: 'Listing Reference',
  listingSpecification: 'Listing Specification',
  listingTimeframe: 'Listing timeframe',
  listingUnderReview: 'Listing under review',
  listings: 'Listings',
  listingsLiveForBusinessDayAutomaticallyClose:
    'Listings will be live for a business day before automatically closing to ensure you receive timely buyer offers.',
  listingsReceived: 'Listings Received',
  listingsRequiringADecision: 'Listings requiring a decision',
  listingsThatAreNoLongerAvailable: 'Listings that are no longer available to make a price offer on.',
  listingsThatAreOpenForOffersPriorToBeingReviewedByTheSeller:
    'Listings that are open for offers prior to being reviewed by the seller',
  listingsThatAreScheduled: 'Listings that have been scheduled and not open for offers yet.',
  listingsThatHaveEnteredTheirScheduledReviewTimeAndNowRequireAction:
    'Listings that have entered their scheduled review time and now require an action',
  listingsWhereTheReviewTimeHasElapsed: 'Listings where the review time has elapsed',
  live: 'Live',
  liveListings: 'Live listings',
  liveLocationUnavailable: 'Live location unavailable',
  livePrice: 'Live price | Live prices',
  livePriceOffer: 'Live price offer | Live price offers',
  livePriceOffers: 'Live price offers',
  livePrices: 'Live prices',
  liveTracking: 'Live tracking',
  liveTrackingDriversDuringLoad: ' Live tracking to know where your drivers are during a load',
  livestock: 'Livestock',
  load: 'Load',
  loadAllocated: 'Load allocated',
  loadAwaitingPlanning: 'Load awaiting planning',
  loadCompleted: 'Load allocated',
  loadDeletedSuccess: 'Load deleted successfully',
  loadDetails: 'Load details',
  loadHasBeenPlanned: 'Load has been planned',
  loadMore: 'Load more',
  loadNotFound: 'Load not found, please check link and try again',
  loadOverAllocated: 'Load has been over allocated',
  loadPlanner: 'Load planner',
  loadPlural: 'load | loads',
  loadReference: 'Load reference',
  loadSelected: 'Load selected',
  loadUpdated: 'Load successfully updated',
  loadWeight: 'Load weight',
  loadedMoreAssetsThanAvailable:
    'You have loaded more {transportAsset} than available please check the route section',
  loadedMoreThanAvailable: 'You have loaded more assets than available please check the route section',
  loading: 'Loading',
  loadingAssets: 'Loading',
  loadingCarriers: 'Loading carriers',
  loads: 'Loads',
  loadsCarrier: 'Loads - Carrier',
  loadsOrganiser: 'Loads - Organiser',
  loadsRecorded: 'Loads recorded',
  loadsUploaded: 'Loads uploaded',
  local: 'Local',
  localeLoaded: 'Locale loaded',
  localeRelativeTime: 'Locale relative time',
  localeTestString: 'This is a sentence in english',
  locality: 'Locality',
  location: 'Location',
  locationAddedSuccessfully: 'Location added successfully',
  'locationAddress/name': 'Location address/name',
  locationContactDetails: 'Location contact details',
  locationDetails: '{locationName} details',
  locationGroup: 'Location group',
  locationGroupCannotBeRecovered:
    'This location group cannot be recovered after deletion. Are you sure you want to delete it?',
  locationGroups: 'Location groups',
  locationGroupsAdded: 'Location groups added',
  locationGroupsAndBusinessUnitsAdded: 'Location groups and Business units added',
  locationGroupsBusinessUnitsAdded: 'Business units (orange) and Location groups (blue) added',
  locationInformation: 'Location information',
  locationServices: 'Location services',
  locationServicesDisabled: 'Location services disabled',
  locationServicesEnabled: 'Location services enabled',
  locationServicesNotSetAlways: 'Location services have not been set to "always"',
  locationType: 'Location type',
  locations: 'Locations',
  lodging: 'Lodging',
  lodgingRegrowth: 'Lodging regrowth',
  loggingYouIn: 'Logging you in...',
  login: 'Login',
  loginAsAccount: 'Login as this account',
  loginAsUser: 'Login as user',
  loginLinkSent: 'Login link sent',
  logistics: 'Logistics',
  logoURL: 'Logo URL',
  logout: 'Logout',
  logs: 'Logs',
  londonFeedWheat: 'London Feed Wheat',
  longTermApproved: 'Long term approved',
  longitude: 'Longitude',
  lookOutForAlertsWhenYourTargetPriceIsReached: 'Look out for alerts when your target price is reached.',
  lookOutForTheBuyersDecision: 'Look out for the buyers decision.',
  looksLikeYouAlreadyHaveAnAccount: 'Looks like you already have an account',
  lookupFarmDetails: 'Lookup farm details',
  loss: 'Loss',
  lost: 'Lost',
  mainLocation: 'main location',
  mainStore: 'Main store',
  maintenanceInProgress: 'Maintenance in progress',
  makeAnAdjustment: 'Make an adjustment',
  makeAnotherBid: 'Make another bid',
  makeAnotherPrice: 'Make another price',
  makeBid: 'Make a bid',
  makeBulkAdjustments: 'To make bulk adjustments, select multiple tick boxes.',
  makeOwner: 'Make owner',
  makeSureContactsValid:
    'Please make sure the contacts below are valid so the contact person can receive important information via email and Whatsapp (or SMS).',
  makingChangesToInventoryRecord: 'Making changes to your inventory record',
  male: 'Male',
  malting: 'Malting',
  manage: 'Manage',
  manageAssetsFacilitiesInOnePlatform: 'Manage assets, facilities and business connections all in one platform',
  manageBidding: 'Manage bidding',
  manageCarriers: 'manage carriers',
  manageConflict: 'Manage conflict',
  manageConflicts: 'Manage conflict',
  manageDriverVehiclesAndLocations: 'Manage Drivers, Vehicles and Locations',
  manageInventory: 'Manage inventory',
  manageInventoryStorage: 'Manage inventory and storage',
  manageMarketListings: 'Manage market listings',
  managePermissions: 'Manage permissions',
  managePermissionsForNewTeamMember:
    'Please fill out the information below and we will email the team member so they can join you.',
  managePermissionsForTeamMember:
    'Manage the permissions for this team member and adjust what they can view, create and edit.',
  managePermissionsForTeamMembers: 'Manage what your team members can access and view in your business.',
  managePermissionsLong: 'Set and manage permissions',
  manageSalesContracts: 'Manage sales contracts based on available inventory',
  manageStorageContracts: 'Manage storage contracts',
  manageTeamMembers: 'Manage team members',
  manageUser: 'Manage user',
  manageUserPermissions: 'Manage user permissions ({count})',
  manageUsersPermissions: 'Manage user permissions',
  manageYourConflicts:
    'To manage your conflict, you will need to click the three dots at the end of each row and ‘Edit movement’.',
  managed: 'Managed',
  management: 'Management',
  managementAndBusinessArea: 'Management type and business area',
  managementType: 'Management type',
  manualSelection: 'Manual Selection',
  mapOnlyAvailableInternetConnection: 'The map is only available when you have an internet connection',
  markAllAsRead: 'Mark all as read',
  markAsDone: 'Mark as done',
  markAsPaid: 'Mark as paid',
  markAsRead: 'Mark as read',
  markDeliveryAsPaid: 'Mark this delivery as paid',
  markMovementCompleteWarning:
    'You are about to mark this movement as complete. Once confirmed, the location of your inventory will be updated.',
  marketCommunications: 'Market communications',
  marketInsight: 'Market insight',
  marketInsights: 'Market insights',
  marketListing: 'Market listing',
  marketListingArchiveModalDescription:
    'As you have accepted the bid, would you like to now close this market listing? Buyers will be notified their bid has been successful.',
  marketListingCreated: 'Your listing has been sent. Sit back and wait for price offers to come in.',
  marketListingHowToGuide: 'Market listing How to guide',
  marketListingSummary: 'Crop listing summary',
  marketListings: 'Market listings',
  marketNews: 'Market news',
  marketThisRequestTo: 'Market this request to',
  marketUpdate: 'Market Update',
  marketUpdates: 'Market updates',
  marketYourListing: 'Market your listing',
  marketingInsights: 'Marketing insights',
  marketingQuestion: {
    event: 'Event',
    formerGraindexCustomer: 'Former Graindex customer',
    newspaperMagazine: 'Newspaper/Magazine',
    onlineAdvert: 'Online advert',
    other: 'Other',
    post: 'Post',
    printAdvert: 'Print advert',
    searchEngine: 'Search engine',
    socialMedia: 'Social media',
    website: 'Website',
    wordOfMouth: 'Word of mouth',
  },
  mart: 'Mart',
  max: 'Max',
  maxDecks: 'Max decks',
  maxFileSize: 'Max indvidual file size is {maxSize}',
  maxNumberFiles: 'You have uploaded the maximum number of files allowed',
  maxPrice: 'Max price',
  maxSelections: 'You can select as many as needed | Only one selection allowed | Only {count} selections allowed',
  maximumWidthHeightMetres: 'Maximum width/height is {number} {unit}',
  measure: 'Measure',
  membershipNo: 'Membership no',
  membershipResults: '{membershipName} results',
  menu: 'Menu',
  merchants: 'Merchants',
  messages: 'Messages',
  messagesViaWhatsapp: 'Messages - via Whatsapp',
  meters: 'meters',
  metre: 'metre | metres',
  metresAbv: 'm',
  midnightOnThe: 'Midnight on the',
  miles: 'miles',
  milesTotal: '{count} miles',
  mill: 'Mill',
  min: 'Min',
  minAgo: 'min ago | mins ago',
  minFilesRequired: 'Upload files (optional) | Upload at least 1 file | Upload at least {count} files',
  minPrice: 'Min price',
  mins: 'mins',
  minute: 'minute',
  minutes: 'minutes',
  minutesPlural: 'no minutes | one minute | {count} minutes',
  minutesShorthand: 'm',
  missingActions: 'Missing actions',
  missingRequiredActions:
    'There are missing required actions. Please complete them where possible before continuing.',
  mobileNumber: 'Mobile number',
  modalSpecificationEditHint:
    'Please update the specification with your sample details and upload the sample document',
  moisture: 'Moisture',
  moistureMax: 'moisure Max',
  moistureMin: 'Moisture min',
  moisturePercentage: 'Moisture (%)',
  monthRange: 'Month range',
  months: 'month(s)',
  monthsStartingFrom: '0 months, starting from | {count} month, starting from | {count} months, starting from',
  more: 'More',
  moreAboutYou: 'More about you',
  moreInfo: 'More info',
  moreStoreLocation: 'More storage location',
  moreSubStorage: 'Add another sub-store',
  mostRecent: 'Most recent',
  mostUsed: 'Most used',
  move: 'Move',
  moveCount: 'Move ({count})',
  moveDown: 'Move down',
  moveIn: 'Move In',
  moveInventory: 'Move inventory',
  moveOut: 'Move Out',
  moveType: 'Move Type',
  moveUp: 'Move up',
  movedExternally: 'Moved externally',
  movedOn: 'Moved on',
  movement: 'Movement',
  movementAndQuantity: 'Movement and quantity',
  movementBidAmount: 'Movement and bid amount',
  movementConflictCropExplanation:
    'Crop clash: crops in this movement cannot be mixed. Click the 3 action dots and choose edit movement to resolve.',
  movementConflictShortfallExplanation:
    'Inventory shortfall: not enough inventory to satisfy the planned movement. Click the 3 action dots and choose edit movement to resolve.',
  movementConflictSurplusExplanation:
    'Store is overcapacity and inventory has to be moved out to make space.  Click the 3 action dots and choose edit movement to resolve.',
  movementCreated: 'Movement created',
  movementDate: 'Movement date',
  movementDateConfirmed: 'Movement date confirmed',
  movementDatesAndDeliveryDetails: 'Movement dates & delivery details',
  movementDetails: 'Movement Details',
  movementDuplicatedSuccessfully: 'Movement duplicated successfully',
  movementDuplicatedinPastAddedToRecordsIncomplete:
    "Movement duplicated was a past movement so may appear in 'Records incomplete'.",
  movementIntakeUpdates: 'Managing intakes, internal and external movements',
  movementLocation: 'Movement location',
  movementMonthAscending: 'Movement month ascending',
  movementMonthDescending: 'Movement month descending',
  movementMonthOptionCount: 'Movement month option {count}',
  movementMonths: 'Movement month(s)',
  movementPeriod: 'Movement period',
  'movementPeriod.endDateUTC': 'End date',
  'movementPeriod.notes': 'Notes',
  'movementPeriod.premiumPrice': 'Premium price',
  'movementPeriod.pricePerUnit': 'Price per unit',
  'movementPeriod.startDateUTC': 'Start date',
  'movementPeriod.totalWeightKG': 'Total weight',
  'movementPeriod.type': 'Type',
  movementRef: 'Movement ref',
  movementReference: 'Movement reference',
  movementReport: 'Movement Report',
  movementSummary: 'Movement summary',
  movementTerm: 'Movement term',
  movementUpdated: 'Movement updated',
  movementX: 'movement | movements',
  movements: 'Movements',
  moving: 'Moving',
  movingFrom: 'Moving from',
  movingTo: 'Moving to',
  movingTooMuchHere: 'Moving too much here. Please check capacity and totals',
  mustBeAValidContactNumber: 'Must be a valid contact number',
  mustBeAValidEmailOrMobileNumber: 'Must be a valid email address or mobile number',
  mustBeAValidPhoneNumber: 'Must be a valid email or phone number starting with +44',
  mustBeTonnes: 'Must be a value in tonnes',
  mustBeUnique: '{fieldName} must be unique',
  myBids: 'My bids',
  myCompany: 'My company',
  myContact: 'My contact',
  myCrops: 'My crops',
  myListings: 'My listings',
  myNetwork: 'My network',
  myPosition: 'My position',
  myTargetPrice: 'My target price',
  myTrading: 'My trading',
  name: 'Name',
  nameOfAuthorisedIndividual: 'Name of authorised individual',
  nameOfBank: 'Name of Bank',
  nameOfPerson: 'Name of person',
  nameYourBusinessUnit: 'Name your business unit',
  nameYourField: 'Name your field',
  nameYourLocationGroup: 'Name your location group',
  nameYourStore: 'Name your store',
  narrowInventoryForTrade: 'Use the filters to narrow down the trade summary below',
  nationwide: 'Nationwide',
  needHelp: 'Need help',
  needHelpCalculating: 'Need help calculating?',
  net: 'Net',
  netWeight: 'Net weight',
  netWeightInTonnes: 'Net weight in tonnes',
  neverMissWhenTheMarketsAtAHigh: "Never miss when the market's at a high with timely price alerts.",
  new: 'New',
  newAsset: 'New asset',
  newAssetCategory: 'New asset category',
  newAssetType: 'New {assetType} load',
  newBusinessConnection: 'New business connection',
  newBusinessUnit: 'New business unit',
  newCarrier: 'New carrier',
  newCategory: 'New category',
  newConnection: 'New connection',
  newContract: 'New contract',
  newCrop: 'New Crop',
  newCustomer: 'New customer',
  newDriver: 'New driver',
  newFarm: 'New farm',
  newField: 'New field',
  newGroup: 'New group',
  newHandler: 'New handler',
  newIntake: 'New intake',
  newIntakeAction: 'New intake action',
  newInventory: 'New inventory',
  newJob: 'New job',
  newJobHasBeenSentToCarriers: 'This job has now been sent to your chosen carriers for quoting.',
  newListing: 'New listing',
  newLoad: 'New load',
  newLoads: 'New loads',
  newLocation: 'New location',
  newLocationGroup: 'New location group',
  newMarketListing: 'New market listing',
  newMovement: 'New movement',
  newMovementSuccessButDuplicationFail:
    'A single movement was created successfully but duplication has failed. Please see the errors for more information.',
  newOfflineJob: 'New offline job',
  newOrganisation: 'New organisation',
  newOrganiser: 'New organiser',
  newPOD: 'New POD',
  newPODUploaded: 'New POD uploaded',
  newPasswordSetPleaseLogin: 'You’ve successfully changed your password',
  newPlanting: 'New planting',
  newPlantings: 'New plantings',
  newPreferredDestination: 'New preferred destination',
  newPremiumContract: 'New premium contract',
  newProfile: 'New profile',
  newProfileType: 'New {profileType}',
  newQuickQuoteAvailable: 'New Quick Quote available',
  newQuote: 'New quote',
  newQuotes: 'New quotes',
  newRequest: 'New request',
  newSalesContract: 'New sales contract',
  newStorage: 'New storage',
  newStorageContract: 'New storage contract',
  newStorageInfoSaved: 'New storage info saved',
  newStore: 'New store',
  newTeamMember: 'New team member',
  newTrailer: 'New Trailer',
  newUpdate: 'New update',
  newUserCreated: 'new user successfully created',
  newUserQuestion: 'New user?',
  newVehicle: 'New vehicle',
  newWantedListing: 'New wanted listing',
  next: 'Next',
  nextExp: 'Next Exp',
  nextNoDays: 'In the next {days} days',
  nextStepToProvideDetailsForAdvancePayment:
    'The next step is to provide us with some details for your advance payment so we can get you paid as soon as possible. ',
  nextSteps: 'Next steps',
  nextStepsToGetYourAdvancePayment: 'Next steps to get your advance payment',
  nextStop: 'Next stop',
  niceWork: 'Nice work, {driver}',
  nilErgotPercentage: 'Nil Ergot (%)',
  no: 'No',
  noAccess: 'No access',
  noAccountRequestAccess: 'No account? Request access',
  noAccountSignUpForFree: 'No account? Sign up for free',
  noActions: 'No Actions to view',
  noActiveJob: 'No active job',
  noAddedCarriers: 'You have’t added any carriers yet',
  noApplicationProcessNeeded: 'No application process needed.',
  noBidsAcceptedJustNow: 'No bids accepted just now',
  noBidsToViewJustNow: 'No bids to view just now',
  noBusinessUnits: 'You currently have no business units set up.',
  noBusinessUnitsCreated: 'No business units created',
  noCarriersCreatedYet: 'No carriers created yet',
  noClientUrlForReport: 'No client URL for this report yet, check status',
  noCompletedStorageContracts: 'No completed storage contracts yet',
  noContacts: 'No contacts',
  noContactsAvailableYet: 'No contacts available yet',
  noCreatedCarriers: "You haven't created any carriers yet",
  noCropOptionsForThisType:
    'There are currently no crops in the system of this type, please contact us for assistance.',
  noCustomerChosen: 'No customer chosen',
  noDataAvailable: 'No data available',
  noDataForThoseFilters: 'No data for those filters',
  noDataYet: 'No data yet',
  noDateChosen: 'No date chosen',
  noDeliveriesForSelectedSalesContracts:
    'There are no deliveries to export recorded on the selected sales contracts',
  noDocumentsUploaded: 'No documents uploaded yet',
  noExistingSalesContractsFound: 'No existing sales contracts found',
  noExportsAvailable: 'No exports available',
  noFixedContracts: 'No fixed contracts',
  noFurtherActionsRequiredJob: 'There are no further actions required for this job.',
  noFutureMovements: 'No future movements',
  noGoBack: 'No, go back',
  noInternetConnection: 'No internet connection',
  noInventory: 'No inventory currently added',
  noInventoryAvailable: 'No inventory available',
  noInventoryFound: 'No inventory found',
  noInventoryInField: 'No inventory in field matching your filters',
  noInvoicesCreatedYet: 'No invoices created yet',
  noJobsAssignedYet: 'No jobs assigned yet',
  noJobsAvailableToday: 'There are no jobs today',
  noJobsAwaitingQuotes: 'No jobs awaiting quotes yet',
  noJobsCreatedYet: 'No jobs created yet',
  noJobsInNextDays: 'You have no jobs in the {days} days',
  noJobsWonYet: 'No jobs won yet',
  noKeepAlive: 'No, keep alive',
  noKeepPrice: 'No, keep price',
  noListingsToBidOn: 'No listings to bid on just now',
  noListingsToView: 'No listings to view',
  noLoadDeatailsEntered: 'No load details entered',
  noLoadsRecorded: 'No loads recorded',
  noLoadsScheduledToday: 'No loads scheduled for today',
  noLocationsAddedToBusinessUnit: 'No locations have been added to this business unit',
  noLocationsBusinessUnit:
    'Setting up business units allows you to group locations by area/business unit/farm, helping you create a consolidated view on your inventory pages',
  noLocationsCreatedYet: 'No locations created yet',
  noLocationsField: 'Add fields to quickly and accurately track your inventory at harvest.',
  noLocationsLocationGroup:
    'Setting up location groups allows you to group locations by area, helping you create a consolidated view on your inventory pages',
  noLocationsMatchingSearch: 'No locations matching search',
  noLocationsStore: 'Add your on-farm storage locations and create your bins/bays.',
  noLostPaperwork: 'All paperwork in one place digitally, no more lost paperwork or spreadsheets',
  noMarketListings: 'No market listings',
  noMatchesFound: 'No matches found',
  noMatchingCarriers: 'No matching carriers',
  noMembershipResults: 'No {membershipName} match found for this location (optional)',
  noNotifications: "You currently don't have any notifications",
  noNotificationsYet: 'No notifications yet',
  noOfDays: 'No. of days',
  noOfLoadsAdded: 'No. of loads added',
  noOfPODsUploaded: 'No of PODs uploaded',
  noOffersReceived: 'No offers received',
  noOptionsInSearch: 'No options for your current search',
  noPhotoAdded: 'No photo added',
  noPriceOffersReceived: 'No price offers received',
  noPricesAccepted: 'No prices have been accepted',
  noPricesHaveBeenSent: 'No prices have been sent',
  noRecentPriceData: 'No recent price data',
  noRecordsFoundForFilters: 'No records found for the selected filters',
  noRecordsSelectedForAdjustment: 'No records selected for adjustment',
  noRecordsSelectedForMovement: 'No records selected for movement',
  noRecordsSelectedGoBack: 'No records selected, please go back and try again',
  noRegionsMatchingSearch: 'No regions matching search',
  noReport: 'No report',
  noRequestReceived: 'No request received',
  noResults: 'No results',
  noResultsFound: 'No results found',
  noResultsOrNotBusinessDesired: 'No results or not the business you are looking for?',
  noResultsOrNotBuyerDesired: 'No results or not the buyer you are looking for?',
  noSalesContractsHaveBeenCreatedYet: 'No sales contracts have been created yet',
  noServices: 'No Services enabled, please contact help@wearehectare.com.',
  noStorageContractsHaveBeenCreatedYet: 'No storage contracts have been created yet',
  noStorageLocationsToViewInventory: 'No storage locations to view your inventory',
  noTargetPriceSet: 'No target price set',
  noThanks: 'No, thanks',
  noTimelineRecords: 'No timeline records to view',
  noTimelineRecordsView: 'You currently have no timeline records to view',
  noTradeAvailabilityAtThisTime: 'No inventory available to view',
  noTradeRecords: 'No trade records to view',
  noTradeRequests: 'No requests',
  noTradesAccepted: 'No trades have been accepted',
  noTradesAgainstRecord: 'You currently have no trades against this inventory record.',
  noTradesFoundForThisCriteria: 'No trades found for this criteria',
  noTrailersAvailable: 'No trailers available',
  noUnreadNotifications: 'No unread notifications',
  noUpcomingJobs: 'No upcoming jobs yet',
  noUploadTermsAndConditions: 'You currently have no Terms and Conditions uploaded.',
  noUseDifferentEmail: 'No, use a different email',
  noUsersAdded: 'No users added',
  noXLoadsToView: 'No {state} to view',
  nonAssured: 'Non-assured',
  none: 'None',
  noneOfTheAbove: 'None of the above',
  noneProvided: 'None provided',
  notAuthorised: 'You are not authorised to access this page',
  notAuthorisedFeature:
    'You do not have permissions to access this feature. Please contact your account owner for assistance.',
  notAvailable: 'Not available',
  notEnoughInventoryToDuplicateMovementXTimes:
    'There is only enough inventory to duplicate this movement {count} times. Update the number of movements to continue.',
  notHeardBackFromSeller: 'Not heard back from the seller?',
  notInterested: 'Not interested',
  notProvided: 'not provided',
  notRequired: 'Not required',
  notSureIfTheseAreGoodPrices: 'Not sure if these are good prices?',
  notSureWhatToListFarmerList4LoadsOnHectare:
    'Not sure what to list? Farmers most commonly list at least 4 loads on Hectare to attract the best offers',
  notUploaded: 'Not uploaded',
  notUsingInstantInvoice: 'Not using instant invoice yet?',
  noteAdded: 'Note added',
  noteForBuyer: 'Note for buyer',
  noteForSeller: 'Note for seller',
  noteForSellerDesciption: 'Leave any helpful notes that you feel could be useful to the seller’s decision.',
  noteFromSeller: 'Note from seller',
  noteNewTermsAndConditions:
    'Note: Any new terms and conditions uploaded will only apply to new jobs and not current jobs.',
  noteToImpersonator: 'Note to impersonator',
  notes: 'Notes',
  notesAndRequirements: 'Notes and requirements',
  notesForBuyer: 'Notes for buyer(s)',
  notesForSeller: 'Notes for seller',
  notesForTheSeller: 'Notes for the Seller',
  notesFromBuyer: 'Notes from Buyer',
  notesFromSeller: 'Notes from Seller',
  notesVisibleToCarrierDriver: 'Notes are visible to the carrier and the driver',
  nothingElseTheTradeWillBeCompletedAsNormal: 'Nothing else, the trade will be completed as it normally is',
  nothingFoundSearchTerm: 'Nothing found for that search term',
  notifications: 'Notifications',
  notified: '{name} notified',
  notify: 'Notify',
  notifyAsSoonAsBuyerSendsPrice: 'We’ll notify you as soon as a buyer sends a price for your crop',
  notifyMoreBuyers: 'Notify more buyers',
  now: 'Now',
  nowCanYouTellUs: 'Now, can you tell us...',
  nowYourPriceAcceptedAddContractInfo:
    'Now that your price has been accepted you can now add your contract information',
  nudgeCarrier: 'Nudge carrier | Nudge carriers',
  numCarriersChosen: 'No carriers chosen | 1 carrier chosen | {count} carriers chosen',
  numItems: '{total} items',
  numItemsSelected: '{selectedCount} of {total} items selected',
  numberDecks: 'Number of decks',
  of: 'of',
  ofNumOfSelectedCarriers: 'of {selectedCarriers}',
  offer: 'offer',
  offerAPrice: 'Offer a price',
  offerBasedOnAcceptedTrade: 'Offer based on your accepted trade',
  offerBreakdown: 'Offer breakdown',
  offerNewPrice: 'Offer new price',
  offerPrice: 'Offer price',
  offerUnderReviewBySellerYourOfferExpiresAtX: 'Offer under review by seller. Your offer expires at {time}',
  offers: 'Offers',
  offlineMode: 'Offline mode',
  offlineSales: 'Offline sales',
  offlineSalesNoficationMessage:
    "Agreed a trade on the phone? You can record any tonnage that you've sold offline below and this will update your sold position",
  offlineSalesUpdated: 'Offline sales updated',
  offtaker: 'Offtaker',
  ok: 'Ok',
  okGreat: 'Ok, great',
  okayGotIt: 'Okay, got it',
  okayGreat: 'Okay, great',
  on: 'on',
  onBuyerRepaymentTerms: 'On buyer repayment terms',
  onDate: 'on {date}',
  onDelivery: 'On delivery',
  onMovementConfirmation: 'On movement confirmation',
  oncePostedYouWillReceiveMostListingsInFirstHour:
    'Once posted, you will receive most offers during the first hour of listing.',
  onceSentTheSellerWillOnlySeeThePriceOffer:
    'Once sent, the seller will only see the price offer when they review their offers at the stated time. Your offer will be valid from that time for 30 minutes.',
  onceYouAcceptTradeEnterAgreementWithBuyer:
    'Once you accept a trade, you’re entering an agreement with the buyer.',
  onceYouAcceptTradeEnteringIntoAgreement: 'Once you accept a trade, you’re entering an agreement with the buyer.',
  onceYouConfirmATrade: 'Once you confirm a trade, you’re entering an agreement with the buyer.',
  onceYouveConfirmedDeliveriesWeUpdateStatus:
    'Once you have confirmed that you have recorded all deliveries on a contract, we will support you by updating the status of the contract automatically. Contracts will update to "delivered" when tipped weight is recorded and "complete" when deliveries are paid.',
  onceYouveRequestedAccessWillRedirectYou:
    'Once you’ve requested access, we will re-direct you to the listing so you can send a price to the seller.',
  oneDigit: '1 digit',
  oneLowercaseCharacter: '1 lowercase character',
  oneSpecialCharacter: '1 special character',
  oneUppercaseCharacter: '1 uppercase character',
  onlyAlerts: 'Only alerts',
  onlyAnIndicationWillingToOffer: 'only an indication what they may be willing to offer at that point in time',
  onlyAvailableDriverSelected: 'Only available driver is selected.',
  onlyEnoughInventoryToDuplicateMovementXTimes:
    'There is only enough inventory to duplicate this movement {count} times. Update the number of movements to continue.',
  onlyIntakesWithStorageContractsWillBeExported: 'Only intakes with storages contracts will be exported',
  onlyMovementsWithStorageContractsAttachedWillBeExported:
    'Only movements with storage contracts attached can be exported.',
  open: 'Open',
  openFor: 'Open for',
  openForPriceOffers: 'Open for price offers',
  openForPriceOffersAt: 'Open for price offers at',
  openForPriceOffersTomorrowAt: 'Open for price offers tomorrow at',
  openListings: 'Open listings',
  openMarketAlerted: 'Open market alerted',
  openMarketNotAlerted: 'Open market not alerted',
  openThisListingToOurTrustedMarket: 'Request prices from our network of buyers to sell more tonnage.',
  opensIn: 'Opens in',
  operatorError: 'Operator error',
  option: 'Option',
  optionDifferentMovementMonth: 'Option for different movement month',
  optional: 'Optional',
  options: 'options',
  or: 'or',
  orEnterPricePerWeight: 'OR enter price per weight',
  orTryLoggingInAgain: 'or try logging in again',
  organisation: 'Organisation',
  organisationFavicon: 'Organisation favicon',
  organisationLogo: 'Organisation logo',
  organisationToUpdate: 'Organisation to update',
  organisations: 'Organisations',
  organiseYourLoadsAssignDrivers: 'Organise your loads and assign drivers',
  organiser: 'Organiser',
  organiserDeclined: 'Unsuccessful',
  organiserPlural: 'Organiser | Organisers',
  organiserReviewQuotesNotifySuccessful:
    'The organiser will now review all quotes and you will be notified if yours has been selected.',
  organiserSelectedSingleDate: 'The organiser has selected a single date for this job.',
  organiserSelectedSingleDateForJob: 'The organiser has selected a single date for this job.',
  organisers: 'Organisers',
  origin: 'Origin',
  originLocation: 'Origin location',
  originalContract: 'Original contract',
  originalListingPostedOn: 'Original listing posted on',
  originalLocation: 'Original location',
  originalMovementPeriod: 'Original movement period',
  originalOutput: 'Original output',
  originalProjectedQuantity: 'Original projected quantity',
  originalProjectedYield: 'Original projected yield',
  originalYield: 'Original yield',
  otherBuyersAreOffering: 'Other buyers are offering',
  otherTrailerType: 'Other trailer type',
  ourDevelopmentTeamAlertedOfError: 'Our development team have been alerted of this error.',
  ourMissionIsToMakeCropTradingEasier:
    'Our mission is to make crop trading easier than ever for farmers and help you to get the best price for your grain. You can get started now with these 4 easy steps.',
  ourPricePredictionsAreBasedOnBidData:
    'Our predictions are based on our market insights and real-time bid data for what you are selling.',
  ourSupportTeam: 'the Farmto support team',
  ourTeamWillBeInTouch: 'Our team will be in touch to help after you’ve finished signing up.',
  outForQuote: 'Out for quote',
  outOf: 'out of ',
  outstanding: 'Outstanding',
  overdue: 'Overdue',
  overnight: 'Overnight',
  overview: 'Overview',
  owner: 'Owner',
  ownersAndLocations: 'Owners and locations',
  page: 'page',
  pageNotFound: 'Page not found',
  pageOf: 'Page {currentPage} of {totalPages}',
  pageWasLeftInactiveTradeAccepted:
    'The page was left inactive for too long. The original trade has still been accepted with the buyer and you will settle in the usual way.',
  paid: 'Paid',
  paidOnMovementConfirmation: 'Paid on movement confirmation',
  paidOut: 'Paid out',
  partialLoad: 'Booked',
  password: 'Password',
  passwordDoesNotMatch: "Your password doesn't match the email address",
  passwordMatchCognitoValidation:
    'Passwords must be at least 8 characters long and contain at least one special character, one uppercase character, one lowercase character and one digit',
  passwordMustContainAllRules: 'Password must contain all of the above',
  passwordResetAttemptLimitExceeded: 'Attempt limit exceeded, please try again after some time',
  passwordResetIncorrectPassword: "You've entered an incorrect password",
  passwordSetSuccessfully: 'Password set successfully',
  passwordUpdated: 'Password updated',
  passwordValidations: {
    lowercase: 'One lowercase character',
    minimumCharacters: '8 character minimum',
    number: 'One number',
    specialCharacter: 'One special character',
    uppercase: 'One uppercase character',
  },
  pastMovementsAreMissingFollowingRecords:
    'Past movements are missing one or more of the following records: Weight, Driver, Vehicle, Sample',
  payee: 'Payee',
  payment: 'Payment',
  paymentDate: 'Payment date',
  paymentDays: 'Payment days',
  paymentDetails: 'Payment details',
  paymentDueWithinDaysInvoiceIssueDate: 'Payment due within {days} days of invoice issue date',
  paymentExpected: 'Payment expected',
  paymentMade: 'Payment made',
  paymentPending: 'Payment pending - to get paid you must email the buyers’ contract',
  paymentReceivedOn: 'Payment received on',
  paymentTerms: 'Payment terms',
  paymentTermsFromDeliveryDate: 'Payment terms from delivery date',
  paymentsSettled: 'Payments settled',
  pedigree: 'Pedigree',
  pending: 'Pending',
  pendingLoads: 'Pending loads',
  pendingStart: 'Pending start',
  per: 'Per',
  perHead: 'per head',
  perHeadShorthand: '/ head',
  perKg: 'per kg',
  perTonne: 'per tonne',
  perUnit: 'per {unit}',
  percentSold: 'Percentage sold',
  percentageAllocated: '{percentage}% allocated',
  percentageCutOverTime: 'Percentage cut over time',
  percentageSold: 'Percentage sold',
  percentileOfMonthYearBidsOverTheLastFiftyTwoWeeks: '{percentile} of {monthYear} bids over the last 52 weeks.',
  percentileOfSeasonalMonthBidPricesAtThisPointInTheYear:
    '{percentile} of seasonal {month} bid prices at this point in the year.',
  performingMaintenance: 'We are performing maintenance on our platform at this time.',
  permissions: 'Permissions',
  permissionsAdded: 'Permissions added',
  permissionsAddedDescription:
    "You've given permission to {userName}. They have been added as an external contact and can also be found in your Team members list.",
  permissionsDenied: 'Permissions denied',
  permissionsMarketListingBidding: 'Bidding',
  permissionsMarketListingBiddingDescription: 'Ability to place bids on market listings.',
  permissionsTranslations: {
    biddingDescription: 'Ability to place bids on market listings',
    biddingTitle: 'Bidding',
    cusBusinessBuyerDescription: 'Update profile information',
    cusBusinessSellerDescription: 'Update profile information and add new farm locations',
    cusBusinessTitle: 'Business management',
    cusTeamsDescription: 'Invite new users, view and adjust permissions',
    cusTeamsTitle: 'Team',
    invAdjustmentsDescription: 'Adjust expected output or in-store loss',
    invAdjustmentsTitle: 'Adjustments',
    invContractsDescription: 'Manage storage contracts',
    invContractsTitle: 'Storage contracts',
    invMovementsDescription: 'Managing intakes, internal and external movements',
    invMovementsTitle: 'Movements',
    invRecordsDescription: 'Anything to do with an individual inventory record',
    invRecordsTitle: 'Inventory records',
    invStorageDescription: 'Store set-up, capacity, locations and management',
    invStorageTitle: 'Storage',
    'trading-inventoryDescription': 'Manage and add inventory',
    tradingInsightsTitle: 'Insights',
    tradingInventoryTitle: 'Inventory',
    trdBuyersDescription: "Permission to buy crops, manage alert preferences and create Wanted's",
    trdBuyersTitle: 'Buyer',
    trdContractsDescription: 'Manage sales contracts based on available inventory',
    trdContractsTitle: 'Contracts',
    trdInventoryDescription: 'Permission to add crops and view position',
    trdInventoryTitle: 'My Crops',
    trdListingsDescription: 'Manage market listings',
    trdListingsTitle: 'Market listings',
    trdSellersDescription: 'Permission to list and sell crops',
    trdSellersTitle: 'Seller',
  },
  personIsResponsibleForAccountResourcesPermissions:
    'This person will be responsible for this business account, and is allowed to access all resources and to edit permissions of all team accounts under the same business.',
  personResponsibleAdminProfile: 'This person will be responsible for this business account',
  phone: 'phone',
  phoneNumber: 'Phone number',
  photo: 'Photo',
  photoAdded: 'Photo added',
  pickUpBy: 'Pick up by',
  pickUpDropOff: 'Pick up/Drop off',
  pickUpLocation: 'Pick up location',
  pickedUpBy: 'Picked up by',
  pickingUpFrom: 'Picking up from {locationName}',
  pickupAndDestination: 'Pickup & destination',
  pickupDate: 'Pickup date',
  pickupLocation: 'Pickup location',
  placeAdditionalBid: 'Place additional bid',
  placeAnotherBid: 'Place another bid',
  planManageMovements: 'Plan and manage movements',
  planWhatisBeingLoaded: 'Plan what is being picked up and dropped off where',
  planned: 'Planned',
  plannedMovementAction: 'Planned movement action',
  plannedMovementDate: 'Planned movement date',
  plannedMovements: 'Planned movements',
  plannedPlantingDate: 'Planned planting date',
  plannedQuantity: 'Planned quantity',
  plantedInventory: 'Planted inventory',
  planting: 'Planting',
  plantingDate: 'Planting date',
  plantingDateOrMonth: 'Planting date/month',
  pleaseAddDriver: 'Please add a driver',
  pleaseAddLoadsFirst: 'Please add some loads first',
  pleaseAddYourContractInfo: 'Please add your contract info',
  pleaseCheckYourEmailMobileAndJunkFolder:
    'Please check your email/phone and remember to look in your junk folder',
  pleaseChooseCorrectStorageLocation: 'Please choose the correct storage location',
  pleaseClickCancelToCloseThisSection: 'Please click cancel to close this section.',
  pleaseClickUpdateDetailsToProvideMissingInformation:
    'Please click update farm and provide any missing information that is needed.',
  pleaseCompleteFieldIf: '{field1} is required if you have entered {field2}',
  pleaseCompleteFormCustomerSuccessWillContact:
    'Please complete this short form and our customer success team will be in contact soon to get you setup with a Hectare account.',
  pleaseConfirmIfYouWishToProceed: 'Please confirm if you wish to proceed.',
  pleaseConfirmYouWouldLikeToDeleteUserX: 'Please confirm you would like to remove {user}',
  pleaseConfirmYouWouldLikeUsToResendRegistrationLink:
    'Please confirm you would like us to re-send a registration link to your email so you can reset your password? The previous link sent will no longer be valid.',
  pleaseContactBusinessAccountOwner: 'Please contact your business account owner ',
  pleaseContactOwner: 'Please contact owner',
  pleaseCorrectDetailsToConnectUserTryAgain: 'Please correct details to connect the user and try again',
  pleaseCreateValidRoute: 'Please create a valid route',
  pleaseDoNotMixLocationTypes: 'Select records only in stores or only in fields to perform this action',
  pleaseEnableToContinue: 'Please enable to continue.',
  pleaseEnsureFullLoad: 'Please ensure you have loaded all assets',
  pleaseEnsureFullLoadAssets: 'Please ensure you have loaded all {transportAsset}',
  pleaseEnsureFullUnload: 'Please ensure you have unloaded all assets',
  pleaseEnsureFullUnloadAssets: 'Please ensure you have unloaded all {transportAsset}',
  pleaseEnsureYouHaveTheCorrectSettlement:
    'Please ensure you have the correct settlement payment details in your system, the rest of the trade will be completed as normal',
  pleaseEnter3Words: 'Please enter 3 words',
  pleaseEnterAValue: 'Please enter a value for {fieldName}',
  pleaseEnterAtLeast: 'Please enter at least {value} {fieldName}',
  pleaseEnterLoadDetailsFirst: 'Please enter load details first',
  pleaseFillInEitherField: 'Please complete either {field1} or {field2}',
  pleaseFinishPlanningExistingVehicles: 'Please finish planning existing vehicles first',
  pleaseMakeSureContactDetailsAreValid:
    'Please make sure the contact number below is valid so the driver can receive the request via WhatsApp (or SMS).',
  pleaseNoteThatTicketImagesAreNotIncludedInThisCSV:
    'Please note that ticket images are not included in this CSV.',
  pleaseNoteThatTicketImagesNotIncluded: 'Please noted that the ticket images are not included in this CSV.',
  pleasePayXTheAgreedSettlementAmount:
    'Please pay MIR Lux Capital the agreed settlement value on existing repayment terms.',
  pleaseProvideAMovementDate: 'Please provide a movement date',
  pleaseProvideValidBusinessAddress:
    'Please provide your business address below. Your farm will appear to buyers as unassured.',
  pleaseProvideYourCollectionReceipts: 'Please provide your collection receipts',
  pleaseReduceNumberOfCopies: 'Please reduce number of copies',
  pleaseRefreshAndTryAgain: 'Please refresh the page and try again.',
  pleaseReloadTryAgain: 'Please reload and try again',
  pleaseReviewHectareCommunications:
    'Please review Hectare communications and opt-out of anything you would rather not receive. ',
  pleaseReviewHectaresCommunicationsAndOptOut:
    'Please review Hectare communications and opt-out of anything you would rather not receive',
  pleaseSelect: 'Please select',
  pleaseSelectABusinessUnit: 'Please select a business unit',
  pleaseSelectADifferentField: 'Please select a different field',
  pleaseSelectARegion: 'Please select a region',
  pleaseSelectAnAssuranceFromOptionsOrClearField:
    'Please select an assurance from the options provided or try another search',
  pleaseSelectAnotherCommodity: 'Please select another commodity',
  pleaseSelectAnotherCommodityRegion: 'Please select another commodity or region',
  pleaseSelectCarriersForQuotes: 'Please select carriers for quotes',
  pleaseSelectDate: 'Please select a date',
  pleaseSelectMonth: 'Please select a month',
  pleaseSelectOne: 'Please select at least one of the {fieldName}',
  pleaseSetupWaypoints: 'Please ensure all stop locations are set',
  pleaseSignIn: 'Please sign in to your account',
  pleaseSignInToNewAccount: 'Please sign in to your new business account',
  pleaseSpecify: 'Please specify',
  pleaseUpdateSpecWithSampleDetailsAndUploadDoc:
    'Please update the specification with your sample details and upload the sample document.',
  pleaseUpdateThePricingBasisAndConfirmTheGrade:
    'Please update the pricing basis and confirm the grade and price of this contract to add your deliveries.',
  pleaseUpdateTradesThatRequireCollectionReceipts:
    'Please update any trades that require collection receipts to enable final payments',
  pleaseUploadFile: 'Please upload a file.',
  pleaseUploadSamplePhoto: 'Please upload sample photo',
  pleaseUploadWeightPhoto: 'Please upload the weight photo.',
  pleaseUseFarmToApp: 'Please use the FarmTo app as a driver. This app is for organisers and carriers',
  pleaseUseGraindexDetailsToSignIn: 'Please use Graindex details to sign in',
  pleaseWaitLoadsPreparing: 'Please wait a moment while loads are prepared...',
  pleaseWaitWeAreProcessingYourPriceOffers: 'Please wait, we’re processing your price offers...',
  pluralQuantity: 'x 1 | x {count}',
  pluralQuantityBushel: '1 bushel | {count} bushels',
  pluralRemainQuantity: '1 quantity remain | {count} quantity remain',
  pluralRemainQuantitybushel: '1 bushel remain | {count} bushels remain',
  pluralRemainQuantitykg: '1 kg remain | {count} kg remain',
  pluralRemainQuantitylb: '1 lb remain | {count} lb remain',
  plusNMore: '+{n} more',
  podLoadUploadCsvTemplate: 'loads upload csv template',
  podRecords: 'POD records',
  podUploaded: 'POD Uploaded',
  policyDocuments: 'Policy documents',
  port: 'Port',
  position: 'Position',
  positionCalculator: {
    calculate: 'Position calculator',
    calculateMyPosition: 'Calculate my position',
    currentlySold: 'Currently sold',
    desiredPosition: 'Desired position',
    locationGroup: 'Location group',
    pricesUpdated: 'Prices updated at {time} | {relativeDate}',
    region: 'Region',
    totalTonnage: 'Total tonnage to be sold',
  },
  postAListing: 'Post a listing',
  postAListingWithIcon: '📝 Post a listing',
  postAWanted: 'Post a wanted',
  postAnotherListing: 'Post another listing',
  postCode: 'Postcode',
  postListingToBuyer: 'Post listing to buyer',
  postWantedListing: 'Post wanted listing',
  postedOn: 'Posted on',
  postedToOpenMarket: 'Posted to open market',
  postingToAllBuyersMaximiseBidsListing:
    'Posting to all buyers will maximise your opportunity for bids on your listing.',
  postingToAllMaximiseOpportunity:
    'Posting to the all buyers will maximise your opportunity for bids on your listing.',
  postingToOpenNetworkWillMaximise:
    'Posting to all relevant buyers on the open market and your buyers will maximise your opportunity for bids on your listing.',
  pound: 'pound',
  pounds: 'pounds',
  poweredBy: 'Powered by',
  poweredByHectare: 'Powered by Hectare',
  preHarvestInventoryRecordsAvailable: 'Pre-harvest inventory records available',
  preciseLocationTip: 'Click to pin the designated precise location and click confirm.',
  predictedYield: 'Predicted yield',
  prefMovement: 'Pref. movement',
  preferences: 'Preferences',
  preferredCarriers: 'Preferred carriers',
  preferredCarriersCanBeSet: 'Preferred carriers can be set in the',
  preferredDestinations: 'Preferred destinations',
  preferredMovement: 'Preferred movement',
  preferredMovementDates: 'Preferred movement dates',
  preferredMovementMonth: 'Preferred movement month(s)',
  prefillRoute: 'Pre-fill route',
  prefillWithRoute: 'Pre-fill with route',
  preharvestHistory: 'Pre-harvest history',
  premium: 'Premium',
  premiumContractCreated: 'Premium contract created',
  premiumContractUpdated: 'Premium contract updated',
  premiumContracts: 'Premium contracts',
  premiumPrice: 'Premium price',
  premiumPricingType: 'Premium pricing type',
  premiumSetTo: 'Premium set to',
  premiumValue: 'Premium value',
  premovementHistory: 'Pre-movement history',
  premovementInventoryRecordsAvailable: 'Pre-movement inventory records available',
  prev: 'prev',
  previewCurrentprofileCard: 'This is a preview of your current profile card.',
  previous: 'Previous',
  previousPriceRequest: 'Previous price offer',
  previousPriceRequestAccepted: 'Previous price offer accepted',
  previousPriceRequestDeclined: 'Previous price request declined',
  previousPriceSent: 'Previous price sent',
  price: 'Price | Prices',
  priceAcceptanceTimeframe: 'Price acceptance timeframe',
  priceAccepted: 'Price accepted | Prices accepted',
  priceAgreed: 'Price agreed',
  priceBelow1Million: 'Price must be below 1 million',
  priceDifferenceIs:
    'Price difference is the change between the current typical price and yesterday’s typical price. ',
  priceExpired: 'Price expired',
  priceExpiry: 'Price expiry',
  priceFromName: 'Price from {name}',
  priceHasBeenWithdrawn: 'price has been withdrawn',
  priceInsightsAreBasedOnMarketPrices: 'Price insights are based on market prices and real-time bid data.',
  priceNoLongerActive: 'Price is no longer active',
  priceOffer: 'Make a price offer',
  priceOfferExpired: 'Price offer expired',
  priceOfferExpiringIn: 'Price offer expiring in',
  priceOfferFromX: 'Price offer from {buyer}',
  priceOffers: 'Price offers',
  priceOffersAccepted: 'Price offers accepted',
  priceOffersBreakdown: 'Price offers breakdown',
  priceOffersCanBeReviewed: 'Price offers can be reviewed',
  priceOffersExpireAt: 'Price offers expire at',
  priceOffersExpireIn: 'Price offers expire in',
  priceOffersExpired: 'Price offers expired',
  priceOffersReceived: 'Price offers received',
  priceOptionX: 'Price option {option}',
  pricePerBushel: 'Price per bushel',
  pricePerHead: 'Price per head',
  pricePerKm: 'Price per Km',
  pricePerMile: 'Price per mile',
  pricePerMovementMonth: 'Price per movement month',
  pricePerMovementMonthDescription:
    "If you're open to different movement months, add a price per movement month below.",
  pricePerTonne: 'Price per tonne',
  pricePerUnit: 'Price per {unit}',
  pricePerUnitSold: 'Price per unit sold',
  priceRangesAttractTheMostInterest:
    'Price ranges attract the most interest from sellers and makes your following offers more likely to be accepted.',
  priceReceived: 'Price received',
  priceRequestDeclined: 'Price request declined',
  priceScore: 'Price score',
  priceScoreConditionsTranslations: {
    average: 'Average',
    high: 'High',
    low: 'Low',
    newHigh: 'New high',
    newLow: 'New low',
    veryHigh: 'Very high',
    veryLow: 'Very low',
  },
  priceSent: 'Price sent',
  priceSentByUserOnDate: 'Price sent by {user} on {date} ({status})',
  priceSentOn: 'Price sent on',
  priceSource: 'Price source',
  priceSpread: 'Price spread',
  priceUnit: 'Price /{unit}',
  priceUpdated: 'Price updated',
  priceValidFor: 'Price valid for',
  priceWithdrawn: 'Price withdrawn',
  prices: 'Prices',
  pricesComparedWithPreviousDaysClose: `Prices compared with previous day's close.`,
  pricesExpire30MinutesAfterTheSellersReviewTime: `Price offers expire 30 minutes after the seller's review time`,
  pricesReceived: 'Prices received',
  pricingBasis: 'Pricing basis',
  pricingTerms: 'Pricing terms',
  primaryBusiness: 'Primary business',
  primaryColor: 'Primary color',
  primaryColour: 'Primary Colour',
  privacyCookiePolicy: 'Privacy and Cookie Policy',
  privacyPolicy: 'Privacy Policy',
  privacyPolicyDescription: 'How we look after your data',
  processedAfter4WorkingDays: 'Processed after 4 working days',
  processedAfterXWorkingDays: 'Processed after {days} working days',
  processing: 'Processing',
  processingPriceOffers: 'Processing price offers...',
  processor: 'Processor',
  produce: 'Produce',
  productSample: 'Product sample',
  productSampleRequired: 'Product sample required',
  profileBusinessConnections: 'Update profiles and add new business connections that you work with',
  profileCard: 'Profile card',
  profileImage: 'Profile image',
  profileMode: '{profileType} mode',
  profilePlural: 'Profile | Profiles',
  profiles: 'Profiles',
  progress: 'Progress',
  projectedYield: 'Projected yield',
  proofOfDeliveryUpload: 'Proof of delivery upload',
  proposalSentToBuyer: 'Price re-open request sent to buyer',
  protein: 'Protein',
  proteinMax: 'Protein Max',
  proteinMin: 'Protein Min',
  proteinPercentage: 'Protein (%)',
  provideBusinessDetails: 'Provide business details',
  provideLiveUpdates: 'Provide live updates on your estimated arrival time to those at the next stop point',
  province: 'Province',
  purchased: 'purchased',
  pushNotifications: 'Push notifications',
  pushNotificationsDisabled: 'Push notifications disabled',
  pushNotificationsEnabled: 'Push notifications enabled',
  qty: 'Qty',
  qualityUpdate: 'Quality update',
  quantity: 'Quantity',
  quantityAndPrice: 'Quantity and price',
  quantityDelivered: 'Quantity delivered',
  quantityInStore: 'Quantity in store',
  quantityMax: 'Quantity max',
  quantityMin: 'Quantity min',
  quantityMovingToDestinationLocation: 'Quantity moving to destination location',
  quantityRolled: 'Quantity rolled',
  quantitySold: 'Quantity sold',
  quantityToRoll: 'Quantity to roll',
  quickAndEasyToGetStarted: 'Quick and easy to get started',
  quickQuote: 'Quick Quote',
  quickQuoteChecklist: 'Quick Quote checklist',
  quickQuoteDisclaimer: 'This is a predicted estimate only and not an actual quote.',
  quickSelect: 'Quick select',
  quickStatsAboutYourListingsAndBids: 'Quick stats about your listings and bids',
  quicklyAndEasilyAddLoads: 'Quickly and easily add loads from your harvest.',
  quote: 'Quote',
  quoteAccepted: 'Quote accepted',
  quoteAcceptedEditingRestricted:
    'A quote has been accepted. Editing is now restricted to a limited set of fields.',
  quoteCancelled: 'Quote cancelled',
  quoteDeclined: 'Quote declined',
  quoteKnown: 'Price agreed',
  quoteReceivedOutsideSelectedCarriers:
    'If you have received a quote from a carrier outside of the originally selected carriers, please add the details below',
  quoteReference: 'Quote reference',
  quoteRequestFailed: 'Quote request failed',
  quoteRequested: 'Quote requested',
  quoteRequests: 'Quote requests',
  quoteRequired: 'Quote required',
  quoteSubmitted: 'Quote has been submitted',
  quoteSubmittedStatus: 'Quote submitted',
  quoteValue: 'Quote value',
  quoted: 'Quoted',
  quotes: 'Quotes',
  quotesReceived: 'Quotes received',
  range: 'Range',
  rangeSet: 'Range set',
  reRouteExplained: 'Re-routing explained',
  reRouteExplainedText:
    'Create a new stop below to add a re-route to the location where your crop has been rejected. This will then update the carrier, driver and new handler.',
  reRoutePrice: 'Price for re-route',
  reSendLink: 'Re-send link',
  reSendRegistrationLink: 'Re-send registration link',
  read: 'Read',
  readAllNotifications: 'You have read all of your notifications',
  readArticle: 'Read article',
  readMore: 'read more',
  readyToCreateYourJob: 'Ready to create your job',
  reasonForAdjustment: 'Reason for adjustment',
  receivableAssignmentContractTermsAndConditions: 'Receivable Assignment Contract, Terms & Conditions',
  receiveMorePrices: 'Receive more prices',
  receiveNotificationsToDesktopSmartphone:
    'Receive emails to your desktop and smartphone to keep up to date. Opt out if you do not want to receive these by WhatsApp.',
  receivePaymentFastWithinXWorkingDays: 'Receive payment fast, within {days} working days',
  receivedFrom: 'Received from',
  receivedOnDate: 'Received on {date}',
  receivingYourSecureSignInLink: 'Receiving your secure sign in link',
  recentSearches: 'Recent searches',
  recentTradePrices: 'Recent trade prices',
  recentTrades: 'Recent trades',
  recentlyListedCrop: 'Recently listed crop',
  recipient: 'Recipient',
  recipients: 'Recipients',
  recordHarvestSoldTonnage: 'Record harvested & sold tonnage',
  recordIntakesAndOuttakesAgainstStorageContracts:
    'Record intake and outtakes against storage contracts so you know how much inventory you have for each of your customers at a glance.',
  recordLoadsForSelectedFields: 'Record loads for selected field(s)',
  recordPlural: 'record | records',
  recordWeight: 'Record weight',
  recordYourHarvest: 'Record your harvest',
  recordYourHarvestDetail: 'Add information about the crop you have harvested below.',
  recordedWeight: 'Recorded weight',
  recordedYieldSoFar: 'Recorded yield so far',
  recordsIncomplete: 'Records incomplete',
  recoverPassword: 'Recover password',
  redTractor: 'Red tractor',
  redTractorCertificateNotFound: 'Red Tractor certificate not found',
  redTractorCertification: 'Red Tractor Certification',
  redTractorFarmNotFound: "Farm not found in Red Tractor's Checker",
  redTractorFormat: 'Format: 00/00/0000',
  redTractorName: 'Red Tractor',
  redTractorStatusUnverified: 'Red Tractor status is unverified',
  ref: 'Ref',
  reference: 'Reference',
  referenceNo: 'Reference No',
  referralCode: 'Referral code',
  refresh: 'Refresh',
  region: 'Region',
  regionISO: 'Region',
  regionLocationType: '{locationType} - {region}',
  rejected: 'Rejected',
  rejectedQuotes: 'Rejected quotes',
  relatedArticles: 'Related articles',
  relationshipType: 'Relationship type',
  remaining: 'Remaining',
  remainingAdvance: 'Remaining advance',
  remainingBalanceWillBePaidToYouAsUsual:
    'The remaining balance, less any adjustments or weighbridge fees will be paid to you as usual in line with the buyer’s standard payment terms.',
  remainingYieldProjected: 'Remaining yield (projected)',
  rememberToUseFlexTonnageToBuyMaxOffered:
    'Remember to use flex tonnage, where you can sell the maximum the buyer has offered.',
  reminder: 'Reminder',
  remove: 'Remove',
  removeAccount: 'Remove account',
  removeAsDefault: 'Remove as default',
  removeCombination: 'Remove combination',
  removeDelivery: 'Remove delivery',
  removeFromYourOrganisation: 'Remove from your organisation',
  removeOption: 'Remove option',
  removePremium: 'Remove premium',
  removePriceOption: 'Remove price option',
  removeTeamMember: 'Remove team member',
  removeThisDelivery: 'Remove this delivery',
  reopenExpiredOffer: 'Reopen expired offer',
  replaceCallsWithNotifications: 'Replace ‘What’s your ETA?’ calls & texts with automated ETA notifications',
  replaceCarrierTermsAndConditions: 'Replace existing carrier Terms and Conditions',
  reportsOverview: 'Reports overview',
  representativeTonnesToSell: 'Representative tonnes to sell',
  request: 'request',
  requestANewOne: 'request a new one?',
  requestANewTrade: 'Request a new trade',
  requestAPrice: 'Request a price',
  requestAccess: 'Request access',
  requestAccessThenViewListing: 'Request access then view listing',
  requestAccessToFullFreeAccount:
    'Request access to a full, free account where you will be able to access your dashboard and all listings available to you at anytime.',
  requestAccountAndViewListing: 'Request account and view listing',
  requestAccountRemoval: 'Request account removal',
  requestAccountRemovalExplainer: 'To request your account be removed, please contact our customer support team',
  requestCropFromSeller: 'Do you need to request a crop from a seller?',
  requestHelp: 'Request help',
  requestPriceNow: 'Request a price now',
  requestSent: 'Request sent',
  requestSentToBuyerHasXHoursRespond: 'Request sent to buyer. The buyer has {hours} hours to respond',
  requestSentToBuyerToReopenOffer: 'Request sent to buyer to reopen offer',
  requestSentToSellers: 'Request sent to sellers',
  requestToReopenPrice: 'Request to reopen price',
  requestToReopenPriceAndTheBuyerHas2Hours:
    '‘Request to re-open price’ and the buyer has 2 hours to accept or decline the trade',
  requestToReopenPriceOffer: 'Request to reopen price offer',
  requestToReopenPriceOfferSent: 'Request to reopen price offer sent',
  requestToReopenPrices: 'Request to reopen prices?',
  requested: 'Requested',
  requestedQuotes: 'Requested quotes',
  requestedToReopen: 'Requested to reopen',
  requests: 'Requests',
  requireDriverRecordWeightImage:
    'Ask driver to record the weight in the app at this location, ideally with an image',
  requireSampleInstructions: 'Require driver to record grain sample in the app, ideally with an image',
  requireUnloadSample: 'Require unload sample',
  required: 'Required',
  requirements: 'Requirements',
  reroute: 'Re-route',
  rerouteAddress: 'Re-route address',
  rerouteCost: 'Re-route cost',
  rerouted: 'Re-routed',
  reroutedLoad: 'Re-routed load',
  resendAuthCode: 'Resend your authentication code',
  resendConfirmationCode: 'Resend code',
  resendInvite: 'Re-send invite',
  resendRegistrationLink: 'Resend your registration link',
  resendSecureSignInLink: 'Resend secure sign in link',
  reset: 'Reset',
  resetCode: 'Reset code',
  resetCodeNotValid: 'This reset code and link is no longer valid.\nWould you like to',
  resetDate: 'Reset date',
  resetDetails: 'Reset details',
  resetFilters: 'Reset filters',
  resetPassword: 'Reset password',
  resetPercentage: 'Reset percentage',
  resetSearch: 'Reset search',
  resetToDefault: 'Reset to default',
  resetView: 'Reset view',
  resetYourPassword: 'Reset your password?',
  residential: 'Residential',
  resourcesToHelpGuideYouWhenUsingHectareTrading: 'Resources to help guide you when using Hectare Trading.',
  respondBeforeExpiry: 'Act now before they expire',
  respondToOffer: 'Ask buyer to re-open',
  respondToSeller: 'Respond to seller',
  respondedOn: 'Responded on',
  respondedOnDate: 'Responded on {date}',
  restartQuote: 'Restart quote',
  retry: 'Retry',
  retryConnection: 'Retry connection',
  returnToDashboard: 'Return to dashboard',
  returnToHelpCentre: 'Return to help centre',
  returnToJob: 'Return to job',
  returnToPage: 'Return to page',
  returnToSignIn: 'Return to sign in',
  review: 'Review',
  reviewAndOfferPrice: 'Review and offer price',
  reviewAndQuote: 'Review & quote',
  reviewAndRepost: 'Review and repost',
  reviewBid: 'Review bid',
  reviewBidEdits: 'Review bid edits',
  reviewBidOption: 'Review bid option',
  reviewCropDetails: 'Review crop details',
  reviewFollowingDocs: 'please review the following documents:',
  reviewFormErrors: 'Review 1 error in this form | Review {count} errors in this form',
  reviewListing: 'Review listing',
  reviewOffer: 'Review offer',
  reviewOtherPriceOffers: 'Review other price offers',
  reviewOtherPrices: 'Review price offers',
  reviewPriceOffers: 'Review price offers',
  reviewPriceOffersAt: 'Review price offers at',
  reviewPriceOffersNow: 'Review price offers now',
  reviewPriceOffersTodayAt: 'Review price offers today at',
  reviewPriceOffersTomorrowAt: 'Review price offers tomorrow at',
  reviewQuotes: 'Review quotes',
  reviewQuotesChooseCarrier: 'Review quotes & choose carrier',
  reviewRequestAndSend: 'Review request and send',
  reviewRouteAndChooseWeighbridge: 'View the route, if changes are neeeded click the edit button above',
  reviewScheduledForTodayAtXYourOfferExpiresAtX:
    'Review scheduled for today at {time}. Your offer expires at {expiryTime}',
  reviewSellersRequest: 'Re-open price request',
  reviewThePriceOffer: 'Review the price offer',
  reviewTime: 'Review time',
  reviewYourOffers: 'Review your offers',
  roads: 'Roads',
  rodentDamage: 'Rodent damage',
  rodentInfestation: 'Rodent infestation',
  role: 'Role',
  rollContract: 'Roll contract',
  rollOverInto: 'Roll over into',
  rolledInto: 'Rolled into',
  rollingContract: 'Rolling contract',
  route: 'Route',
  routeDetails: 'Route details',
  routeMap: 'Route map',
  routeSummary: 'Route summary',
  row: 'Row',
  rowsSelected: 'rows selected',
  salesContractUpdated: 'Sales contract updated',
  salesContracts: 'Sales contracts',
  salesTax: 'Sales tax label',
  salesTaxDetails: 'Sales tax details',
  salesTaxLabel: 'Sales tax label',
  salesTaxPercentage: 'Sales Tax percentage',
  sameAddressForBilling: 'We are using the same address for billing',
  sample: 'Sample',
  sampleAdded: 'Sample added',
  sampleData: 'Sample data',
  sampleDeleted: 'Sample deleted',
  sampleInformationAddedToLoad: 'Sample information added to load',
  sampleRecord: 'Sample record',
  sampleRecordUpdated: 'Sample Record Updated',
  sampleRecords: 'Sample records',
  sampleUpload: 'Sample upload',
  sampleUploadRequiredHere: 'Sample upload required here',
  satellite: 'Satellite',
  save: 'Save',
  saveAllRecords: 'Save all records',
  saveBankDetails: 'Save bank details',
  saveCategory: 'Save category',
  saveChanges: 'Save changes',
  saveCombination: 'Save combination',
  saveCombinations: 'Save combinations',
  saveContract: 'Save contract',
  saveCustomer: 'Save customer',
  saveGroup: 'Save group',
  saveInformation: 'Save information',
  saveLoad: 'Save load',
  saveLoads: 'Save loads',
  saveLocation: 'Save location',
  saveMovement: 'Save movement',
  saveNewPrice: 'Save new price',
  saveOrganisation: 'Save organisation',
  saveProfile: 'Save profile',
  saveRecord: 'Save record',
  saveSettings: 'Save settings',
  saveSpecification: 'Save specification',
  saveStoreInfo: 'Save store info',
  saveTrade: 'Save trade',
  saveVehicle: 'Save vehicle',
  saveVehicles: 'Save all vehicles',
  saved: 'Saved',
  savedSuccess: 'Saved successfully',
  scheduledListings: 'Scheduled listings',
  scheduledListingsThatAreOpenForOffersFromBuyersOneHourBeforeYourReviewTime:
    'Scheduled listings that open for offers from buyers one hour before your review time.',
  scheduledMovement: 'Scheduled movement',
  schemeMembershipNumber: 'Scheme/Membership number',
  scottishAssurance: 'Scottish assurance',
  scottishAssuranceFormat: 'Format: F123456',
  search: 'Search',
  searchAndSelectABusinessUnit: 'Search and select a business unit',
  searchAndSelectAnyThatApply: 'Search and select any that apply',
  searchAndSelectBusinessUnits: 'Search and select any business units that apply',
  searchBusinessUnitsOrLocationGroups: 'Search business units/location groups',
  searchByBusinessNameEmailAddress: 'Search by business name/email/address',
  searchByBuyersName: 'Search by buyers name',
  searchByContractID: 'Search by contract ID',
  searchByContractIDBusinessNetwork: 'Search by contract ID/business network',
  searchByContractIdOrBuyer: 'Search by contract ID or buyer',
  searchByCounty: 'Search by county',
  searchByCrop: 'Search by crop',
  searchByLocationNameAddress: 'Search by location name/address',
  searchByLocations: 'Search by locations',
  searchByRegionOrCounty: 'Search by region or county',
  searchByTypeAndGroup: 'Search by type & group',
  searchEvents: 'Search for events',
  searchExternalBusiness: 'Search and select an external business',
  searchForBusinessUnit: 'Choose farm/business unit',
  searchForCarrier: 'Search for carrier',
  searchForCustomer: 'Search for customer',
  searchForGroup: 'Search for group',
  searchForInvoice: 'Search for Invoice',
  searchForJob: 'Search for job',
  searchForLoad: 'Search for load',
  searchForLocation: 'Seach for location',
  searchForOrganisation: 'Search for organisation',
  searchForProfile: 'Search for profile',
  searchForQuote: 'Search for quote',
  searchForTheBusinessUnit: 'Search for the business unit',
  searchForVehicle: 'Search for vehicle',
  searchForYourCrop: 'Search for your crop',
  searchInternalField: 'Search and select an internal field',
  searchLocations: 'Search locations',
  searchLogs: 'Search for logs',
  searchResults: 'Search results',
  searchSelectStore: 'Search and select a store',
  searchVehicle: 'Search vehicle',
  secondaryColor: 'Secondary color',
  seconds: 'seconds',
  secondsShorthand: 's',
  section: 'section',
  secureSignInSentToX: 'Secure sign in link sent to {username}',
  security: 'Security',
  securityAndSafetyAtTheHeartOfHectare: 'Security and safety at the heart of Hectare',
  see: 'See',
  seeAHandRepresentativeExampleOfHowItWorks:
    'As soon as one arrives, you’ll see a handy representative example reflecting an offer. In the meantime, see below for how Advance Pay works.',
  seeBelowForSteps: 'See below for helpful first steps to get started',
  seeDetails: 'See details',
  seeListOfInvoicesForJobs: 'See a full list of outstanding invoices for completed jobs',
  seeOptions: 'See options',
  seeOur: 'See our',
  seeWhatsAvailableToTrade: 'To see what you have available to trade of a specific crop, use the filters above.',
  seeYourSoldPosition: 'See your sold position based on what you have in the shed. ',
  selectAField: 'Select a field',
  selectASingleOrRangeOfPreferredMovementMonths: 'Select a single or range of preferred movement months',
  selectASpecificRangeMovement: 'Select a specific or range of movement months if you are flexible.',
  selectATime: 'Select a time',
  selectAll: 'Select all',
  selectAllApply: 'Select all that apply | Select one | Select up to {count}',
  selectAllPreferredCarriers: 'Select all preferred carriers',
  selectAllPreferredDestinations: 'Select all preferred destinations',
  selectAnOrganisationFirst: 'Select an organisation first',
  selectCarrier: 'Select carrier',
  selectCarriers: 'Select carriers',
  selectCarriersForQuotes: 'Select carriers for quotes',
  selectCarriersForQuoting: 'Select carriers for quoting',
  selectCountry: 'Select country',
  selectDateOrRange: 'Select date or range',
  selectDateRange: 'Select date range',
  selectDriver: 'Select driver',
  selectForBulkAction: 'Select for bulk action',
  selectGrade: 'Select grade',
  selectInventoryBulkAction: 'Select inventory for bulk action',
  selectInventoryForBulkAction: 'Select inventory for bulk action',
  selectLocation: 'Select a location',
  selectLocationsBelowAndAdd: 'Select mutiple locations below and click add to group',
  selectMonthOrDateRange: 'Select month or date range',
  selectNone: 'Select none',
  selectOneOrMore: 'Select one or more',
  selectOrganisation: 'Select organisation',
  selectPrimaryContact: 'Select a primary contact for all loads.',
  selectQuote: 'Select quote',
  selectRecords: 'Select records',
  selectRegionsCounties: 'Select regions or counties',
  selectRequiredCrop: 'Please select the required crop',
  selectSingleMultipleDates: 'Select single/multiple dates',
  selectSingleMultipleFields: 'Select single or multiple fields to record loads for this harvest.',
  selectSingleMultipleMonths: 'Select single/multiple months',
  selectTheTimeYouWillReviewPriceOffersReceived: 'Select the time you will review price offers received',
  selectVariety: 'Select variety',
  selectVehicle: 'Select vehicle',
  selectWhichInventory:
    'Please select which inventory you would like to move by clicking the checkboxes or three dots in the table rows.',
  selectYourCountry: 'Select your country',
  selected: 'Selected',
  selectedCarriers: 'Selected carrier | Selected carriers',
  selectedFiltersData: 'Current selected filters',
  selectedForQuotes: 'selected for quotes',
  selectedOnlyAvailableDriver: 'Only available driver has been selected.',
  'selectedStores/FieldsHaveBeenAdded': 'Selected stores/fields have been added',
  selectedVarieties: 'Selected varieties',
  selectingCarriersMayImproveAccuracy: 'Selecting carriers may improve estimate',
  selectionClearedOnPageChange: 'Your selection on this page will be cleared when you go to a different page.',
  seller: 'Seller',
  sellerDetails: 'Seller details',
  sellerIsListingMovementAsAvailable: 'Seller is listing movement as available',
  sellerNotes: 'Notes from seller',
  sellerRequestToReopenPrice: 'Seller request to re-open price',
  sellerReviewAtX: 'Seller review at {time}',
  sellerUnlockWhenBidAccepted: 'Seller: Unlocked when bid accepted',
  sellerUnlockedWhenBidAccepted: 'Seller: Unlocked when bid accepted',
  sellerWillReviewAtX: 'Seller will review at {time}',
  sellersReviewTime: `Seller's review time`,
  sellersTargetPrice: 'Sellers target price',
  selling: 'Selling',
  sendAContactRequest: 'Send a contact request',
  sendAnotherPrice: 'Send another price',
  sendBidToSeller: 'Send bid to seller',
  sendContactRequest: 'Send a contact request',
  sendEmailInvite: 'Send email invite',
  sendFeedback: 'Send feedback',
  sendInvite: 'Send Invite',
  sendListing: 'Post listing',
  sendPriceOffer: 'Send price offer',
  sendPriceOfferX: 'Send price offer - {price}',
  sendQuote: 'Send quote',
  sendRequest: 'Send request',
  sendRequestAndViewListing: 'Send request & view listing',
  sendRequestToReopenOffer: 'Send request to reopen the offer',
  sendRequestToSellers: 'Send request to sellers',
  sendRevisedBidToSeller: 'Send revised bid to seller',
  sendSecureSignInLink: 'Send secure sign in link',
  sendThisListingToBuyers: 'Send listing to buyers',
  sendThisRequestToSellers: 'Send this request to sellers',
  sendToAll: 'Send to all',
  sendToContacts: 'Send to {n} contacts | Send to {n} contact | Send to {n} contacts',
  sendToEveryone: 'Send to everyone',
  sendToEveryoneIncludingBuyers: 'Send to everyone (including your buyers)',
  sendToPreferred: 'Send to preferred',
  sendToTrustedNetwork: 'Send to trusted network',
  sendTradeRequest: 'Send request',
  sendWantedRequest: 'Send wanted request',
  sendYouPasswordLink: "We'll send you a link to set a new password.",
  sendsYouAPrice: 'Sends you a price',
  sent: 'Sent',
  sentAutomaticallyWhenJobCompletes: 'Invoices sent automatically when a job completes',
  sentBy: 'Sent By',
  sentByDvla: 'Sent from DVLA lookup',
  sentPriceSummary: 'Sent price summary',
  sentTo: 'Sent to',
  sentToChosenBuyers: 'Sent to chosen buyers',
  sentToMyNetwork: 'Sent to my network',
  service: 'Service',
  serviceAgreed: 'Service agreed',
  serviceAgreedStorageContract: 'Service agreed in the storage contract',
  serviceTerms: 'Service terms',
  services: 'Services',
  sessionExpired: 'Session expired',
  setACropGroupRegionCombination: 'Set a crop, group, region combination',
  setAMinAndMaxTonnage:
    'Set a min and a max tonnage range to attract more buyers who may be looking to buy more tonnage. Our buyers are always looking for more crop so choose the min you’re prepared to sell and the max you have to get more bids.',
  setANewPassword: 'Set a new password',
  setATargetPriceAlert: 'Set a target price alert',
  setAlertPreferences: 'Set alert preferences',
  setAsDefault: 'Set as default',
  setBusinessUnits: 'Set business units',
  setCollectionDate: 'Set a collection date',
  setContractTerms: 'Set contract terms',
  setDateRange: 'Set date range',
  setLocation: 'Set location',
  setNewPassword: 'Create a new password',
  setPassword: 'Set password',
  setPermissions: 'Set permissions',
  setPreciseLocation: 'Set precise location',
  setPreferences: 'Set preferences',
  setPriceExpiryTimes: 'Set price expiry times or withdraw offers in a dynamic market',
  setPricesGetAlerts: 'Set price targets and get alerts when the market hits the mark',
  setTargetPrice: 'Set target price',
  setTargetPrices: 'Set target prices',
  setTargetPricesBannerDescription:
    'Never miss a market high with instant notifications when the market hits your target price.',
  setTargetPricesForYourCommodities: 'Set target prices for your commodities',
  setTargetPricesToBeAlerted:
    'Set up your target prices to be alerted when the ex farm price reaches your breakeven or target price',
  setTargetPricesToReceiveAlerts:
    'Set target prices to receive alerts for market highs and lows, helping you make informed decisions about when to sell.',
  setTargetPricesWithIcon: '🔔 Set target prices',
  setTargetPricesWithoutPosting: 'Set target prices without posting a listing or adding crops',
  setUpOwnersProfile: 'Set up owners profile',
  setUpTargetPrices: 'Set target prices',
  setUpTargetPricesGetAlert:
    'Set up your target prices so you can get alert on when the ex farm price has reached a particular price',
  setUpYourStorageContractsAndApply:
    'Set up your storage contracts and apply automatic adjustments for drying and cleaning losses.',
  setYourPreferenceCombinations:
    'Set your preference combinations for different crops, groups and regions, to receive personalised alerts.',
  settings: 'Settings',
  settled: 'Settled',
  settlementAndDelivery: 'Settlement & delivery',
  settlementContact: 'Settlement contact',
  settlements: 'Settlements',
  setup: 'Setup',
  sex: 'Sex',
  shareWithDriver: 'Share with driver',
  shareXWithDriver: 'Share {loadRef} with driver',
  sharingYourLocationWillEnableUs: 'Sharing your location will enable us to:',
  show: 'Show',
  showAll: 'Show all',
  showAllCrops: 'Show all crops',
  showDetails: 'Show details',
  showFullDetails: 'Show full details',
  showList: 'Show list',
  showMeMore: 'Show me more',
  showMoreFields: 'Show more fields',
  showMoreStorage: 'Show more storage',
  showYourOrganisationDetailsWithThisRequest: 'Show your organisation details with this request',
  showingResults: 'Showing {currentDocumentIndex} to {range} of {results} results',
  signIn: 'Sign in',
  signInOptions: 'Sign in options',
  signInWithoutPassword: 'Sign in without password',
  signIntoYourAccount: 'Sign in to your account',
  signOut: 'Sign out',
  signUp: 'Sign up',
  signUpForAFreeTradingAccount: 'Sign up for a free trading account',
  signUpForFreeAccount: 'Sign up for free account',
  signedInToAccessOrganisationView: "You'll be signed in and able to access the organisation view.",
  signedOut: 'Signed out',
  signingOut: 'Signing out...',
  silo: 'Silo',
  sitTightAndWaitForThePricesToComeOnIn: 'Sit tight and wait for the prices to come on in',
  skip: 'Skip',
  slideToArrive: 'Swipe to arrive',
  slideToDepart: 'Swipe to depart',
  slideToFinishJob: 'Swipe to finish job',
  slideToStartJob: 'Swipe to start job',
  smarterAgricultureForEveryone: 'Smarter Agriculture, for everyone',
  smsAppNotificationsAndEmail: 'SMS, App Notifications and Email',
  sold: 'Sold',
  soldOffline: 'Sold offline',
  soldOn: 'Sold on',
  soldPosition: 'Sold position',
  soldPositionHigherThanDesiredPosition: 'Sold position is higher than your desired position',
  soldTo: 'Sold to',
  soldTonnage: 'Sold tonnage',
  somethingWentWrong: 'Something went wrong, please reload or go back and try again',
  somethingWentWrongPleaseRefresh: 'Something went wrong. Please refresh the page and try again.',
  somethingWentWrongShort: 'Something went wrong',
  somethingWentWrongWithYourAction:
    'Something went wrong. Your input/action may not be successfully recovered. If the issue persists, please contact help@wearehectare.com.',
  sorryPleaseCheckLink: 'Sorry something has gone wrong, please check the link you have used is correct',
  sorryTheresBeenAProblemIssuingYourAdvancePayment:
    'Sorry, there’s be a problem issuing your advance payment. Your trade with the buyer is still accepted but has been changed to a standard trade until resolved.',
  sorryThisBusinessUnitCannotBeDeleted:
    'Sorry, this Business Unit cannot be deleted as it is linked to crops or a listing.',
  sorryWeCouldntFindWhatYouWereLookingFor: "Sorry, we couldn't find what you were looking for.",
  sortAndFilter: 'Sort and filter',
  sortCode: 'Sort code',
  sortCodeNotValidFormat: 'Sort code is not in a valid format',
  sortcode: 'Sort code',
  sortcodeFormat: 'Format: 00-00-00',
  southAfrica: 'South Africa',
  specialRequirements: 'Special requirements',
  species: 'Species',
  specific: 'Specific',
  specification: 'Specification',
  specificationDeviateDefault: 'Does your specification deviate from this default?',
  specificationSettings: 'Specification settings',
  specifications: 'Specifications',
  specifyCommodityDetails: 'Specify commodity details',
  specifyCropDetails: 'Specify crop details',
  specifyNumberOfDays: 'Specify number of days',
  spoilage: 'Spoilage',
  spot: 'Spot',
  sqcFarmNotFound: "Farm not found in SQC's Checker",
  staff: 'Staff',
  standard: 'Standard',
  standardCropSpecification: 'Standard crop specification',
  standardSpecForChosenCrop: 'Standard specification for your chosen crop',
  start: 'Start',
  startANewListing: 'Start a new listing',
  startAddingCommoditiesToGetPosition: 'Start adding your commodities to get a overall view of your position.',
  startDate: 'Start date',
  startImportingContractsHere:
    'Start importing your storage contracts here to simplify storage agreement traceability and provide better storage services',
  startJob: 'Start job',
  startSettingUp: 'Start setting up',
  startTypingAddressOrPostcode: 'Start by typing address or postcode',
  startTypingYourAddress: 'Start typing your address',
  startTypingYourAddressOrPostcode: 'Start typing your address or postcode',
  startingANewListingWillRemoveTheSavedDraft: 'Starting a new listing will remove the saved draft',
  startingPointDestination: 'Starting point & destination ',
  status: 'Status',
  stayAheadOfGrainMarketInNewsletter:
    'Stay ahead of the grain market with our weekly newsletter about commodity trends',
  stayOnTopOfTheMarketWithTargetPriceAlerts: 'Stay on top of the market with target price alerts',
  step: 'step',
  stepNumOfTotal: 'step {currentStep} out of {totalSteps}',
  stepX: 'Step {step}',
  stillAwaitingQuotesFromCarriers: 'Waiting for quotes from 1 carrier | Waiting for quotes from {count} carriers',
  stillHaventReceivedAnythingReenterEmail:
    'If you still haven’t received anything please re-enter your email/phone below for another link.',
  stillLoading: 'Still loading...',
  stillLoadingTryRefreshing: 'Still loading...try refreshing',
  stillSaveHoldingNumber: 'You can still save the holding number but it will remain unverified.',
  stillToCome: 'Still to come',
  stop: 'Stop',
  stopTypeLoad: 'Load',
  stopTypeLoadUnload: 'Load and unload',
  stopTypeUnload: 'Unload',
  storage: 'Storage',
  storageAndSubStorage: 'Storage and sub-storage',
  storageContract: 'Storage contract',
  storageContractIdEndsOnDate: 'Storage contract ID{reference}, ends on {date}',
  storageContractUpdated: 'Storage contract updated',
  storageContracts: 'Storage contracts',
  storageEmpty: 'Storage empty',
  storageLocations: 'Storage locations',
  storageLocationsWillBeReset: 'Selected storage locations will be reset',
  storageNotes: 'Storage notes',
  storageSetupLocationsManagement: 'Store set-up, capacity, locations and management',
  store: 'Store',
  storeCapacity: 'Store capacity',
  storeDamage: 'Store damage',
  storeField: 'Store/Field set up',
  storeHasFutureMovement:
    'This location has a future movement. You are unable to delete this location until the movement has been completed.',
  storeInformation: 'Store information',
  storedExternally: 'stored externally',
  storedFor: 'Stored for 3rd party',
  stores: 'Stores',
  'stores/fields': 'Stores/Fields',
  storesX: 'No stores | {count} store | {count} stores',
  streetOrRegion: 'Street or region lookup',
  'sub-total': 'Sub-total',
  subStorage: 'Sub-storage',
  subStorageForStore: 'Do you have any sub-storage for this store?',
  subStoreId: 'Sub-store {id}',
  subStoreName: 'Sub store name',
  submit: 'Submit',
  submitQuote: 'Submit Quote',
  submitted: 'Submitted',
  subtotal: 'Subtotal',
  subtotalInStore: 'Subtotal in this store',
  successful: 'Successful',
  successfullySignedOut: 'You have successfully signed out',
  successfullyUpdated: 'Successfully updated',
  successfullyWithdrawn: 'Successfully withdrawn',
  summary: 'Summary',
  summaryDestinations: 'Quotes for your destinations',
  summaryInStore: 'Summary in store',
  superAdmin: 'Super admin',
  supplyingCropInformation: 'Supplying crop information',
  supportDesk: 'Support desk',
  supportEmail: 'support@farmto.com',
  sureChangeThisOption: 'Are you sure you want to change this option?',
  swipeArrived: 'Arrived!',
  swipeArriving: 'Arriving at stop...',
  swipeDeparted: 'Departed!',
  swipeDeparting: 'Departing location...',
  swipeFinishing: 'Finishing your job...',
  swipeStarting: 'Starting your job...',
  switchAccount: 'Switch account',
  tAndCsPrivacyPolicy: 'T&Cs and Privacy Policy',
  tab: 'tab',
  takeAction: 'Take action',
  takeHassleOutOfInvoicing: 'Take the hassle out of invoice generation for good!',
  takeMeThere: 'Take me there',
  takePhoto: 'Take photo',
  takePhotoSampleResults: 'Take a photo of the sample results paper',
  takePhotoWeighbridgeReading: 'Take a photo of the weighbridge reading',
  takingTooLongTryReloading: 'This is taking too long, try reloading the app or contact us to investigate.',
  target: 'Target',
  targetBreakdown: 'Target breakdown',
  targetMargin: 'Target margin',
  targetPrice: 'Target price',
  targetPriceAlerts: 'Target price alerts',
  targetPriceForEveryRegion: 'Target prices for every region',
  targetPriceSaved: 'Target price saved',
  targetPriceSpotBanner:
    'Keep on top of the market and get notified when the spot price moves above or below your target price.',
  targetPriceX: 'Target price ({currency}/{unit})',
  targetPrices: 'Target prices',
  targetPricesSaved: 'Target prices saved',
  taxNumber: 'Tax number',
  team: 'Team',
  teamMembersReceivingAlerts:
    'team members receiving alerts | team member receiving alerts | team members receiving alerts',
  teams: 'Teams',
  telephone: 'Telephone',
  tellTheBuyerHowMuchYouWouldLikeToSell: 'Tell the buyer how much would you like to sell',
  tellUsCropGroupRegionToTailorAlerts:
    'Tell us what crops, groups and regions you want to buy and we’ll tailor your alerts.',
  tellUsFinalMovementDate:
    'Tell us the final movement date so this can be communicated to ensure a timely settlement.',
  tellUsWhatCropsYouHaveSoThatWeCanSendYouSpecificInformation:
    'Tell us what crops you have so that we can send you specific information about those markets. You can choose how you receive these messages in the next step.',
  tellUsWhenToMoveCropAndQuantityToSell:
    'Tell us when you would like to move your crop and how much you would like to sell.',
  temp: 'Temp',
  temperature: 'Temperature',
  temporarilyApproved: 'Temporarily approved',
  temporaryPassword: 'Temporary password',
  term: 'Term',
  terms: 'Terms',
  termsAndConditions: 'Terms and Conditions',
  termsAndPolicies: 'Terms and policies',
  termsConditions: 'Terms & Conditions',
  termsConditionsPrivacyPolicy: 'Terms & Conditions and Privacy Policy',
  termsConditionsPrivacyPolicyDescription: 'Our standard terms for using the platform.',
  termsOfService: 'Terms of service',
  termsOfServiceDescription: 'Policy documents',
  termsOfServiceDetail: 'Our terms of service for using InstantInvoice',
  termsOfUse: 'Terms of use',
  termsPrivacyPolicy: 'Terms and Privacy Policy',
  test: 'Test',
  testingSiteOnly: 'This is a Testing site only',
  thankYouForSigningUp: 'Thank you for signing up',
  thankYouForUploadingCollectionReceipt:
    'Thank you for uploading your collection receipt, this will now be checked.You will receive confirmation via email about the remaining payment.',
  thankYouForUploadingFinalCollectionReceipt: 'Thank you for uploading this final collection receipt.',
  thankYouPatienceCheckBackSoon: 'Thank you for your patience and please check back soon.',
  thankYouPatienceEstimatedTime: 'Thank you for your patience we aim to be back online by {time}',
  thanksForChoosingAdvancePayWereProcessingThisTrade:
    'Thanks for choosing Advance Pay. We’re processing this trade, for which you will receive your payment within the next 3 working days.',
  thanksForConfirmingMovementDate: 'Thanks for confirming the movement date.',
  thanksNowAboutThoseCrops: 'Thanks! Now, about those crops...',
  thanksQuoteReceived: 'Thanks, your quote has been received.',
  thanksYourWorkIsDone: 'Thanks, your work here is done.',
  thatEmailOrPasswordWasInvalid: 'That email or password was invalid',
  thatJobCanNoLongerBeDriven: 'That job can no longer be driven',
  thatUsernameOrPasswordWasInvalid: 'That username or password was invalid',
  thatYouReceive: 'that you receive',
  theAmountMovedIsHigherThanTheTotalSelected:
    'The amount moved is higher than the total selected - {originAmount} {unit}. You need to remove {amountRemaining} {unit} from one of the destinations.',
  theAmountMovedIsLowerThanTheTotalSelected:
    'The amount moved is lower than the total selected - {originAmount} {unit}. You still have {amountRemaining} {unit} to move.',
  theBusinessUnitCannotBeRecoveredAfterDeletion:
    'This business unit cannot be recovered after deletion. Are you sure you want to delete it?',
  theBuyerHasXHoursToAcceptYourRequest: 'The buyer has {hours} hours to accept your request',
  theBuyerIsVerifiedFullyVettedCreditChecked:
    'This buyer is Hectare verified. This means they have been fully vetted and credit checked, giving you peace of mind to trade securely with them.',
  theBuyerWillRespondToRequestSoon: 'The buyer will respond to your request soon',
  theBuyersContractWillBeRequiredToReleaseTheInitialAdvance: `The buyers’ contract will be required to release the initial advance`,
  theCustomerSuccessTeamHasBeenNotified:
    'The customer success team has been notified and will be in contact with you about your account shortly.',
  theDriverHasReceivedUploadRequest: 'The driver has received your POD upload request.',
  theFeesShownAreIndicative:
    'The fees shown are indicative based on a daily rate for the terms agreed. All fees will be deducted from the payment following movement confirmation, move earlier and you’ll pay less.',
  theHaveXHoursToRespondToRequest: 'They will have {hours} hours to respond to your request.',
  theInventoryIsMovingTo: 'The inventory is moving to',
  theMoreInformationYouCanProvideAboutYourCropsTheBetter:
    'The more information you can provide about your crop, the better. Buyers need all of this information to offer you their best price.',
  thePlatformIsSoEasyToUse:
    '“The platform is so easy to use and such a time saver. I got bids from all the merchants I’d normally call plus some I wouldn’t - without having to spend hours on the phone.”',
  thePriceMacdAndRsi: 'The Price {macd} and {rsi}',
  theSellerHasAcceptedAdvancePayForTheTrade: 'The seller has accepted Advance Pay for this trade',
  theSellerHasAssignedTheReceivable: 'The Seller has assigned the receivable to MIR Lux Capital',
  theSellerHasChosenTheirPreferredMonths: 'The seller has chosen their preferred movement of',
  theSellerWillReviewPriceOffersTodayAtXAllExpireAfter30Minutes:
    'The seller will review price offers today at {time}. All offers expire after 30 minutes.',
  theft: 'Theft',
  thenGetPaidWithinXWorkingDays: 'Then, get paid within {days} working days',
  thereAreNoActionsToDisplay: 'There are no actions to display',
  thereAreNoAvailableDriversOnYourAccount:
    'There are no available drivers on your account, please {contactSupport}',
  thereAreNoScheduledListings: 'There are no scheduled listings',
  thereAreNoStorageLocationsLinkedWithThisBusinessUnit:
    'There are no storage locations linked with this business unit. Update this in your store set up.',
  thereIsNothingToDoNow: 'There is nothing to do now',
  thereWasAProblemResendingInviteContactSupport:
    'There was a problem resending the invite. Please contact support or try again later',
  thereWasAnErrorWithUploadedFiles:
    'There was an error with uploaded files, please try removing and re-uploading.',
  thereWasProblemWithThatRequest: 'There was a problem with that request',
  theyWillReceiveANotificationOfYourMarketListing: 'They will receive a notificaiton of your market listing',
  thirtyMinutes: '30 minutes',
  thisBusinessUnitCannotBeDeleted: 'This business unit cannot be deleted',
  thisCompanyHasNotIncludedABio: 'This company has not included a bio.',
  thisCropDetails: 'This crop details',
  thisCropIsDefaultedFromCombination: 'This crop is defaulted from your combination setting.',
  thisHelpsFindOutQAAssured: "This helps us find out if it's QA assured",
  thisHelpsFindOutQualityAssured: 'This helps us find out if it’s QA assured',
  thisIntakeWillBeConnectedTo: 'This intake will be connected to',
  thisInvoiceWillBeConnectedToMovement: 'This invoice will be connected to movement',
  thisIsConfirmationOfTradeNotContract: 'This is a confirmation of your trade and is not a contract.',
  thisIsForGuidanceBuyerWillNotSee: 'This is for guidance only and will not be visible to the buyer.',
  thisIsNotAContract: 'This is not a contract',
  thisIsNotAContractItsASummary: "This is not a contract, it’s a summary of the trade you've agreed",
  thisIsSimplyADealTicket: "This information is simply a summary of the trade you've agreed",
  thisIsTheDefault: 'This is the default',
  thisIsWhereYoullManageAndReviewPrices:
    'This is where you’ll manage and review all the prices you receive on listings. To start getting prices from our verified, credit checked buyers, see the how-to guide or go ahead and send your first listing.',
  thisLeavesXUnitAtStorageQuestion: 'This leaves {displayWeight} at storage. Is this correct?',
  thisListingHasEndedByTheSeller: 'This listing has ended by the seller',
  thisListingWasLiveForXDaysHelpUsUnderstand: `This listing was live until {date}, help us understand why your previous listings needs new buyer interest`,
  thisMarketListingHasEnded: 'This market lising has ended',
  thisMonth: 'This month',
  thisMovementWillBeConnectedTo: 'This movement will be connected to',
  thisMustBeWithinQuantity: 'This must be within the quantities listed above.',
  thisNotifiesBuyersThatListingEnded:
    'This notifies buyers with open price offers that you no longer wish to sell this crop.',
  thisPriceNoLongerExists:
    'Unfortunately this price no longer exists. Look out for more market listings from your contacts.',
  thisTeamMemberWillBeOwner:
    'This team member will become the owner of the organisation and you will no longer have owner permissions.',
  thisTradeHasBeenAcceptedWithAdvancePay:
    'This trade has been accepted with Advance Pay and the receivable is now assigned to MIR LUX capital. This is contingent upon the agreed receivable contract, terms and conditions as accepted by the Seller below.',
  thisTradeHasBeenAcceptedWithAdvancePaySellerAssigned:
    'This trade has been accepted with Advance Pay and the Seller has assigned the receivable to MIR Lux capital. This assignment of the receivable has been made by the Seller accepting the contract, terms and conditions as linked below.',
  thisWillAlertBuyersWeWillNotReNotify:
    'This will alert all relevant buyers across the network including your own buyers. We will not re-notify buyers already contacted and you will no longer be able to specify buyers to send this listing to.',
  thisWillBeUsedToCalculateYourTotalReceipts:
    'This will be used to calculate your total receipts against the contract.',
  thisWillResultInOverCapacity: 'This will result in over capacity at the destination',
  ticket: 'Ticket',
  ticketUploaded: 'Ticket uploaded',
  time: 'Time',
  timeToRecordYourNewPlantings:
    'Time to record your new plantings for the coming harvest year so you can better plan your inventory next year!',
  timeframeExpired: 'Timeframe expired',
  timeline: 'Timeline',
  timezones: 'Timezones',
  tipped: 'Tipped',
  tippedQuantity: 'Tipped quantity',
  tips: 'Tips',
  tipsFromFarmers: 'Tips from fellow farmers',
  to: 'To',
  toAddNewOrganisationsOrContactsPleaseVisitYourBusinessNetworkPages:
    'To add new organisations or contacts, please visit your {businessNetwork} pages',
  'toAddStores/FieldsGoToGroupsPage': 'To add your stores/fields into groups, go to the group page.',
  toBeSold: 'To be sold',
  toBulkAdjustGoBackSelectMultiple:
    'If you want to bulk adjust, go back to your inventory list and select multiple inventory records there first.',
  toCancelInstantInvoice: 'To cancel InstantInvoice service, please email the customer services teams',
  toCreateANewContractClickNewContract: 'To create a new contract simply click ‘New contract’ or view your',
  toEditAContractClickTheActionThreeDots:
    'To edit a contract, click the action three dots at the end of each table row.',
  toExportAMovementItMustHaveAStorageContractAttached:
    'To export a movement, it must have a storage contract attached.',
  toGetPaidYouMustEmailTheBuyersContractTo: 'To get paid you must email the buyers’ contract to',
  toGetPriceBackFromBuyers: 'to get prices back from buyers',
  toReviewPriceOffersBeforeTheyExpire: 'to review price offers before they expire',
  toSaveTimeFillingChooseDuplicateMovement:
    'To save time filling the same info in again, choose to duplicate the movement form and select the number of copies.',
  toSetUpAccount: 'to set up a user account for you.',
  toStartRecievingListingsVisitPreferences:
    'To start receiving listings, please visit your alert preferences and either opt-in to receive everything or customise what you receive with custom combinations.',
  toTheNearestMetre: 'To nearest {unit}',
  toTradeAnytime: 'To trade anytime, anywhere and receive instant notifications.',
  toUpdateOrganisationGoToBusinessProfile:
    'If you wish to update your organisation, please go to your account settings. Under "Business Profile" you will be able to amend your organisation details.',
  toViewAssetsCreateStorage: 'To view and create assets you will need to create your storage locations first',
  toViewInventoryRecords:
    'To view and create inventory records you will need to create your storage locations first.',
  today: 'Today',
  tomorrow: 'Tomorrow',
  tonnage: 'Tonnage',
  tonnageSold: 'Tonnage sold',
  tonne: 'tonne',
  tonnes: 'tonnes',
  tonnesAgreedToSell: 'Tonnes agreed to sell',
  tonnesToSell: 'Tonnes to sell',
  total: 'Total',
  totalAssetTransported: 'Total {assetType} transported ',
  totalAvailable: 'Total available',
  totalBreakdownOfYield: 'Total breakdown of yield',
  totalCapacity: 'Total capacity',
  totalCarbonOuput: 'Total carbon output for this job',
  totalCommittedTonnage: 'Total committed tonnage',
  totalContractPrice: 'Total contract price',
  totalContractValue: 'Total contract value',
  totalDistance: 'Total distance',
  totalDistanceLegs: 'Total distance & legs',
  totalFieldSize: 'Total field size',
  totalHarvested: 'Total harvested',
  totalIn: 'Total in',
  totalInField: 'Total in field',
  totalInFields: 'Total in field(s)',
  totalInStore: 'Total in store',
  totalInStoreAfterMovement: 'Total in store after movement',
  totalInStoreToday: 'Total in store today',
  totalIncluding: 'Total including',
  totalInventory: 'Total inventory',
  totalLoss: 'Total adjustments',
  totalOut: 'Total out',
  totalPredictedYield: 'Total predicted yield',
  totalPrice: 'Total price',
  totalProjectedYield: 'Total projected yield',
  totalQuantityInOriginLocation: 'Total quantity in origin location',
  totalQuantitySold: 'Total quantity sold',
  totalSold: 'Total sold',
  totalTonnage: 'Total tonnage',
  totalTonnageSold: 'Total tonnage sold',
  totalTonnageToBeSold: 'Total tonnage to be sold',
  totalTonnesBushelsSold: 'Total tonnes/bushels sold',
  totalTonnesSold: 'Total tonnes sold',
  totalTradeValue: 'Total trade value',
  totalValue: 'Total value',
  totalWeight: 'Total weight',
  totalWeightKG: 'Total weight (KG)',
  totalYieldProjected: 'Total yield (projected)',
  touchMapToChangeLocation: 'Touch a point on map to change',
  town: 'Town/City',
  townOrCity: 'Town or City',
  trade: 'Trade',
  tradeAccepted: 'Trade accepted',
  tradeAcceptedAndListingClosed: 'Trade accepted and listing closed',
  tradeAcceptedAndYourListingHasClosed: 'Trade accepted and your listing has closed',
  tradeAgreedOn: 'Trade agreed on',
  tradeAgreedWith: 'Trade agreed with',
  tradeAndContracts: 'Trade and contracts',
  tradeAssets: 'Trade ({count}) assets',
  tradeAvailability: 'Trade availability',
  tradeConfirmation: 'Trade confirmation',
  tradeConfirmationEnsuresAClearAuditTrail:
    'Trade confirmation ensures a clear audit trail, avoiding any buyer/seller contract confusion',
  tradeCount: 'Trade ({count})',
  tradeDate: 'Trade date',
  tradeFromBusinessUnit: 'Do you trade from this business unit?',
  tradePosition: 'Trade position',
  tradeRequest: 'Trade request',
  tradeRequestEnded: 'Request ended',
  tradeRequestRequiresValidStatus:
    'This trade request requires a valid scheme status. You can update this via your the Business unit in your Business setup.',
  tradeRequestSentToSellers: 'Request sent to sellers',
  tradeSummary: 'Trade summary',
  tradeTotalToBePaid: 'Trade total to be paid',
  tradeWithConfidence: 'Trade with confidence',
  traded: 'Traded',
  trades: 'Trades',
  trading: 'Trading',
  tradingInsights: 'Trading: Insights',
  tradingInventory: 'Trading: Inventory',
  tradingLivestockInsights: 'Trading: Livestock insights',
  tradingNotifications: 'Trading notifications',
  tradingTip: 'Trading tip',
  trailer: 'Trailer',
  trailerConfigured: 'Trailer configured',
  trailerCreated: 'Trailer created',
  trailerDetails: 'Trailer details',
  trailerDrawBar: 'Draw bar trailer',
  trailerName: 'Trailer name',
  trailerOther: 'Other',
  trailerStraightFrame: 'Straight frame',
  trailerSwanNeck: 'Swan neck',
  trailerType: 'Trailer type',
  trailers: 'Trailers',
  trainStation: 'Train station',
  transportMoreLessHassle: 'Transport more with less hassle',
  transportRequestCreatedCarriersNotified:
    'Transport request created. {totalCarriers} carriers have been notified.',
  transportRequestExpiry: 'Transport request expiry',
  transportRequestSettings: 'Transport request settings',
  transportRequestUpdatedCarriersNotified:
    'Transport request updated. {totalCarriers} carriers have been notified.',
  travelingToOrigin: 'Starting',
  tryAgain: 'Try again',
  tryDifferentMovementMonthsAndTargetPrices:
    'Try different movement months and target prices to see what you could receive',
  tryFilteringByADifferentCropAndDate: 'Try filtering by a different crop and date',
  trySearchBeforeCreateLocation: 'Try searching/filtering before creating a new location to prevent duplication',
  turnedOff: 'turned off',
  turnedOn: 'turned on',
  type: 'Type',
  typeAndGroup: 'Type & Group',
  typePercentageWeAdjustItems: 'Type in a percentage and we will adjust all selected records for you',
  typicalPriceEquals:
    'Typical price = (Highest price today + Lowest price today + Last price received today) ÷ 3).',
  ukIce: 'UK (ICE)',
  ukIceFeedWheat: 'UK (ICE) Feed Wheat',
  ukIreland: 'UK & Ireland',
  unSelectAll: 'Unselect all',
  unableToDeleteFarm: 'Unable to delete farm location',
  unassigned: 'unassigned',
  unassignedQuotes: 'Unassigned quotes',
  unassured: 'Unassured',
  unassuredFarm: 'Unassured Farm',
  unch: 'unch',
  unchanged: 'Unchanged',
  unconfirmedBySeller: 'Unconfirmed by seller',
  uncoverBestPricesInMarket: 'Uncover the best prices in the market for commodities in your region.',
  undelivered: 'Undelivered',
  underReview: 'Under review',
  underReviewUntil: 'Under review until',
  underReviewUntilX: 'Under review until {time}',
  unfix: 'Unfix',
  unfixContract: 'Unfix contract',
  unfixThisContractFromThePremium: 'Unfix this contract from the premium?',
  unfixed: 'Unfixed',
  unfortunatelyNoPriceOffersWereReceivedByBuyers: 'Unfortunately no price offers were received by buyers.',
  unfortunatelyPriceHasEnded:
    'Unfortunately this price has ended or no longer exists. Look out for more market listings from your contacts.',
  unfortunatelyPriceOfferExpired: 'Unfortunately your price offer has expired',
  unfortunatelySomethingsGoneWrong: "Unfortunately something's gone wrong.",
  unfortunatelyThisBidHasEndedOrNoLongerExists:
    'Unfortunately this bid has ended or no longer exists. Look out for more market listings from your contacts.',
  unfortunatelyYourBidAcceptanceTimeframeHasExpired:
    'Unfortunately your bid acceptance timeframe has expired so you are unable to withdraw this bid.',
  unfortunatelyYouveHadNoBidsAccepted: 'Unfortunately you’ve not had an accepted bid yet. Keep trying.',
  unitBushels: 'bushels | bushel | bushels',
  unitKg: 'kg | kg | kg',
  unitLb: 'lb | lb | lb',
  unitTonneShort: 't',
  unitTonnes: 'tonnes | tonne | tonnes',
  unitedKingdom: 'United Kingdom',
  unitedStates: 'United States',
  unitsShort: {
    tonne: 't',
  },
  unknown: 'Unknown',
  unload: 'Unload',
  unloadedMoreAssetsThanAvailable:
    'You have unloaded more {transportAsset} than available please check the route section',
  unloadedMoreThanAvailable: 'You have unloaded more assets than available please check the route section',
  unloading: 'Unloading',
  unlockInsightsNow: 'Unlock insights now',
  unlockMarketingInsightsNow: 'Unlock marketing insights now',
  unlockNow: 'Unlock now',
  unlockToAccessUKWideData: 'Unlock our trading insights module to access UK wide pricing data.',
  unlockedWhenBidAccepted: 'Unlocked when bid accepted',
  unpriced: 'Unpriced',
  unpricedSpecificTerms: 'Unpriced - specific terms',
  unsold: 'Unsold',
  untickTheBoxIfYouWantToHideYourOrganisationName:
    'Untick the box if you want to hide your organisation name on this request.',
  unverified: 'Unverified',
  unverifiedFarm: 'Unverified farm',
  up: 'Up',
  upToTwoAdvances: 'Up to two advances',
  upToXPercentOfTheValueUponContractUpload: 'Up to {percentage}% of the value upon contract upload',
  upcoming: 'Upcoming',
  upcomingJobs: 'Upcoming jobs',
  upcomingLoads: 'Upcoming loads',
  update: 'Update',
  updateBusinessProfile: 'Update business profile',
  updateBusinessUnit: 'Update farm/business unit',
  updateDetails: 'Update details',
  updateFarm: 'Update farm',
  updateFarmAssurance: 'Update farm assurance',
  updateFarmDetails: 'Update farm details',
  updateFulfillmentsAndSettlements: 'Update fulfilments and settlements',
  updateJob: 'Update job',
  updateJobAndQuotes: 'Update job and quotes',
  updatePassword: 'Update password',
  updateProfile: 'Update profile',
  updateQuote: 'Update quote',
  updateSpecification: 'Update specification',
  updateTags: 'Attributes',
  updateTeamMember: 'Update team member',
  updateUserProfile: 'Update user profile',
  updateWeighBridgeTicket: 'Update weighbridge ticket',
  updateYourPassword: 'Update your password',
  updateYourProfile: 'Update your profile',
  updated: 'Updated',
  updatedOutput: 'Updated output',
  updatesAppearHere: 'Important updates will appear here',
  updatesInclude: 'Updates include',
  updatingEstimate: 'Please wait, updating estimate',
  upgradeRequestSent: 'Request for upgrade sent successfully',
  upgradeToOurStorageModule: 'Upgrade to our storage module',
  upgradeToOurStorageModuleDescription:
    "Simply click the 'Send a contact request' button below and your Customer Success manager will get in touch to help you unlock this module.",
  upload: 'Upload',
  uploadAFile: 'Upload a file',
  uploadAPhoto: 'Upload a photo',
  uploadAdditionalDocs: 'Upload additional documents',
  uploadAttachment: 'Upload attachment',
  uploadCollectionReceipt: 'Upload collection receipt',
  uploadContract: 'Upload contract',
  uploadContractDocument: 'Upload contract document',
  uploadCsv: 'Upload CSV',
  uploadDocument: 'Upload document',
  uploadDocumentImage: 'Upload document/image',
  uploadFailedTryAgain: 'Failed to upload. Please try again',
  uploadFile: 'Upload file',
  uploadFiles: 'Upload files',
  uploadFinalCollectionReceipt: 'Upload final collection receipt',
  uploadImageSampleRecord: 'Upload image of sample record',
  uploadInventory: 'Upload inventory',
  uploadInvoiceDocument: 'Upload invoice document',
  uploadListOfLoads: 'Upload list of loads',
  uploadListOfLoadsAsCSVUseTemplateToUploadSpreadsheet:
    "Upload your list of loads to our platform in .csv format. We'll help you automatically create records and notify your carriers so you can keep track of them. Use our template below as a guide to create your upload spreadsheet.",
  uploadNewDataFile: 'Upload new data file',
  uploadPOD: 'Upload POD',
  uploadProofOf: 'Upload proof of the',
  uploadSample: 'Upload sample',
  uploadSampleResults: 'Upload sample results',
  uploadSingleFile: 'Please upload a single file',
  uploadSuccessfulTeamMembersOfXCanView:
    'Upload was successful. Team members of {organisations} can now view and download these PODs.',
  uploadTermsAndConditions: 'Upload your Terms and Conditions',
  uploadVehicleDocuments: 'Upload any vehicle documents',
  uploaded: 'Uploaded',
  uploadedBy: 'Uploaded by',
  uploading: 'Uploading',
  uploadsRemaining: 'Uploads remaining',
  useAnyBelowOptions: 'Use any of the below options',
  useAppOfflineAfterLoginFirstTime: 'You can use the app offline after you have logged in for the first time.',
  useFiltersToNarrowTradeSummary: 'Use the filters to narrow down the trade summary below',
  useHarvestPlannerToBulkMoveFromFields: 'Use harvest plan to bulk move from fields',
  usedToCreateEtaNoTracking: 'We use this time to inform all parties of an ETA if no live tracking is available.',
  user: 'User',
  userDeleted: 'User deleted',
  userHasBeenUpdated: 'User has been updated',
  userMadeToOwner: '{userName} has been made owner',
  userPermissions: '{name}’s permissions',
  userPermissionsUpdated: '{userName} has been updated',
  userProfile: 'User profile',
  userProfileUpdated: 'User profile updated',
  userRemovedFromYourOrganisation: '{userName} has been removed from your organisation',
  usersPermissionsUpdated: 'Users permissions have been updated',
  validFor: 'Valid for',
  validateHoldingNumber: 'Validate holding number',
  validation: 'Validation',
  valuationTool: 'Valuation tool',
  value: 'Value',
  valueCannotBeLessThan: '{field1} cannot be less than {field2}',
  valueCannotBeMoreThan: '{field1} cannot be more than {field2}',
  variable: 'variable',
  variableDeckConfig: 'Variable deck configuration',
  variableDecks: 'Variable decks',
  variety: 'Variety',
  varietyAttributesSpecifiedCrop: 'Variety and Attributes can still be specified for this crop.',
  varietySpecifiedCrop: 'Variety can still be specified for this crop.',
  various: 'Various',
  vat: 'VAT',
  'vat/TaxNumber': 'VAT / Tax number',
  vatNumber: 'VAT number',
  vatTaxNumber: 'VAT/Tax number',
  vehicle: 'Vehicle',
  vehicleAndDriver: 'Vehicle and driver',
  vehicleArticCab: 'Artic Cab',
  vehicleCreated: 'Vehicle created',
  vehicleDetails: 'Vehicle details',
  vehicleDocuments: 'Vehicle documents',
  vehicleEightWheelRigid: '8 Wheeler truck (Rigid)',
  vehicleFourWheelRigid: '4 Wheeler truck (Rigid)',
  vehicleInfoNotFound: "We couldn't find any matching vehicle information, please complete this manually below.",
  vehicleLookupResult: 'DVLA lookup result',
  vehicleReg: 'Vehicle reg',
  vehicleRegistration: 'Vehicle registration',
  vehicleSaved: 'Vehicle saved',
  vehicleSixWheelRigid: '6 Wheeler truck (Rigid)',
  vehicleType: 'Vehicle type',
  vehicleXDetails: 'Vehicle {x} details',
  vehicles: 'Vehicles',
  vehiclesAndTrailers: 'Vehicles & Trailers',
  verificationNeeded: 'Verification needed',
  verified: 'Verified',
  verifiedBuyer: 'Verified buyer',
  verifiedByHectare: 'Verified by Hectare',
  verifySpecificationUploadImageHelpYouSell:
    'Verify specification and upload a sample image to attract more buyers',
  view: 'View',
  viewAll: 'View all',
  viewAllInvoices: 'View all invoices',
  viewAllJobs: 'View all jobs',
  viewAllPricesReceived: 'View all prices received',
  viewAllTrades: 'View all trades',
  viewAllTrading: 'View all trading',
  viewAmendedSpecification: 'View amended specification',
  viewAndDownload: 'View and download',
  viewAndDownloadProofOfDelivery: 'View and download proof of delivery',
  viewArticles: 'View articles',
  viewBidHistory: 'View bid history',
  viewBids: 'View bids',
  viewBio: 'View bio',
  viewCertificationDetails: 'View certification details',
  viewContract: 'View contract',
  viewContractDetails: 'View contract details',
  viewContractDocs: 'View contract docs',
  viewContracts: 'View contracts',
  viewCustomer: 'View customer',
  viewDefaultSpecification: 'View default specification',
  viewDetails: 'View details',
  viewDocs: 'View docs',
  viewDriver: 'View driver',
  viewEvent: 'View event',
  viewFAQs: 'View FAQs',
  viewFarmDetails: 'View farm details',
  viewGroup: 'View group',
  viewHistory: 'View history',
  viewInventoryDetails: 'View inventory details',
  viewInventoryOverview: 'View inventory overview',
  viewJob: 'View job',
  viewListOfRecordedLoads: 'View a list of your recorded loads per harvest plan',
  viewListing: 'View listing',
  viewListingDetails: 'View listing details',
  viewListingInformation: 'View listing information',
  viewListingNotes: 'View listing notes',
  viewListingSpecification: 'View listing specification',
  viewListings: 'View listings',
  viewLoadInfo: 'View load info',
  viewLocation: 'View location',
  viewLog: 'View log',
  viewLossMatrixTable: 'View loss matrix table',
  viewMap: 'View map',
  viewMarketListing: 'View market listing',
  viewMovementDocs: 'View movement docs',
  viewMovements: 'View movements',
  viewMyListings: 'View My listings',
  viewMyTrading: 'View my trading',
  viewNewPricesReceived: 'View new prices received',
  viewOnly: 'View only',
  viewOrganisation: 'View organisation',
  viewPositionBreakdown: 'View position breakdown',
  viewPremiumContract: 'View premium contract',
  viewPremiumContracts: 'View premium contracts',
  viewPriceBenchmarking: 'View price benchmarking',
  viewPriceOffer: 'View price offer',
  viewPriceSummary: 'View price summary',
  viewProfile: 'View profile',
  viewReRoute: 'View re-route',
  viewReceipt: 'View receipt',
  viewReopenRequests: 'View reopen requests',
  viewResults: 'View results',
  viewSalesContracts: 'View sales contracts',
  viewSamples: 'View samples',
  viewSpecification: 'View specification',
  viewStorageContracts: 'View storage contracts',
  viewStoreInfo: 'View store info',
  viewStoredInventory: 'View stored inventory',
  viewSummaryInStore: 'To view summary in store, filter to a specific crop',
  viewTeam: 'View team',
  viewTheHowToGuide: 'View the how-to guide.',
  viewTheNewPlantings: 'View the new plantings',
  viewTradeConfirmation: 'View trade confirmation',
  viewTradeNotes: 'View trade notes',
  viewTradeSummary: 'View trade summary',
  viewTradingContracts: 'View trading contracts',
  viewTrailer: 'View trailer',
  viewUnread: 'View unread',
  viewUpdates: 'View updates',
  viewVehicle: 'View Vehicle',
  viewWanted: 'View Wanted',
  viewWhichVehiclesCarriersIntendToUtilise: 'View the carriers vehicle and load split for the job',
  viewXListing: 'View {amount} listings',
  viewYourNotes: 'View your notes',
  viewYourSalesPosition: 'View your sales position',
  viewYourTrades: 'View your trades',
  waiting: 'Waiting',
  wantToGetPaidNowForThisTrade: 'Want to get paid now for this trade?',
  wantToGetPaidNowToEaseCashFlow: 'Want to get paid now to ease cash flow?',
  wantToKnowMoreAboutAdvancePay: 'Want to know more about Advance Pay?',
  wantToPostThisAnonymously: 'Want to post this anonymously?',
  wantToReceiveMorePrices: 'Want to sell more tonnage?',
  wantToRemoveVisibilityOfThisListing:
    'Want to remove visibility of this listing? You can end it now and all buyers with outstanding price offers will be notified.',
  wantToRemoveVisibilityOfThisListingYouCanEndItNow:
    'Want to remove visibility of this listing? You can end it now.',
  wantToStartABrandNewListing: 'Want to start a brand new listing?',
  wanted: 'Wanted',
  wantedAlertResponse: 'Wanted alert response',
  wantedBuyer: 'Wanted - Buyer',
  wantedLocations: 'Wanted locations',
  wantedSeller: 'Wanted - Seller',
  wantsToBuy: 'wants to buy',
  warning: 'Warning',
  was: 'Was',
  waypoint: 'waypoint',
  waypointCleaningFacilities: 'Cleaning facilities',
  waypointCollectionCenter: 'Collection center',
  waypointDepot: 'Depot',
  waypointFarm: 'Farm',
  waypointFeedlot: 'Feedlot',
  waypointLayby: 'Layby',
  waypointMalting: 'Malting',
  waypointMart: 'Mart',
  waypointMill: 'Mill',
  waypointNotesPlaceholder: 'e.g. Access is via the green gate, access code to the gates is 1230#',
  waypointOfftaker: 'Offtaker',
  waypointOvernight: 'Overnight',
  waypointPort: 'Port',
  waypointProcessor: 'Processor',
  waypointRest: 'Rest',
  waypointSilo: 'Silo',
  waypointTrading: 'Trading',
  waypointTrainStation: 'Train station',
  waypointTransportHub: 'Transport Hub',
  waypointWeighbridge: 'Weighbridge',
  weAreDelightedToAnnounceGraindex:
    'We are delighted to announce that Graindex has a brand new name, look, and feel. We are now Hectare Trading. Our new brand identity reflects our mission to make crop trading easier than ever before and whilst we still have all of the features you have grown to expect, we’re excited to launch some new features designed to help you trade smarter',
  weAreSettingUpYourAccount:
    'We are setting up your account, so some functionality is currently locked. Please contact Hectare customer success team for assistance.',
  weCanHelpSimplifyStorageContractManagement:
    'We can help simplify storage contract management if you upgrade to our storage module.',
  weCantFindWhatYoureLookingFor: "We can't find what you're looking for.",
  weDoExtensiveChecksOnBuyersToVerifyThem:
    'We do extensive checks on each buyer to verify them so you can trust to buy from them,',
  weFoundAnExistingAccount: 'We found an existing account',
  weFoundAndExistingAccountWithEmail:
    'We found an account with that email address. Would you like to go to the login page instead?',
  weFoundExistingAccountWithCompanyNameEmail: 'We found an existing account with this company name/email address',
  weFoundXExistingBaseContracts: 'We found {num} existing base contracts',
  weFoundXExistingContractsWithY: 'We found {num} existing contracts with {company}',
  weGenerateAStandardSpecificationforYourCrop:
    'We generate a standard specification for your crop type and grade. If you think your crop differs click ‘Show’ and ‘Edit’ your specification. Accurate crop specs ensure buyers offer you the best price they can.',
  weHaveLoadedNextJobForYou: 'We have loaded the next job for you instead',
  weHaveSentAMessageToYourNewContactInformation:
    'We have sent a message to your new contact information. Click on the link in the message to confirm your new details.',
  weNowSentYouANewRegistrationLink: 'We’ve now sent you a new registration link. Please check your email.',
  weOnlyRequireOnceTheseDetailsTheFirstTime:
    'We only require these details the first time you receive an advance. These will be used to pay you every time you use Advance Pay.',
  weWantToGetYouPaidAsQuicklyAsPossible:
    'We want to get you paid as quickly as possible. To do this, please complete the following steps.',
  weWillContactYouSoon: 'We will contact you soon to verify your account',
  weWillHelpRemindCarriersOfPODRequest:
    "We'll help you remind your carriers that you are waiting for the following PODs so they can upload for you asap.",
  weWillHelpYouExportAMovementReport:
    'We will export all {direction} movement records for your customers between these dates',
  weWillHelpYouLinkForWhatsappToUploadPOD:
    "We'll help you generate a link and send it to your drivers via WhatsApp so they can upload the POD for your organisers.",
  weWillSendYouUpdates:
    'We will send you updates through emails by default, but you can also allow SMS and WhatsApp notifications to stay up to date',
  weWillTakeYouToYourAccount: 'We will now take you to your account.',
  weatherDrought: 'Weather - Drought',
  weatherFire: 'Weather - Fire',
  weatherFlooding: 'Weather - Flooding',
  weatherFreezeDamage: 'Weather - Freeze damage',
  weatherHail: 'Weather - Hail',
  weatherWind: 'Weather - Wind',
  weighbridge: 'Weighbridge',
  weighbridgeEmpty: 'Weighbridge empty',
  weighbridgeEmptyRequired: 'Weighbridge record empty required',
  weighbridgeFull: 'Weighbridge full',
  weighbridgeFullRequired: 'Weighbridge record full required',
  weighbridgeLocationLeftToCarrier:
    'Selecting a weighbridge location can be left to the selected carrier to choose',
  weighbridgeRecord: 'Weighbridge record',
  weighbridgeRequired: 'Weighbridge required',
  weighbridgeRequiredCount: 'No weighbridges required | One Weighbridge required | {count} Weighbridges required',
  weighbridgeTicket: 'Weighbridge ticket',
  weight: 'Weight',
  weightAfterDrying: 'Weight after drying',
  weightEmpty: 'Weight empty',
  weightFull: 'Weight full',
  weightMax: 'Weight max',
  weightMin: 'Weight min',
  weightPerHead: 'Weight per head',
  weightUploadRequiredHere: 'Weight upload required here',
  welcome: 'Welcome',
  welcomeBack: 'Welcome back',
  welcomeName: 'Welcome {name}',
  welcomeToHectareTrading: '👋  Welcome to Hectare Trading',
  welcomeToHectareTradingName: '👋  Welcome to Hectare Trading, {name}',
  welcomeToInventory: 'Welcome to Hectare Inventory',
  welcomeToMyListings: 'Welcome to My Listings',
  welcomeToMyTrading: 'Welcome to My Trading',
  welcomeToYourHarvest: 'Welcome to your Harvest',
  welcomeToYourHarvestDetail:
    'Your fields have been grouped by farm, crop and type. Click into each group to start adding your loads for this harvest year.',
  wellKeepYouInformedOnIndustryNews: "We'll keep you informed on the latest industry news and price fluctuations",
  wereProcessingYourAccount:
    'We’re processing your account and someone will be in touch shortly to get you logged in.',
  what3words: 'What3words',
  whatAreTargetPrices: 'What are target prices?',
  whatAreYouAdding: 'What are you adding?',
  whatAreYouInterestedInBuying: 'What are you interested in buying?',
  whatAreYouLookingFor: 'What are you looking for?',
  whatAreYouMoving: 'What are you moving?',
  whatBringsYouToHectare: 'What brings you to Hectare Trading?',
  whatCapacityCanBeStored: 'What capacity can be stored?',
  whatCrop: 'What is the crop?',
  whatCropStoredHere: 'What crops could be stored here?',
  whatCropsYouWantToHearAbout: 'What crops you want to hear about?',
  whatDataTypeToDownload: 'What data type would you like to download?',
  whatDataTypeWouldYouLikeToDownload: 'What data type would you like to download?',
  whatDoesThisMean: 'What does this mean?',
  whatFieldType: 'What type of field are you planting in?',
  whatGrade: 'What is the group/grade?',
  whatHappensNext: 'What happens next',
  whatInventoryHasBeenSold: 'What inventory has been sold?',
  whatInventoryType: 'What is the inventory type?',
  whatIsAdvancePay: 'What is Advance Pay?',
  whatIsBeingTransported: 'What is being transported?',
  whatIsExpectedYield: 'What is the expected yield?',
  whatIsIntake: 'What is the intake?',
  whatIsPlanted: 'What is planeted in these field(s)',
  whatIsTheGroupGrade: 'What is the group/grade?',
  whatIsTheirMobile: 'What is their mobile number?',
  whatLoadingHere: 'What are you loading here?',
  whatToDoNext: 'What to do next?',
  whatType: 'What is the type?',
  whatUnloadingHere: 'What are you unloading here?',
  whatVariety: 'What is the variety?',
  whatYouAreSelling: 'What you are selling to the buyer',
  whatYouLoadingHere: 'What are you loading here?',
  whatYouUnloadingHere: 'What are you unloading here?',
  whatYoureSelling: "What you're selling",
  whatsApp: 'WhatsApp',
  whatsBeenCommitted: 'What’s been committed?',
  whatsForSale: "What's for sale?",
  whatsNewInHectareTrading: 'What’s new in Hectare Trading',
  whatsapp: 'WhatsApp',
  whatsyourDesiredPosition: 'What’s your desired position?',
  whenBuyersHaveRequestItWillAppearHere: 'When buyers have a crop need, their request will appear here',
  whenDoYouNeedToMoveIt: 'When do you need to move it?',
  whenPlannedMovementMonth: 'When is the planned movement month?',
  whenPlannedPlantingMonth: 'When is the planned planting month?',
  whenPostingAListing:
    'When posting a listing, a valid farm address and holding number are required. Contact our team if you need any help after signing up.',
  whenShouldYourOfferExpireChoosePresetOrCustom:
    'When should your offer expire? Choose a preset time or add your own custom expiry',
  whenTheMovementHappensYouWillBeAskedToUploadAReceipt:
    'When the movement happens, you will be asked to upload a collection receipt to complete the trade and enable remaining payments to be made.',
  whereAreYouMovingThisTo: 'Where are you moving this to?',
  whereCompanyBased: 'Where is your company based?',
  whereIsInventoryComingFrom: 'Where is the inventory coming from?',
  whereIsLoadNow: 'Where is the load now?',
  whereIsTheCompanyBased: 'Where is the company based',
  whereIsYourCountryBasedIn: 'Where is your company based in?',
  whereWouldYouLiketoMoveCropFrom: 'Where would you like to move crop from?',
  whichBusinessAreYouMovingInventoryTo: 'Which Business are you moving the inventory to?',
  whileImpersonatingOrganisation:
    'While impersonating an organisation, changes you make will be visible to them. Take extreme care and logout as soon as you are finished.',
  whileOfflineAppFunctionality:
    'While offline you are able to complete jobs but other app functionality is limited until you re-gain signal.',
  whilstOptionalWeFindPriceRangesAttractMostInterest:
    'Whilst optional, we find that price ranges attract the most interest from sellers, and makes your following offers more likely to be accepted.',
  whoKnowDriverETAAtStop: "Who needs to know the driver's ETA at this stop?",
  whoOwnsInventory: 'Who owns the inventory?',
  whoSellingTo: 'Who are you selling to?',
  whoStoreInventory: 'Who do you store inventory for?',
  whosTheBuyer: 'Who’s the buyer?',
  whyAreYouEndingTheListing: 'Why are you ending this listing today?',
  whyAreYouListingAgain: 'Why are you listing again?',
  whyDoWeNeedThis: 'Why do we need this?',
  width: 'Width',
  with: 'with',
  withXDecks: 'with {count} deck | with {count} decks',
  withdraw: 'Withdraw',
  withdrawBid: 'Withdraw bid',
  withdrawOffer: 'Withdraw offer',
  withdrawPrice: 'Withdraw price',
  withdrawQuote: 'Withdraw quote',
  withdrawn: 'Withdrawn',
  withinXWorkingDays: 'Within {days} working days',
  words: 'words',
  wouldYouLikeToCancelContract:
    'Would you like to cancel this contract? Please note that the contract cannot be retrieved.',
  wouldYouLikeToContinueWhereYouLeftOff: 'Would you like to continue where you left off?',
  wouldYouLikeToDeleteThisPOD:
    'Would you like to delete this POD? Please note that the POD cannot be retrieved after deletion. PODXSuccessfullyDeleted: POD - {POD} successfully deleted',
  wouldYouLikeToEndThisListing: 'Would you like to end this listing?',
  wouldYouLikeToEndThisListingAndStopReceivingFurtherPrices:
    'Would you like to end this listing and stop receiving further prices from interested buyers?',
  wouldYouLikeXPercentOfThisTradesValue:
    'Would you like to receive {percentage}% of this trades value now with an Advance Payment? Find out more below or continue through to the trade summary.',
  wrongApp: 'Wrong app',
  xBusinessesFound:
    'No businesses found in your connections | One business found in your connections | {count} businesses found in your connections',
  xBuyersFound:
    'No buyers were found in your connections | One buyer was found in your connections | {count} buyers were found in your connections',
  xFieldsFound:
    'No fields found in your locations | One field found in your locations | {count} fields found in your locations',
  xHasReceivedYourPODUploadRequest: '{carriers} has received your POD upload request',
  xInventoryAdded: '{count} new inventory added',
  xIsNowPartOfYourFarmLocations: '{farmName} is now part of your farm locations.',
  xListings: '({amount}) listings',
  xListingsAreUnderReview:
    '{count} listing is currently under review | {count} listings are currently under review',
  xListingsForReviewToday:
    'You have {count} listings for review today | You have {count} listing for review today | You have {count} listings for review today',
  xLoadsSelected: 'Load selected | Loads selected',
  xMins: '{duration} mins',
  xMinutes: '{minutes} minutes',
  xMovementsFound:
    'No existing movements to {buyer} found for the same crop | We found one existing movement to {buyer} for the same crop | We found {count} existing movements to {buyer} for the same crop.',
  xNewLoadsAdded: '{count} new loads added',
  xNewLoadsAddedYourCarriersCanViewArrangeJobs:
    '{count} new loads has been added. Your carriers can now view and \u2028arrange these jobs.',
  xNewPODsUploaded: '{count} New POD(s) uploaded',
  xPercentOfBuyersWillAcceptTradeAtPriceTheyOriginallySent:
    '{percentage}% of buyers will accept a trade at the price they originally sent you',
  xResultsFound: 'No results found | One result found | {count} results found',
  xResultsReturnedChooseYourFarmAssurance: '{results} results returned, choose your farm assurance',
  xResultsWereFound: '{count} results were found',
  xStorageContractsFound:
    'We could not find any contracts based on your selected crop. | We found one existing contract based on your selected crop. | We found {count} existing contracts based on your selected crop.',
  xStoresFound:
    'No stores found in your storage locations | One store found in your storage locations | {count} stores found in your storage locations',
  xTradesRequireContractsPleaseUpload:
    '{number} Trades require contracts. Please upload them as soon as possible.',
  yes: 'Yes',
  yesAskToReopenPrice: 'Yes, ask to reopen price',
  yesConfirmTrade: 'Yes, confirm trade',
  yesConnectNow: 'Yes, connect now',
  yesContinue: 'Yes, continue',
  yesEndListing: 'Yes, end listing',
  yesMakeOffer: 'Yes, make offer',
  yesMakeOwner: 'Yes, make owner',
  yesRemove: 'Yes, remove',
  yesViaEmail: 'Yes, via email',
  yesWithdraw: 'Yes, withdraw',
  yesterday: 'Yesterday',
  yield: 'Yield',
  yieldBreakdown: 'Yield breakdown',
  yieldForSale: 'Yield for sale',
  yieldPerHectare: 'Yield per hectare',
  yieldVariance: 'Yield variance',
  you: 'You',
  youAddedAdditionalBuyers: "You've added {n} additional buyers",
  youAgreeToCreatingAccountAndHectares: "you agree to creating your account and to Hectare's",
  youAgreedToSell: "You've agreed to sell",
  youAreAboutToPostTheFollowingListingAgain: 'You are about to post the following listing again',
  youAreImpersonatingX: 'You are now impersonating {businessName}',
  youAreLoggedInAs: 'You are logged in as {name}',
  youAreMaximisingThePotentialOfBidsByOpeningItUpToTheOpenMarket:
    'You are maximising the potential of bids by opening it up to the open market.',
  youAreOfflineTryAgainInternetConnection:
    'This is most likely because you are offline, try again when you have an internet connection.',
  youAreOnlyAbleToAcceptOneBidOption: 'You are only able to accept one bid option',
  youArePreApprovedForAdvancePayment: 'You are pre-approved for Advance Payment',
  youAreReceivingNewQuoteAlerts: 'You are receiving new quote alerts via',
  youCanAddDetailsForTeam: 'You can add details which team members need to know here.',
  youCanAdjustToggleToGoBackToDeliveryInProgress:
    'You can easily adjust the toggle to go back to "delivery in progress".',
  youCanAlwaySendFarmerAnotherOffer: 'You can always send the farmer another offer to consider',
  youCanAlwaysSendAnotherPrice: 'You can always send another price',
  youCanAskBuyerToReopenPrice:
    'You can ask the buyer to reopen the price. We can’t guarantee that they will accept, but we still recommend making the request below.',
  youCanEditTheDetailsIfYouWishToChangeAnything:
    'You can edit the details if you wish to change anything about the listing',
  youCanEditThisPriceLater: "Don't worry. You can edit this price later if the market shifts.",
  youCanEitherReadOurFAQs: 'You can either read our frequently asked questions',
  youCanFixABaseContractToAPremium:
    'You can fix a base contract to a premium once this premium contract has been created.',
  youCanNoLongerEditThisPrice: 'You can no longer edit this price',
  youCanNoLongerUpdateThisPrice: 'You can no longer update this price, however you can make another price.',
  youCanOnlyAcceptOneOfTheFollowingOptions: 'You can only accept one of the following options',
  youCanProvideYourFarmDetailsLater: 'You can provide your farm details later',
  youCanRequestANewLinkIn: 'You can request a new link in',
  youCanReviewPriceOffersReceivedAt: 'You can review price offers received at',
  youCanSearchOrAddFiltersToRefineLocations: 'You can search or add filters to refine the list of locations below',
  youCanSellYourCropsByPostingAListing:
    'You can sell your crops by posting listings to all our vetted buyers or by responding to buyer’s wanted listings.',
  youCanStillSellIt:
    'Unfortunately your price offer has expired, you can ask the buyer to re-open the price. We can’t guarantee that they will accept, but we still recommend making the request below.',
  youCanUpdateTheContractPrice:
    'You can update the contract price if there is any change in grade after sampling.',
  youCanUseAnyNumberYouWishToMatchYourRecords: 'You can use any number you wish to match your records',
  youCanViewYourContractsOrAddAnother: 'You can view your list of contracts or add another contract',
  youCannotDeleteSubstoreWithInventory: 'You cannot delete substores with inventory inside',
  youCantEditTheBusinessUnit: 'You can’t edit the Business unit.',
  youCurrentlyDontHaveAnyBusinessAreas: 'You currently don’t have any Business areas',
  youCurrentlyHaveNoBusinessConnections: 'You currently have no businesses connections',
  youCurrentlyHaveNoExtraStoreInfo: 'You currently have no extra store info',
  youCurrentlyHaveNoHarvestPlans: 'You currently have no harvest plans',
  youCurrentlyHaveNoSalesContracts: 'You currently have no sales contracts',
  youDidNotReceiveAnyOffers: 'Unfortunately you didn’t receive any offers',
  youDontHaveAnyOffersHeresRepresentativeExample: 'You currently have no offers. Here’s a representative example',
  youDontHaveOffersThatQualifyAdvancePay: "You don't have any offers that qualify for Advance Pay",
  youHave: 'You have',
  youHaveAcceptedABid: 'Congratulations, you have made a trade with {organisation}',
  youHaveNoPremiumContracts: 'You have no premium contracts',
  youHaveNoStoredInventory: 'You have no stored inventory',
  youHaveSelectedToMoveMoreInventoryThanAvailable:
    'You have selected to move more inventory than you have available in store.',
  youHaveSuccessfullyUpdatedYourAccountDetails: 'You have successfully updated your account details',
  youHaventCreatedAnyYet: 'You have’t created any market listings yet',
  youHaventReceivedAnyPrices: 'You haven’t received any prices',
  youMayLinkInventoryToBusinessUnit:
    'Congratulations! You may link inventory to this business unit when adding new inventory to the platform.',
  youMayNameFieldBlockForEasyPlanning: 'You may name it as a field block for easy planning',
  youOriginallyQuoted: 'You originally quoted',
  youRecentlyStartedAListingFor: 'You recently started posting a listing for',
  youRequestedToSellWeightForMovementDate: 'You requested to sell {weight} for {price} for movement in {date}',
  youRequestedToSellWeightForPrice: 'You requested to sell {weight} for {price}',
  youWillAlwaysSeeBuyerNames: 'You will always see buyer names associated with any offers made.',
  youWillBeNotifiedWhenBuyersSendYouAPrice: 'You will be notified when buyers send you a price',
  youWillNowReceiveListingsForX: 'You’ll now receive listings for {commoditySummary}',
  youllBePayingMIRLuxCapitalInsteadOfSeller:
    'You’ll be paying MIR Lux Capital instead of the seller for this trade',
  youllBePayingXInsteadOfSellerForTrade: 'You’ll be paying MIR Lux Capital instead of the seller for this trade',
  youllGetPercentOfTradeAlreadyApproved:
    'You’ll receive up to 80% of the trade value from our independent partner so you’re free to trade with the buyer of your choice. There’s no need to apply, you’re already pre-approved.',
  youllNeedToMakeABidOnAListing: 'You’ll need to make a bid on a listing to see bids on this page.',
  yourAcceptedTrades: 'Your accepted trades',
  yourAccount: 'Your account',
  yourAccountHasBeenCreated: 'Thank you! Your account has been created',
  yourAdvancePayment: 'Your advance payment',
  yourAdvancePaymentForAmountHasBeenSuccessfullyMade:
    'Your advance payment for {amount} has been successfully processed, and will normally be in your bank within 1 working day.',
  yourAssuranceStatusIsUnverified: 'Your assurance status is unverified',
  yourBankDetailsForPayment: 'Your bank details for payment',
  yourBaseContractHasBeenCreatedAndConnected:
    'Your base contract has been created and connected to the premium contract. ',
  yourBidWillBeWithdrawn: 'Your bid will be permanently withdrawn',
  yourBusinessNameUsedOnAnyAssuranceScheme: 'Your business name used on any assurance scheme ',
  yourBusinessUnit: 'Your business unit',
  yourBusinessUnitType: 'Business unit type',
  yourBuyerWillUploadContractAgainstTrade:
    'Your buyer will upload a contract against this trade in due course. If you do not receive this within 72 hours, you must contact the buyer and request a formal contract.',
  yourBuyers: 'Your buyers',
  yourChangesWillBeLostIfYouClickCancel: 'Your changes will be lost if you click cancel.',
  yourConfirmation: 'Your confirmation',
  yourConfirmationCodeHasExpired: 'Your reset password request has expired, would you like us to send a new code?',
  yourContacts: 'Your contacts',
  yourContractHasBeenSaved: 'Your contract has been saved',
  yourContractPriceHasBeenFixed: 'Your contract price has been fixed',
  yourCurrentStorageSelectionWillBeLost:
    "Your current storage selection will be lost. You will need to choose a new storage location that's available based on the new date selected.",
  yourDetails: 'Your details',
  yourFarmIsUnassuredWithNoLocationUpdateDetails:
    'Your farm is unassured with no location. Please update your details.',
  yourFinanceDetails: 'Your finance details',
  yourGrainMustHaveTheSameGroupToAddLoads: 'Your grain must have the same group to add loads.',
  yourGuidePrice: 'Your guide price',
  yourHarvest: 'your harvest',
  yourHarvestOverview: 'Your harvest overview',
  yourInitialAdvancePayment: 'Your initial advance payment',
  yourIntermediateAdvancePayment: 'Your intermediate advance payment',
  yourInventoryHasBeenCreated: 'Your new Inventory has been created',
  yourInventoryPositionInThePalmOfYourHand: 'Your inventory position in the palm of your hand',
  yourInviteResend: 'Your invite to {name} has been re-sent',
  yourInviteSent: 'Your invite to {name} has been sent',
  yourListingHasBeenSent: 'Your listing has been sent. Sit back and wait for price offers to come in.',
  yourListingHasBeenSentToTheTrustedNetwork: 'Your listing has been sent to the trusted network',
  yourListingHasBeenSuccessfullyEnded: 'Your listing has been successfully ended',
  yourListingIsScheduled: 'Your listing is scheduled',
  yourListingOpensForPriceOffersFromBuyers: 'Your listing opens for price offers from buyers at',
  yourListingOpensForPriceOffersOn: 'Your listing opens for price offers from buyers on',
  yourListingOpensForPriceOffersTodayAt: 'Your listing opens for price offers today at',
  yourListingOpensForPriceOffersTomorrowAt: 'Your listing opens for price offers tomorrow at',
  yourListingWillBePending: 'Your listing will be in pending status until the verification process is complete',
  yourListingWillEndAt: 'Your listing will end at',
  yourListingWillOpenForPriceOffers: 'Your listing will open for price offers from buyers',
  yourLoadsHaveBeenRecordedPleaseConfirmYourHarvestProgress:
    'Your loads have been recorded. Please confirm your harvest progress.',
  yourMarketListingWillNotBeSeenByLotsOfInterestedOpenMarketBuyers:
    'Your market listing will not be seen by lots of interested open market buyers.',
  yourMovementIsCreated: 'Your movement has been created.',
  yourNewInventoryHasBeenCreated: 'Your new inventory has been created',
  yourNewJobHasBeenCreated: 'Your new job has been created',
  yourNote: 'Your note',
  yourNotes: 'Your notes',
  yourOfferExpires: 'Your offer expires',
  yourOrganisationAddressHasBeenSelected:
    'Your organisation address has been selected for this as no business units have been set up at this time.',
  yourPaymentsWillBeReceivedInYourAccountEndingIn:
    'Your payments will be received in your account number ending in',
  yourPremiumContractHasBeenCreated:
    'Your premium contract has been created. Start fixing contracts or view all of your premium contracts.',
  yourPreviousOffer: 'Your previous offer',
  yourPreviousPriceForListingWasX: 'Your previous price for this listing was {amount}',
  yourPriceExpired: 'Your price expired',
  yourPriceHasBeenAccepted: 'Your price has been accepted',
  yourPriceHasBeenSent: 'Your price has been sent',
  yourPriceHasBeenUpdated: 'Your price has been updated',
  yourPriceHasBeenWithdrawn: 'Your price has been withdrawn',
  yourPriceHasExpired: 'Your price has expired',
  yourPriceOffer: 'Your price offer',
  yourPriceOffersWillShowHere: 'Your price offers will show here',
  yourPriceWasWithdrawn: 'Your price was withdrawn',
  yourProfile: 'Your profile',
  yourProgress: 'Your progress',
  yourQuestionsAnswered: 'Your questions answered',
  yourQuote: 'Your quote',
  yourQuoteFor: 'Your quote for',
  yourRedTractorStatusUnverified: 'Your Red Tractor status is unverified',
  yourRequests: 'Your requests',
  yourReviewIsSetForXYouHaveXXminsToReview:
    'Your review is set for {reviewTime}. You will have {reviewLength} minutes to review price offers before they expire.',
  yourRouteHasBeenPrefilled: 'Your route has been prefilled',
  yourSentListings: 'Your sent listings',
  yourStorageContractHasBeenSaved: 'Your storage contract has been saved',
  yourTargetPrice: 'Your target price',
  yourTradeRequestHasBeenSentToSellers:
    'Your request has been sent to sellers with that inventory available to them. Keep an eye out and respond to listings when they come in.',
  youreATradingPro: '🎉 You’re a trading pro!',
  youreAlmostThere: 'You’re almost there. We have emailed you a link to set up your password.',
  youreNoLongerAbleToMakePrice: "You're no longer able to make a price.",
  youreRequestingToReopenPrices: "You're requesting to reopen this price",
  youveAddedNAdditionBuyers:
    "You've added {n} additional buyers | You've added {n} additional buyer | You've added {n} additional buyers",
  youveAddedNBuyers: "You've added {n} buyers",
  youveAlertedNAdditionalTeamMembers:
    "You've alerted {n} additional team members |   You've alerted {n} additional team member | You've alerted {n} additional team members",
  youveAlreadyAcceptedAnotherBid: "You've already accepted 1 other price offer",
  youveAlreadyAcceptedOtherBids: "You've already accepted {amount} other price offers",
  youveChosenToRecieveAllListings:
    'You’ve chosen to receive all listings from sellers. You can turn this off at any time using the toggle.',
  youveCompletedTheJob: "You've completed the job",
  youveNowFixed: "You've now fixed",
  youvePostedToTheOpenMarket: 'You’ve posted your market listing to the open market',
  youvePostedYourFirstListing: 'You’ve posted your first listing!',
  youveRequestedToSell: 'You’ve requested to sell',
  youveUpdatedFarmLocation: "You've successfully updated your farm location.",
}

export default enGB
