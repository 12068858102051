var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Transition',{attrs:{"name":_setup.transitionName,"appear":""}},[_c('dialog',{ref:"dialog",staticClass:"max-w-full divide-y p-0 flex flex-col bg-white z-layer-modal",class:{
      'md:!w-full': _vm.isFullWidth,
      'h-dialog h-full !m-0 min-h-full top-0 right-0 bottom-0 w-full md:w-[576px] fixed': _vm.flyOut,
      'left-auto': _vm.position === 'left',
      'h-dialog w-full h-fit md:min-h-fit mt-auto mr-0 mb-0 ml-0 sm:m-0 md:m-auto md:w-[576px] md:max-w-[576px] md:top-[50%] md:bottom-[50%] md:rounded':
        !_vm.flyOut && _setup.hasMobileModalUiUpdate,
      'h-dialog w-full h-full min-h-full md:h-fit md:min-h-fit m-0 md:m-auto md:w-[576px] md:max-w-[576px] md:top-[50%] md:bottom-[50%] md:rounded':
        !_vm.flyOut && !_setup.hasMobileModalUiUpdate,
      absolute: !_setup.isMobileOrNative && !_vm.flyOut
    },on:{"cancel":function($event){$event.preventDefault();return _setup.handleCancel.apply(null, arguments)}}},[_c('div',{staticClass:"px-4 py-4 md:px-6 flex items-center",class:{
        'text-center': _vm.position === 'center',
        'flex-row-reverse': _setup.props.flyOut,
        'flex-row': !_setup.props.flyOut
      },attrs:{"data-testid":"hDialogHeader"}},[_c('div',{class:{
          'flex-1 z-layer-base': !_setup.props.flyOut,
          'flex-1': _setup.props.flyOut
        }},[_vm._t("header",function(){return [_c(_setup.HTitle,{attrs:{"size":"small","data-testid":"hDialogText"}},[_vm._v("\n            "+_vm._s(_vm.title)+"\n          ")])]})],2),_vm._v(" "),(_vm.dismissable)?_c('button',{staticClass:"flex-0 z-layer-raised rounded-full cursor-pointer bg-primary-50 hover:bg-primary-100 w-9 h-9",class:{ 'mr-4': _setup.props.flyOut, 'md:absolute right-[1.5rem]': !_setup.props.flyOut && _setup.props.dismissable },attrs:{"aria-label":_vm.$t('close').toString(),"data-testid":"hDialogDismissButton"},on:{"click":_setup.handleCancel}},[(_setup.props.flyOut)?_c(_setup.FontAwesomeIcon,{staticClass:"cursor-pointer text-base text-primary",attrs:{"data-testid":"hDialogCancelIcon","icon":_setup.faArrowLeft}}):_c(_setup.FontAwesomeIcon,{staticClass:"cursor-pointer text-base text-primary",attrs:{"data-testid":"hDialogCancelIcon","icon":_setup.faTimes}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"grow overflow-y-auto relative bodyWrapper",class:{
        [_setup.props.bodyClass]: true,
        'p-2 md:p-6': _vm.hasBodyPadding && _vm.flyOut,
        'p-4 md:p-6': _vm.hasBodyPadding && !_vm.flyOut,
        'text-center md:h-fit': _vm.position === 'center'
      },attrs:{"data-testid":"hDialogBody"}},[_vm._t("default")],2),_vm._v(" "),(_vm.showFooter)?_c('div',{staticClass:"dialog-footer py-4 px-4 md:px-6",attrs:{"data-testid":"hDialogFooter"}},[_vm._t("footer",function(){return [_c('div',{staticClass:"flex flex-row",class:{
            'justify-center': !_vm.dismissable || !_vm.hasCancelButton,
            'justify-between': _vm.dismissable && _vm.hasCancelButton,
            'flex-col-reverse sm:flex-row': !_vm.flyOut && _setup.hasMobileModalUiUpdate
          }},[(_vm.dismissable && _vm.hasCancelButton)?_c('Button',{class:{ 'mt-4 sm:mt-0': !_vm.flyOut && _setup.hasMobileModalUiUpdate },attrs:{"data-testid":"hDialogCancelButton"},on:{"click":_setup.handleCancel}},[_vm._v("\n            "+_vm._s(_setup.cancelTextEvaluated)+"\n          ")]):_vm._e(),_vm._v(" "),_c('Button',{attrs:{"priority":"primary","data-testid":"hDialogOkButton"},on:{"click":_setup.handleOk}},[_vm._v("\n            "+_vm._s(_setup.okTextEvaluated)+"\n          ")])],1)]})],2):_vm._e(),_vm._v(" "),_c(_setup.LoaderOverlay,{attrs:{"isLoading":_vm.isLoading}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }